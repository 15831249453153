import React, { useMemo, useState } from "react";
import { Icon, Button, Form, Modal } from "semantic-ui-react";
import { createDeviceConfiguration } from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import { OptionType } from "../../util";
import { useUser } from "../../../../../context/User.context";
import { CreateJSONConfigModalProps } from "./CreateJSONConfigModal";
import GeofenceEditor, {
  LatLng,
} from "../../Inventory/DeviceConfigurations/GeofenceEditor";
import { beamtoast } from "../../../../common/CustomToast";
import {
  StyledSemanticTabs,
  StyledTabPaneNoBorder,
} from "../../../Dashboards/Panel/util";
import { CheckboxWithErrorState } from "../../Inventory/DeviceConfigurations/CreateGeofenceConfigurationsModal";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/ext-searchbox.js";
import ThemeSchema from "../../../../../theme/schema";

function CreateGeofenceConfigModal(props: CreateJSONConfigModalProps) {
  const {
    isOpen,
    onClose,
    setOptionType,
    setSelectedVersion,
    allConfigOptions,
  } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [versionName, setVersionName] = useState<string>("");
  const [alertThreshold, setAlertThreshold] = useState<number>(20);
  const [errors, setErrors] = useState<{
    versionName?: string;
    thresholdValue?: string;
    completeGeofenceCheckbox?: string;
  }>({
    versionName: "",
    thresholdValue: "",
    completeGeofenceCheckbox: "",
  });

  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  const [path, setPath] = useState<LatLng[]>([]);
  const [jsonViewPath, setJsonViewPath] = useState<string>("[]");
  const [isPolygonComplete, setIsPolygonComplete] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number>(12.927381); // defaults to bangalore
  const [longitude, setLongitude] = useState<number>(77.637729); // defaults to bangalore

  const updateDeviceConfig = async () => {
    try {
      const configJson = {
        type: "polygon",
        path,
        alert_interval: alertThreshold.toString(),
      };

      const newConfig = await createDeviceConfiguration(
        versionName.trim(),
        configJson,
        "update_geofence"
      );

      if (newConfig.version_name) {
        setOptionType(OptionType.UploadGeofence);
        setSelectedVersion(newConfig.version_name);
        beamtoast.success(
          `Created Geofence Configuration ${newConfig.version_name}`
        );
        Mixpanel.track("Created Configuration", {
          Config: newConfig.version_name,
        });
      }
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    } finally {
      onCloseModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasDuplicateGeoPoints = path.some(
      (point, index) =>
        path.findIndex((p) => p.lat === point.lat && p.lng === point.lng) !==
        index
    );

    if (allConfigOptions.has(versionName?.trim()?.toLowerCase())) {
      setErrors({
        versionName: "This geofence config version already exists",
      });
    } else if (alertThreshold < 1) {
      setErrors({
        thresholdValue: "Geofence alert threshold should be greater than 0",
      });
    } else if (versionName?.trim()?.length === 0) {
      setErrors({
        versionName: "Please enter a Version Name",
      });
    } else if (!alertThreshold) {
      setErrors({
        thresholdValue: "Please enter valid geofence threshold",
      });
    } else if (!isPolygonComplete) {
      beamtoast.error(
        activeIndex === 0
          ? "Please complete geofence selection by clicking on polygon to lock on the map."
          : "Please complete geofence selection using complete geofence polygon checkbox."
      );
    } else if (path.length < 3) {
      beamtoast.error(
        "Please select at least three points to define a valid geofence area."
      );
    } else if (hasDuplicateGeoPoints) {
      beamtoast.error(
        "Duplicate points detected, Please ensure all geofence points are unique."
      );
    } else {
      // valid data, submit
      await updateDeviceConfig();
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    setActiveIndex(0);
    setJsonViewPath("[]");
    setVersionName("");
    setAlertThreshold(20);
    setIsPolygonComplete(false);
    setPath([]);
    setErrors({
      versionName: "",
      thresholdValue: "",
    });
    setLatitude(12.927381);
    setLongitude(77.637729);

    onClose();
  }

  const panes = useMemo(
    () => [
      {
        menuItem: "Map View",
        pane: (
          <StyledTabPaneNoBorder
            key={"map_view"}
            style={{
              padding: "0px",
              marginBottom: "12px",
            }}
          >
            <Form>
              <Form.Field>
                <label htmlFor="version_name">Version Name</label>
                <Form.Input
                  id={"version_name"}
                  error={errors.versionName !== "" ? errors.versionName : null}
                  placeholder={"bengaluru-geofence"}
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="geofence_editor">
                  Click to create geofence polygon
                </label>
                <GeofenceEditor
                  editable={true}
                  latitude={latitude}
                  longitude={longitude}
                  path={path}
                  onPolygonChanged={(path, isComplete) => {
                    setPath(path);
                    setIsPolygonComplete(isComplete);
                  }}
                  theme={theme}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="alert_threshold">
                  Alert threshold (seconds)
                </label>
                <Form.Input
                  min="1"
                  id={"alert_threshold"}
                  type="number"
                  error={
                    errors.thresholdValue !== "" ? errors.thresholdValue : null
                  }
                  placeholder={20}
                  value={alertThreshold}
                  onChange={(e) => {
                    setAlertThreshold(parseInt(e.target.value));
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </StyledTabPaneNoBorder>
        ),
      },
      {
        menuItem: "JSON View",
        pane: (
          <StyledTabPaneNoBorder
            key={"json_view"}
            style={{
              padding: "0px",
              marginBottom: "12px",
            }}
          >
            <Form>
              <Form.Field>
                <label>Version Name</label>
                <Form.Input
                  id={"version_name"}
                  error={errors.versionName !== "" ? errors.versionName : null}
                  placeholder={"bengaluru-geofence"}
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <label>JSON geofence polygon</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    Complete geofence polygon{" "}
                    <CheckboxWithErrorState
                      error={Boolean(errors.completeGeofenceCheckbox)}
                      checked={isPolygonComplete}
                      onChange={() => {
                        try {
                          let parsedJSON = JSON.parse(jsonViewPath);

                          if (
                            parsedJSON !== null &&
                            typeof parsedJSON === "object" &&
                            !Array.isArray(parsedJSON)
                          ) {
                            beamtoast.error(
                              "Invalid format: A geofence path must be an array where each point must include only numeric 'lat' and 'lng' properties."
                            );
                          } else if (
                            parsedJSON !== null &&
                            Array.isArray(parsedJSON)
                          ) {
                            if (
                              parsedJSON.every(
                                (point) =>
                                  typeof point === "object" &&
                                  Object.keys(point).length === 2 &&
                                  "lat" in point &&
                                  "lng" in point &&
                                  typeof point.lat === "number" &&
                                  typeof point.lng === "number"
                              )
                            ) {
                              const hasDuplicateGeoPoints = parsedJSON.some(
                                (point, index) =>
                                  parsedJSON.findIndex(
                                    (p) =>
                                      p.lat === point.lat && p.lng === point.lng
                                  ) !== index
                              );

                              if (parsedJSON.length < 3) {
                                beamtoast.error(
                                  "Please select at least three points to define a valid geofence area."
                                );
                              } else if (hasDuplicateGeoPoints) {
                                beamtoast.error(
                                  "Duplicate points detected, Please ensure all geofence points are unique."
                                );
                              } else {
                                setErrors({
                                  completeGeofenceCheckbox: "",
                                });
                                setPath(parsedJSON);
                                setIsPolygonComplete(!isPolygonComplete);
                              }
                            } else {
                              beamtoast.error(
                                "Invalid format: Each geofence point must include only numeric 'lat' and 'lng' properties."
                              );
                            }
                          } else {
                            beamtoast.error("Your Geofence path is invalid");
                          }
                        } catch (e) {
                          beamtoast.error("Your Geofence path is invalid");
                        }
                      }}
                    />
                  </div>
                </div>
                <AceEditor
                  height="calc(100vh - 400px)"
                  width="100%"
                  mode="json"
                  theme={
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "ace-editor-theme"
                    ]
                  }
                  name="custom-json"
                  fontSize={14}
                  value={jsonViewPath}
                  onChange={(val) => {
                    try {
                      setJsonViewPath(val);
                      setIsPolygonComplete(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 2,
                  }}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "4px",
                    border: `${
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-border"
                      ]
                    }`,
                    boxShadow: `${
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-box-shadow"
                      ]
                    }`,
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="alert_threshold">
                  Alert threshold (seconds)
                </label>
                <Form.Input
                  min="1"
                  id={"alert_threshold"}
                  type="number"
                  error={
                    errors.thresholdValue !== "" ? errors.thresholdValue : null
                  }
                  placeholder={20}
                  value={alertThreshold}
                  onChange={(e) => {
                    setAlertThreshold(parseInt(e.target.value));
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </StyledTabPaneNoBorder>
        ),
      },
    ],
    [
      versionName,
      errors,
      jsonViewPath,
      path,
      isPolygonComplete,
      alertThreshold,
      theme,
      latitude,
      longitude,
    ]
  );

  return (
    <Modal open={isOpen} onClose={onCloseModal} size="small" className="dark">
      <Modal.Header>{`Create Geofence Configuration`}</Modal.Header>
      <Modal.Content
        style={{
          padding: "12px 18px 0px 18px",
        }}
      >
        <StyledSemanticTabs
          menu={{}}
          panes={panes}
          renderActiveOnly={false}
          activeIndex={activeIndex}
          onTabChange={(e, data) => {
            setActiveIndex(data.activeIndex ?? activeIndex);
          }}
          style={{
            marginBottom: "8px",
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submitButton"
          primary
          onClick={async (e) => {
            await handleSubmit(e);
          }}
        >
          <Icon name={"checkmark"} />
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateGeofenceConfigModal;
