import React, { useState, useEffect, useMemo } from "react";
import { Icon, Button, Form, Modal, Checkbox } from "semantic-ui-react";
import {
  editDeviceConfiguration,
  createDeviceConfiguration,
  DeviceConfigurationType,
} from "../../../../../BytebeamClient";
import { Mixpanel } from "../../../common/MixPanel";
import { DeviceConfigurationsOperationType } from "./DeviceConfigurations";
import GeofenceEditor, { LatLng } from "./GeofenceEditor";
import { useUser } from "../../../../../context/User.context";
import { beamtoast } from "../../../../common/CustomToast";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/ext-searchbox.js";
import ThemeSchema from "../../../../../theme/schema";
import {
  StyledSemanticTabs,
  StyledTabPaneNoBorder,
} from "../../../Dashboards/Panel/util";
import styled from "styled-components";

export const CheckboxWithErrorState = styled(Checkbox)`
  &&&.ui.checkbox {
    .box:before,
    label:before,
    input:checked ~ .box:before,
    input:checked:focus ~ label:before,
    input:checked ~ .box:after,
    input:checked ~ label:after {
      border: 1px solid
        ${({ error, theme }) =>
          error ? "red" : theme.colors["foreground-color"]} !important;
      color: ${({ error, theme }) =>
        error ? "red" : theme.colors["foreground-color"]} !important;
    }
  }
`;

type CreateGeofenceConfigurationsModalProps = {
  readonly type: string;
  readonly changeType: (type: string) => void;
  readonly name: string;
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly setConfig: (value: DeviceConfigurationType) => void;
  readonly fillConfigsTable: () => Promise<void>;
  readonly allExistingDeviceConfigurations: DeviceConfigurationType[];
  readonly config: DeviceConfigurationType;
};

const TYPE_BASED_ACTION = {
  Create: "Create",
  Edit: "Update",
  View: "View",
};

function CreateGeofenceConfigurationsModal(
  props: CreateGeofenceConfigurationsModalProps
) {
  const {
    type,
    config,
    changeType,
    name,
    isOpen,
    setConfig,
    close,
    fillConfigsTable,
    allExistingDeviceConfigurations,
  } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme ?? "dark";

  const [versionName, setVersionName] = useState<string>("");
  const [alertThreshold, setAlertThreshold] = useState<number>(20);
  const [errors, setErrors] = useState<{
    versionName?: string;
    thresholdValue?: string;
    completeGeofenceCheckbox?: string;
  }>({
    versionName: "",
    thresholdValue: "",
    completeGeofenceCheckbox: "",
  });

  const [disableAlertThreshold, setDisableAlertThreshold] =
    useState<boolean>(true);

  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  const [path, setPath] = useState<LatLng[]>([]);
  const [jsonViewPath, setJsonViewPath] = useState<string>("[]");
  const [isPolygonComplete, setIsPolygonComplete] = useState<boolean>(false);

  const [latitude, setLatitude] = useState<number>(12.927381); // defaults to bangalore
  const [longitude, setLongitude] = useState<number>(77.637729); // defaults to bangalore

  const [difference, setDifference] = useState<number>(0); // defaults to bangalore

  const callDeviceConfigApi = () => {
    const configJson = {
      type: "polygon",
      path,
      alert_interval: alertThreshold.toString(),
    };
    if (type === DeviceConfigurationsOperationType.Edit) {
      return editDeviceConfiguration(name, configJson);
    } else {
      return createDeviceConfiguration(
        versionName,
        configJson,
        "update_geofence"
      );
    }
  };

  const updateDeviceConfig = async () => {
    try {
      const newConfig = await callDeviceConfigApi();
      beamtoast.success(
        `${
          type === DeviceConfigurationsOperationType.Edit
            ? "Updated"
            : "Created"
        } Geofence Configuration ${newConfig.version_name}`
      );
      Mixpanel.track("Created Configuration", {
        Config: newConfig.version_name,
      });

      await fillConfigsTable();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "Upload Configuration",
        error: JSON.stringify(e),
      });
      console.log(e);
    } finally {
      onCloseModal();
    }
  };

  async function setConfigDetails() {
    try {
      const { min, max, abs } = Math;

      const latitudes = config.config_json.path.map((coord) => coord.lat);
      const longitudes = config.config_json.path.map((coord) => coord.lng);

      const latDifference = max(...latitudes) - min(...latitudes);
      const lngDifference = max(...longitudes) - min(...longitudes);

      /**
       * this difference is calculated to get a rough idea about the area covered by the coordinates
       * yes, it is not fully accurate but does the job
       */

      const difference = abs(latDifference - lngDifference);

      const latitude =
        config.config_json.path.reduce((sum, coord) => sum + coord.lat, 0) /
        config.config_json.path?.length;
      const longitude =
        config.config_json.path.reduce((sum, coord) => sum + coord.lng, 0) /
        config.config_json.path?.length;

      setLatitude(latitude);
      setLongitude(longitude);
      setDifference(difference);
      setPath(config.config_json.path);
      setJsonViewPath(JSON.stringify(config.config_json.path, null, 2));
      setVersionName(config.version_name);
      setAlertThreshold(Number(config.config_json.alert_interval));
    } catch (e) {
      console.log(e);
    } finally {
      setDisableAlertThreshold(false);
    }
  }

  function versionNameExists(allConfigs, versionName) {
    return allConfigs.some((item) => item.version_name === versionName);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasDuplicateGeoPoints = path.some(
      (point, index) =>
        path.findIndex((p) => p.lat === point.lat && p.lng === point.lng) !==
        index
    );

    if (
      type === DeviceConfigurationsOperationType.Create &&
      versionName?.length === 0
    ) {
      setErrors({
        versionName: "Please enter a Version Name",
      });
    } else if (
      type === DeviceConfigurationsOperationType.Create &&
      versionNameExists(allExistingDeviceConfigurations, versionName)
    ) {
      setErrors({
        versionName: "This geofence config version already exists",
      });
    } else if (alertThreshold < 1) {
      setErrors({
        thresholdValue: "Geofence alert threshold should be greater than 0",
      });
    } else if (!alertThreshold) {
      setErrors({
        thresholdValue: "Please enter valid geofence alert threshold",
      });
    } else if (!isPolygonComplete) {
      beamtoast.error(
        activeIndex === 0
          ? "Please complete geofence selection by clicking on polygon to lock on the map."
          : "Please complete geofence selection using complete geofence polygon checkbox."
      );
    } else if (path.length < 3) {
      beamtoast.error(
        "Please select at least three points to define a valid geofence area."
      );
    } else if (hasDuplicateGeoPoints) {
      beamtoast.error(
        "Duplicate points detected, Please ensure all geofence points are unique."
      );
    } else {
      // If all validations pass, create/update the geofence configuration
      await updateDeviceConfig();
    }
  };

  // Reset state and close modal
  function onCloseModal() {
    // Reset state
    setActiveIndex(0);
    setVersionName("");
    setAlertThreshold(20);
    setIsPolygonComplete(false);
    setPath([]);
    setJsonViewPath("[]");
    setErrors({
      versionName: "",
      thresholdValue: "",
      completeGeofenceCheckbox: "",
    });
    setDisableAlertThreshold(true);

    setLatitude(12.927381);
    setLongitude(77.637729);
    setDifference(0);

    setConfig({
      action_type: "",
      tenant_id: "",
      is_deactivated: false,
      version_name: "",
      config_json: "",
    });

    // Close Modal
    close();
  }

  useEffect(() => {
    if (
      type === DeviceConfigurationsOperationType.Edit ||
      type === DeviceConfigurationsOperationType.View
    ) {
      setConfigDetails();
    }
  }, [type, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (path.length < 3 && isPolygonComplete)
      beamtoast.error(
        "Please select at least three points to define a valid geofence area."
      );
  }, [path, isPolygonComplete]);

  const panes = useMemo(
    () => [
      {
        menuItem: "Map View",
        pane: (
          <StyledTabPaneNoBorder
            key={"map_view"}
            style={{
              padding: "0px",
              marginBottom: "12px",
            }}
          >
            <Form>
              <Form.Field>
                <label>Version Name</label>
                <Form.Input
                  disabled={
                    type === DeviceConfigurationsOperationType.Edit ||
                    type === DeviceConfigurationsOperationType.View
                  }
                  error={errors.versionName !== "" ? errors.versionName : null}
                  placeholder={
                    type === DeviceConfigurationsOperationType.Edit ||
                    type === DeviceConfigurationsOperationType.View
                      ? name
                      : "production v1"
                  }
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Click to create geofence polygon</label>
                <GeofenceEditor
                  editable={type !== DeviceConfigurationsOperationType.View}
                  latitude={latitude}
                  longitude={longitude}
                  difference={difference}
                  path={path}
                  onPolygonChanged={(path, isComplete) => {
                    setPath(path);
                    setJsonViewPath(JSON.stringify(path, null, 2));
                    setIsPolygonComplete(isComplete);
                  }}
                  theme={theme}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="alert_threshold">
                  Alert threshold (seconds)
                </label>
                <Form.Input
                  id="alert_threshold"
                  min="1"
                  type="number"
                  error={
                    errors.thresholdValue !== "" ? errors.thresholdValue : null
                  }
                  disabled={
                    type === DeviceConfigurationsOperationType.View
                      ? true
                      : type === DeviceConfigurationsOperationType.Create
                        ? false
                        : disableAlertThreshold
                  }
                  placeholder={20}
                  value={alertThreshold ? alertThreshold : null}
                  onChange={(e) => {
                    setAlertThreshold(parseInt(e.target.value));
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </StyledTabPaneNoBorder>
        ),
      },
      {
        menuItem: "JSON View",
        pane: (
          <StyledTabPaneNoBorder
            key={"json_view"}
            style={{
              padding: "0px",
              marginBottom: "12px",
            }}
          >
            <Form>
              <Form.Field>
                <label>Version Name</label>
                <Form.Input
                  disabled={
                    type === DeviceConfigurationsOperationType.Edit ||
                    type === DeviceConfigurationsOperationType.View
                  }
                  error={errors.versionName !== "" ? errors.versionName : null}
                  placeholder={
                    type === DeviceConfigurationsOperationType.Edit ||
                    type === DeviceConfigurationsOperationType.View
                      ? name
                      : "production v1"
                  }
                  value={versionName}
                  onChange={(e) => {
                    setVersionName(e.target.value);
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <label>JSON geofence polygon</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    Complete geofence polygon{" "}
                    <CheckboxWithErrorState
                      error={Boolean(errors.completeGeofenceCheckbox)}
                      checked={isPolygonComplete}
                      onChange={() => {
                        try {
                          let parsedJSON = JSON.parse(jsonViewPath);

                          if (
                            parsedJSON !== null &&
                            typeof parsedJSON === "object" &&
                            !Array.isArray(parsedJSON)
                          ) {
                            beamtoast.error(
                              "Invalid format: A geofence path must be an array where each point must include only numeric 'lat' and 'lng' properties."
                            );
                          } else if (
                            parsedJSON !== null &&
                            Array.isArray(parsedJSON)
                          ) {
                            if (
                              parsedJSON.every(
                                (point) =>
                                  typeof point === "object" &&
                                  Object.keys(point).length === 2 &&
                                  "lat" in point &&
                                  "lng" in point &&
                                  typeof point.lat === "number" &&
                                  typeof point.lng === "number"
                              )
                            ) {
                              const hasDuplicateGeoPoints = parsedJSON.some(
                                (point, index) =>
                                  parsedJSON.findIndex(
                                    (p) =>
                                      p.lat === point.lat && p.lng === point.lng
                                  ) !== index
                              );

                              if (parsedJSON.length < 3) {
                                beamtoast.error(
                                  "Please select at least three points to define a valid geofence area."
                                );
                              } else if (hasDuplicateGeoPoints) {
                                beamtoast.error(
                                  "Duplicate points detected, Please ensure all geofence points are unique."
                                );
                              } else {
                                setErrors({
                                  completeGeofenceCheckbox: "",
                                });
                                setPath(parsedJSON);
                                setIsPolygonComplete(!isPolygonComplete);
                              }
                            } else {
                              beamtoast.error(
                                "Invalid format: Each geofence point must include only numeric 'lat' and 'lng' properties."
                              );
                            }
                          } else {
                            beamtoast.error("Your Geofence path is invalid");
                          }
                        } catch (e) {
                          beamtoast.error("Your Geofence path is invalid");
                        }
                      }}
                    />
                  </div>
                </div>
                <AceEditor
                  height="calc(100vh - 400px)"
                  width="100%"
                  mode="json"
                  theme={
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "ace-editor-theme"
                    ]
                  }
                  name="custom-json"
                  fontSize={14}
                  value={jsonViewPath}
                  onChange={(val) => {
                    try {
                      setJsonViewPath(val);
                      setIsPolygonComplete(false);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 2,
                  }}
                  style={{
                    marginBottom: "16px",
                    borderRadius: "4px",
                    border: `${
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-border"
                      ]
                    }`,
                    boxShadow: `${
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-box-shadow"
                      ]
                    }`,
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="alert_threshold">
                  Alert threshold (seconds)
                </label>
                <Form.Input
                  id="alert_threshold"
                  min="1"
                  type="number"
                  error={
                    errors.thresholdValue !== "" ? errors.thresholdValue : null
                  }
                  disabled={
                    type === DeviceConfigurationsOperationType.View
                      ? true
                      : type === DeviceConfigurationsOperationType.Create
                        ? false
                        : disableAlertThreshold
                  }
                  placeholder={20}
                  value={alertThreshold ? alertThreshold : null}
                  onChange={(e) => {
                    setAlertThreshold(parseInt(e.target.value));
                    setErrors({
                      versionName: "",
                    });
                  }}
                />
              </Form.Field>
            </Form>
          </StyledTabPaneNoBorder>
        ),
      },
    ],
    [
      type,
      name,
      versionName,
      errors,
      jsonViewPath,
      path,
      isPolygonComplete,
      alertThreshold,
      theme,
      disableAlertThreshold,
      difference,
      latitude,
      longitude,
    ]
  );

  return (
    <Modal open={isOpen} onClose={onCloseModal} size="small" className="dark">
      <Modal.Header>{`${TYPE_BASED_ACTION[type]} Geofence Configuration`}</Modal.Header>
      <Modal.Content
        style={{
          padding: "12px 18px 0px 18px",
        }}
      >
        <StyledSemanticTabs
          menu={{}}
          panes={panes}
          renderActiveOnly={false}
          activeIndex={activeIndex}
          onTabChange={(e, data) => {
            setActiveIndex(data.activeIndex ?? activeIndex);
          }}
          style={{
            marginBottom: "8px",
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          id="submitButton"
          primary
          disabled={config.is_deactivated}
          onClick={async (e) => {
            if (type !== DeviceConfigurationsOperationType.View)
              await handleSubmit(e);
            else {
              setPath([]);
              setJsonViewPath("[]");
              changeType(DeviceConfigurationsOperationType.Edit);
            }
          }}
        >
          <Icon
            name={
              type === DeviceConfigurationsOperationType.View
                ? "edit"
                : "checkmark"
            }
          />{" "}
          {type === DeviceConfigurationsOperationType.View
            ? "Edit"
            : TYPE_BASED_ACTION[type]}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CreateGeofenceConfigurationsModal;
