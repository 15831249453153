import { createGlobalStyle } from "styled-components";
import { breakpoints } from "../components/common/breakpoints";

export const GlobalStyles = createGlobalStyle`
::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: transparent !important;
}

body ::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) =>
    theme.colors["scrollbar_thumb-background"]} !important;
  border-radius: 8px;
}

.app-container {
  padding-top: 56.5px;
  padding-bottom: 36px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.toast-top-right {
  margin-top: 56.5px;
}

.app {
  min-width: 90%;
  width: 100%;
  display: table;
  padding: 24px 36px 64px 36px;
  min-height: calc(100vh - 92.5)px;
  border: none;
  border-radius: 0.28571429rem;
}

.app .ui.progress .bar {
  min-width: 36% !important;
}

.metadata-cell-container {
  .metadata-cell-icon {
    visibility: hidden;
  }

  &:hover {
    .metadata-cell-icon {
      visibility: visible;
    }
  }
}

body {
  background-color: ${({ theme }) =>
    theme.colors["background-color"]} !important;
  overflow-x: auto !important;
}

i.link.icon.disabled {
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.45 !important;
  }
}

// -------------------- G Map Info Window Style --------------------
.gm-style .gm-style-iw-c {
    overflow: visible !important;
    padding: 15px !important;
  }
  .gm-style-iw-chr {
    position: absolute;
    top: -12px;
    right: -12px;

    button {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      background: #fff !important;
      border: 1px solid #000 !important;
      border-radius: 50% !important;
      width: 28px !important;
      height: 28px !important;
      opacity: 1 !important;

      span {
        background-repeat: no-repeat !important;
        margin: 0px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }

    .gm-style-iw-ch {
      display: hidden !important;
    }
  }
// ------------------ G Map Info Window Style Ends ------------------

.dark {
  background-color: ${({ theme }) => theme.colors["background-color"]};
  color: ${({ theme }) => theme.colors["foreground-color"]};

  .ui.selection.dropdown .menu > .message {
    color: ${({ theme }) => theme.colors["foreground-color"]};
  }

  .ui.menu,
  .ui.vertical.tabular.menu {
    background-color: ${({ theme }) =>
      theme.colors["secondary_tab-background"]};
    color: ${({ theme }) => theme.colors["foreground-color"]};

    .item {
      color: ${({ theme }) => theme.colors["foreground-color"]};
      opacity: 1;

      &:hover {
        color: ${({ theme }) => theme.colors["secondary_menu_tab-text-hover"]};
        background-color: ${({ theme }) =>
          theme.colors["vertical-menu-hover-color"]};
        opacity: 1;
      }

      &.logo {
        opacity: 1;
      }
    }

    .right.menu {
      .item {
        opacity: 1;

        .ui.button {
          background-color: unset;
          color: unset;
        }
      }
    }

    .active.item {
      color: ${({ theme }) => theme.colors["vertical-menu-active-text-color"]};
      opacity: 1;
      background-color: ${({ theme }) => theme.colors["secondary-color"]};

      &:hover {
        color: ${({ theme }) =>
          theme.colors["vertical-menu-active-hover-text-color"]};
        opacity: 1;
        background-color: ${({ theme }) =>
          theme.colors["secondary-hover-color"]};
      }
    }
  }
  .ui.dropdown {
    &:focus-within {
      border: 1px solid ${({ theme }) => theme.colors["inputs-border"]};
    }
  }
  .ui.input {
    color: ${({ theme }) => theme.colors["foreground-color"]};
    border: 1px solid ${({ theme }) => theme.colors["input-unselected-border"]};
    border-radius: 2px;

    input,
    input:focus,
    input[type="text"] {
      background: ${({ theme }) => theme.colors["background-color"]};
      color: ${({ theme }) => theme.colors["foreground-color"]};
      border: 0px;
    }

    input::placeholder {
      color: ${({ theme }) =>
        theme.colors["search_input-placeholder-color"]} !important;
    }
  
    input:focus::placeholder {
      color: ${({ theme }) =>
        theme.colors["search_input-placeholder-color-focus"]} !important;
    }

    &:focus-within {
      border: 1px solid ${({ theme }) => theme.colors["inputs-border"]};
    }

    .ui.basic.button.dropdown {
      border-left: 2px solid ${({ theme }) =>
        theme.colors["input-unselected-border"]};
        color: ${({ theme }) => theme.colors["foreground-color"]};
    }

    .ui.selection.dropdown {
      border-width: 0px;
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    }
  }

  textarea {
    background-color: ${({ theme }) => theme.colors["background-color"]};
    color: ${({ theme }) => theme.colors["foreground-color"]};
    border: 1px solid ${({ theme }) => theme.colors["input-unselected-border"]};
    border-radius: 2px;

    &:hover {
      border-color: ${({ theme }) => theme.colors["inputs-border"]};
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors["inputs-border"]};
      outline: none;
    }
  }

  input::selection {
    color: rgba(255, 255, 255, 0.87);
  }

  .ui.labeled.input {
    .ui.label {
      background: ${({ theme }) => theme.colors["input-label-bg"]} !important;
      color: unset;
      border-radius: 5px;
    }
  }

  .ui.pagination.menu {
    box-shadow: ${({ theme }) =>
      theme.colors["pagination-box-shadow"]} !important;

    .active.item {
    color: rgba(255, 255, 255, 0.95);
    background-color: ${({ theme }) =>
      theme.colors["pagination-active-item-color"]};
  }
    .item {
      &:hover{
        background-color: ${({ theme }) =>
          theme.colors["pagination-item-background-hover-color"]} !important;
        box-shadow: none;
      }
    }
  }

  .ui.labels .ui.label {
    background-color: ${({ theme }) => theme.colors["ui-label-bg"]};
    color: white;
  }

  .rc-slider-rail {
    background-color: gray;
  }

  .ui.checkbox {
    .box:before,
    label:before,
    input:checked ~ .box:before,
    input:checked:focus ~ label:before,
    input:checked ~ .box:after,
    input:checked ~ label:after {
      background-color: ${({ theme }) =>
        theme.colors["background-color"]} !important;
      border: 1px solid ${({ theme }) =>
        theme.colors["foreground-color"]} !important;
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
      border-radius: 0.21428571rem;
    }

    .box:hover:before,
    label:hover:before {
      background-color: ${({ theme }) => theme.colors["background-color"]};
      border: 1px solid ${({ theme }) => theme.colors["foreground-color"]};
      border: 1px solid ${({ theme }) =>
        theme.colors["inputs-border"]} !important;
      border-radius: 0.21428571rem;
    }
  }

  .ui.checkbox.radio.checkbox {
    label:after {
      top: 3px !important;
      left: 2px !important;
      width: 11px !important;
      height: 11px !important;
      background-color: ${({ theme }) => theme.colors["foreground-color"]} !important;
      border-radius: 50% !important;
    }
    label:before {
      background-color: ${({ theme }) => theme.colors["background-color"]} !important;
      border-radius: 50% !important;
    }
  }

  .ui.checkbox label,
  .ui.checkbox + label {
    color: rgba(255, 255, 255, 0.87) !important;
  }

  .ui.toggle.checkbox {
    .box:before,
    label:before,
    input:checked ~ .box:before,
    input:checked ~ label:before,
    input:checked ~ box:before {
      background-color: ${({ theme }) => theme.colors["background-color"]};
      border: 1px solid ${({ theme }) =>
        theme.colors["foreground-color"]} !important;
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
      border-radius: 500rem;
    }

    input ~ label:after {
      border: 1px solid ${({ theme }) =>
        theme.colors["foreground-color"]} !important;
    }

    input:focus:checked ~ label:before {
      background-color: ${({ theme }) =>
        theme.colors["toggle-enabled-color"]} !important;
    }

    input:checked ~ .box:after,
    input:checked ~ label:after {
      background-color: rgb(255, 255, 255) !important;
      border-radius: 500rem;
    }

    .box:hover:before,
    label:hover:before {
      background-color: ${({ theme }) => theme.colors["background-color"]};
      border: 1px solid ${({ theme }) => theme.colors["foreground-color"]};
      border: 1px solid ${({ theme }) =>
        theme.colors["inputs-border"]} !important;
      border-radius: 500rem;
    }
  }

  .bytebeam-panel {
    background: ${({ theme }) => theme.colors["panel-background"]};
    border-radius: 10px;
    color: ${({ theme }) => theme.colors["component-bold-text"]};
    border: ${({ theme }) => theme.colors["panel-border"]};
    box-shadow: ${({ theme }) => theme.colors["panel-box_shadow"]};

    &:hover, &:focus, &:active {
      outline: 1px solid ${({ theme }) => theme.colors["panel-border-hover"]};

      .bytebeam-panel-icons {
        opacity: 1;
      }
    }

    .bytebeam-panel-icons {
      opacity: 0;
    }

    .bytebeam-panel-title {
      display: inline-block;
      padding-top: 15px;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 5px;
      background: ${({ theme }) => theme.colors["panel-background"]};
      color: ${({ theme }) => theme.colors["panel-title"]};
      border-top-left-radius: 10px;
      border-top-right-radius: 0px;
    }

    .main-svg {
      background-color: rgba(0, 0, 0, 0) !important;

      .infolayer .bg {
        fill: rgba(0, 0, 0, 0) !important;
      }
    }

    .gridlayer {
      .xgrid,
      .ygrid {
        stroke: ${({ theme }) => theme.colors["grid-stroke"]} !important;
      }
    }

    .infolayer {
      .legendtext {
        fill: rgb(150, 150, 150) !important;
      }
    }
  }

  .ui.button.primary {
    background-color: ${({ theme }) =>
      theme.colors["primary-color"]} !important;
    color: ${({ theme }) => theme.colors["cta-button-bold-text"]} !important;

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors["primary-hover-color"]} !important;
      color: ${({ theme }) =>
        theme.colors["cta-button-bold-hover-text"]} !important;
    }
  }

  .ui.button.secondary {
    background-color: ${({ theme }) => theme.colors["secondary-button-color"]};
    color: ${({ theme }) => theme.colors["cta-button-bold-text"]};

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors["secondary-button-hover-color"]};
      color: ${({ theme }) => theme.colors["cta-button-bold-hover-text"]};
    }
  }

  .ui.button.add-panel-button {
    background-color: ${({ theme }) =>
      theme.colors["panelButton-background-color"]};
    color: ${({ theme }) => theme.colors["panelButton-text-color"]};
    border-radius: 8px;
    border: ${({ theme }) => theme.colors["panelButton-border"]};
    box-shadow: ${({ theme }) => theme.colors["panelButton-box-shadow"]};

    .svgImageComponent {
      padding: 15px;
      color: ${({ theme }) => theme.colors["panel-icons-color"]} !important; 
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors["panelButton-background-hover-color"]};
      color: ${({ theme }) =>
        theme.colors["panelButton-text-hover-color"]} !important;
      border: ${({ theme }) => theme.colors["panelButton-hover-border"]};
      box-shadow: ${({ theme }) =>
        theme.colors["panelButton-hover-box-shadow"]};

      .imageCaption{
        color: ${({ theme }) =>
          theme.colors["panelButton-text-hover-color"]} !important;
      }

      .svgImageComponent {
        color: ${({ theme }) =>
          theme.colors["panel-icons-hover-color"]} !important;
      }
    }
  }

  .ui.table,
  .ui.sortable.table {
    background: unset;
    color: ${({ theme }) => theme.colors["table-text"]} !important;
    border-color: ${({ theme }) => theme.colors["table-outer-border"]};

    thead th,
    thead th.sorted {
      background: ${({ theme }) =>
        theme.colors["table-header-background-color"]};
      color: ${({ theme }) => theme.colors["table-bold-text"]} !important;
    }

    thead th:hover,
    thead th.sorted:hover {
      background: ${({ theme }) =>
        theme.colors["table-header-hover-background-color"]};
      color: ${({ theme }) => theme.colors["table-bold-text"]} !important;
    }

    tbody tr td {
      border-bottom: 1px solid ${({ theme }) =>
        theme.colors["datatable-bottom-border"]};
    }

    tbody tr:last-child td {
      border-bottom: unset;
    }
  }

  .ui.table th,
  .ui.table td {
    overflow: visible !important;
  }

  .ui.table thead tr th {
    white-space: nowrap !important;
  }

  @media only screen and  (max-width: ${breakpoints.sm}px){
    .ui.table:not(.unstackable) th:first-child,
    .ui.table:not(.unstackable) td:first-child{
      font-weight: normal;
    }
  }

  .ui.selectable.table tbody tr:hover,
  .ui.table tbody tr td.selectable:hover {
    background: ${({ theme }) =>
      theme.colors["table-hover-cell-color"]} !important;
    color: ${({ theme }) => theme.colors["table-hover-text-color"]} !important;
  }

  .ui.inverted.table tr.warning,
  .ui.inverted.table td.warning {
    background: rgb(84, 84, 84) !important;
    color: rgb(255, 255, 255) !important;
  }

  .ui.table tr.warning {
    background:${({ theme }) =>
      theme.colors["table-row-warning-background-color"]} !important;
    color: ${({ theme }) => theme.colors["table-row-warning-color"]} !important;
    font-weight: 600;
  }

  .ui.inverted.table tr.error,
  .ui.inverted.table td.error {
    background: rgb(84, 84, 84) !important;
    color: rgb(255, 255, 255) !important;
  }

  .ui.selection.dropdown {
    background-color: unset !important;
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    border-color: ${({ theme }) => theme.colors["input-unselected-border"]};

    display: flex;
    align-items: center;

    .dropdown.icon {
      top: unset;
      margin: 0;
      padding: 0;
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors["inputs-border"]};
    }

    &:hover .text:not(.default) {
      color: ${({ theme }) => theme.colors["input-text-active-hover"]} ;
    }

    input + .default.text:not(button){
      color: ${({ theme }) =>
        theme.colors["search_input-placeholder-color"]} !important;
    }
  
    input:focus + .default.text:not(button) {
      color: ${({ theme }) =>
        theme.colors["search_input-placeholder-color-focus"]} !important;
    }

    &,
    &.visible {
      .text:not(.default) {
        color: ${({ theme }) => theme.colors["text"]} !important;
      }
    }

    .text:not(.default) {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: normal;
    }

    &.visible {
      .text:not(.default) {
        white-space: unset;
        word-wrap: break-word;
      }
    }
  }

  .ui.dropdown:not(.button) > input:focus ~ .default.text {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color-focus"]} !important;
  }

  .ui.dropdown:not(.button) > .default.text {
    color: ${({ theme }) =>
      theme.colors["search_input-placeholder-color"]} !important;
      
  }

  .ui.multiple.search.dropdown > .label ~ .text {
    display: none !important;
  }

  .ui.multiple.search.dropdown > input.search {
    color: ${({ theme }) => theme.colors["foreground-color"]};
  }

  .ui.multiple.dropdown {
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;

  }

  .ui.multiple.dropdown > .label {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: nowrap !important;
    background: ${({ theme }) =>
      theme.colors["input-label-filter-bg"]} !important;
    color: ${({ theme }) =>
      theme.colors["input-label-filter-text-color"]} !important;
    margin-left: 1px !important;
    word-break: keep-all !important;
    white-space: nowrap !important;
  }

  .mail-tags {
    background: unset;
    color: unset;
  }

  .ui.segment {
    background: unset;
    color: unset;
  }

  &.ui.modal {
    background-color: ${({ theme }) =>
      theme.colors["background-color"]} !important;
      color: ${({ theme }) =>
        theme.colors["modal-heading-text-color"]} !important;

  &.scrolling {
    margin-top: 0px !important;
  }

    /* Need to figure out how to make the modal scrollable and dropdown outside the modal with adding scroll to the modal */
    /* & > .content {
      max-height: calc(100vh - 145px) !important;
      overflow: auto;
      position: relative !important;
      z-index: 1 !important;
    }
    & > .actions {
      position: relative !important;
      z-index: 1 !important;
    } */

    .content,
    .header,
    .actions {
      background-color: ${({ theme }) =>
        theme.colors["background-color"]} !important;
      color: ${({ theme }) =>
        theme.colors["modal-heading-text-color"]} !important;
    }

    .header {
      border-bottom: 1px solid  ${({ theme }) =>
        theme.colors["modal-separation-line-color"]};
    }

    .actions {
      border-top: 1px solid ${({ theme }) =>
        theme.colors["modal-separation-line-color"]};
    }

    & > .close {
      top: 1rem;
      right: 1rem;
      font-size: 1.5em;
      color: ${({ theme }) =>
        theme.colors["modal-heading-text-color"]} !important;
    }
  }

  #actionOptions1.ui.selection.dropdown {
    min-width: 100px !important;
  }

  .action-buttons,
  .dashboard-header {
    .ui.button {
      background-color: ${({ theme }) =>
        theme.colors["input-label-bg"]} !important;
      color: ${({ theme }) => theme.colors["component-bold-text"]} !important;

      &:has(a.label) { background-color: unset !important; }

      &:hover {
        background-color: ${({ theme }) =>
          theme.colors["input-label-bg-hover"]} !important;
        color: ${({ theme }) =>
          theme.colors["component-bold-text-hover"]} !important;
      }
    }
  }

  #save-button {
    background-color: ${({ theme }) =>
      theme.colors["save-button-bg"]} !important;
      color: ${({ theme }) => theme.colors["save-button-color"]} !important;

      &:hover {
        color: ${({ theme }) =>
          theme.colors["save-button-color-hover"]} !important;
      }
  }

  .action-buttons {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
  }

  .ui.buttons .or:before {
    background-color: ${({ theme }) => theme.colors["background-color"]};
    color: unset;
  }

  .ui.basic.button {
    background: unset !important;
    color: unset !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .ui.form {
    input,
    input[type="file"] {
      background: ${({ theme }) => theme.colors["background-color"]};
      color: unset;

      &:focus {
        background: unset !important;
        color: unset !important;
      }
    }

    .field > label {
      color: ${({ theme }) => theme.colors["foreground-color"]};
    }
    textarea {
      background: ${({ theme }) => theme.colors["background-color"]};
      border: ${({ theme }) =>
        theme.colors["textarea-bottom-border"]} 1px solid;
      color: unset;

      &:focus {
        background: unset;
        color: unset;
      }
    }

    .field.error input[type="text"] {
      background-color: transparent !important;
      color: #f77 !important;
    }
  }

  .ui.pointing.above.prompt.label {
    background-color: ${({ theme }) => theme.colors["input-label-bg"]} !important;
    color: #f77 !important;
    border: 1px solid #f77 !important;
  }

  .ui.breadcrumb .divider {
    color: ${({ theme }) => theme.colors["breadcrumb-divider"]};
  }

  .ui.accordion .title:not(.ui) {
    color: unset;
  }

  .ui.black.message {
    background-color: ${({ theme }) => theme.colors["input-label-bg"]};
  }

  // Status Title in Action Status Card Styles
  .title {
    &.initiated {
      color: ${({ theme }) => theme.colors["progress_bar-initiated"]};
    }

    &.completed {
      color: ${({ theme }) => theme.colors["progress_bar-completed"]};
    }

    &.failed {
      color: ${({ theme }) => theme.colors["progress_bar-failed"]};
    }

    &.queued {
      color: ${({ theme }) => theme.colors["progress_bar-queued"]};
    }

    &.in-progress {
      color: ${({ theme }) => theme.colors["progress_bar-in_progress"]};
    }

    &.scheduled {
      color: ${({ theme }) => theme.colors["progress_bar-scheduled"]};
    }

    &.pending_approval {
      color: ${({ theme }) => theme.colors["progress_bar-pending_approval"]};
    }
  }

  // Linear Progress Bar Styles
  .ui.progress {
    background: ${({ theme }) =>
      theme.colors["progress_bar-background"]} !important;

    &.initiated .bar {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-initiated"]};
      font-weight: bold;
      .progress {
        color: ${({ theme }) =>
          theme.colors["progress-bar-inner-text-color"]} !important;
      }
    }

    &.completed .bar {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-completed"]};
      font-weight: bold;
      .progress {
        color: ${({ theme }) =>
          theme.colors["progress-bar-inner-text-color"]} !important;
      }
    }

    &.failed .bar {
      background-color: ${({ theme }) => theme.colors["progress_bar-failed"]};
      font-weight: bold;
      .progress {
        color: ${({ theme }) =>
          theme.colors["progress-bar-inner-text-color"]} !important;
      }
    }

    &.queued .bar {
      background-color: ${({ theme }) => theme.colors["progress_bar-queued"]};
      font-weight: bold;
      .progress {
        color: ${({ theme }) =>
          theme.colors["progress-bar-inner-text-color"]} !important;
      }
    }

    &.in-progress .bar {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-in_progress"]};
      font-weight: bold;
      .progress {
        color: #17191d !important;
      }
    }

    &.scheduled .bar {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-scheduled"]};
      font-weight: bold;
      .progress {
        color: #17191d !important;
      }
    }

    &.pending_approval .bar {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-pending_approval"]};
      font-weight: bold;
      .progress {
        color: #17191d !important;
      }
    }
  }

  .progress-bar-action-status {
    width: 50%;
    margin-bottom: 0px !important;
  }

  .ui.divider {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .ui.selection.active.dropdown .menu {
    border-color: ${({ theme }) => theme.colors["inputs-border"]};
    color: ${({ theme }) => theme.colors["foreground-color"]};
  }

  .ui.dropdown .menu {
    /* position: absolute !important;
    z-index: 1050 !important;  */
    background-color: ${({ theme }) =>
      theme.colors["dropdown-menu"]} !important;
    box-shadow: rgba(0, 0, 0, 0.5) 4px 4px 8px !important;
    margin-top: 2px !important;
    padding: 0px;
    color: ${({ theme }) => theme.colors["foreground-color"]} !important;
    border: 1px solid rgba(82, 82, 115, 0.5) !important;

    .item {
    display:flex !important;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap !important;
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
      border-top: 0px;

      &:hover {
        background-color: ${({ theme }) =>
          theme.colors["deviceId-drop-down-background-color"]} !important;
      }
    }

    .divider {
      border-top: 1px solid rgba(221, 219, 217, 0.5);
    }
  }

  .ui.menu .ui.dropdown .menu {
    .item {
      color: ${({ theme }) => theme.colors["foreground-color"]} !important;
      border-top: 0px;

      &:hover {
        color: ${({ theme }) => theme.colors["tab-hover-text"]} !important;
        background-color: ${({ theme }) =>
          theme.colors["navbar-dropdown-background-hover-color"]} !important;
      }
    }

    .active.item {
      background-color: ${({ theme }) =>
        theme.colors["secondary-color"]} !important;

      span {
        color: ${({ theme }) => theme.colors["dropdown-bold-text"]} !important;
      }
      &:hover {
        color: ${({ theme }) =>
          theme.colors["secondary_menu_tab-text-hover"]} !important;
        background-color: ${({ theme }) =>
          theme.colors["secondary-hover-color"]} !important;
      }
    }

    .divider {
      border-top: 1px solid rgba(255 - 34, 255 - 36, 255 - 38, 0.5);
    }
  }

  .ui.selection.dropdown .menu > .item {
    border-top: 0px;
    color: ${({ theme }) => theme.colors["foreground-color"]};
  }

  .ui.dropdown .menu {
    background-color: ${({ theme }) => theme.colors["input-label-bg"]};
    border: 0px;

    .selected.item {
      background-color: ${({ theme }) =>
        theme.colors["dropdown-menu-selected-items-background-color"]};
    }

    .item:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .item {
      color: unset;
    }
  }

  .dashboard-header {
    width: 100%;
    align-items: top;

    .dashboard-header-row-1 {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .header-left {
        font-size: 24px;
        .dashboard-title {
          height: 100% !important;
          /* float: left; */
          line-height: 32px;
        }
      }

      .header-right {
        display: flex;
        gap: 8px 0px;
      }

      .ui.selection.dropdown {
        background-color: ${({ theme }) =>
          theme.colors["input-label-bg"]} !important;
        border: 0px;
        color: ${({ theme }) => theme.colors["foreground-color"]};
      }
    }

    .dashboard-header-row-2 {
      display: flex !important;
      align-items: center;
      justify-content: space-between; 
      gap: 12px 0px;
      flex-wrap: wrap;
      margin-top: 12px;
      .ui.selection.dropdown {
        border: 2px solid ${({ theme }) =>
          theme.colors["dropdown-border"]} !important;
        border-radius: 5px;
        color: ${({ theme }) => theme.colors["foreground-color"]};
      }
      .filter.icon {
        font-size: 18px;
        margin-right: 16px;
        color: ${({ theme }) => theme.colors["filter-icon"]};
      }

      .dashboard-header-row-2-left {
        .ui.button.primary {
          .icon {
            color: ${({ theme }) => theme.colors["filter-icon"]} !important;
          }
        }
      }
    }
  }

  .dashboard-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    gap: 12px 0px;
    flex-wrap: wrap;
    .filter.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-right: 16px;
    }
    .ui.selection.dropdown {
      border: 2px solid ${({ theme }) =>
        theme.colors["dropdown-border"]} !important;
      border-radius: 5px;
      color: ${({ theme }) => theme.colors["foreground-color"]};
    }
  }

  .customDropDown {
    font-size: 	14px !important;
    font-weight: 600 !important;

    &.ui.dropdown .menu .disabled.item{
      padding: 4px 16px !important;

      .divider {
        border-top: ${(props) =>
          `1px solid ${props.theme.colors["dropdown-divider-color"]}`} !important;
      }

      &:hover {
      background-color: ${({ theme }) =>
        theme.colors["secondary_menu_tab-background"]} !important;
      color: ${({ theme }) =>
        theme.colors["secondary_menu_tab-text"]} !important;
      }
    }
  }

  .last-refresh-time {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 156px;
    background-color: ${({ theme }) => theme.colors["refreshtime-bg"]};
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }

  .last-refresh-time-error {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 156px;
    background-color: #da2828;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }

  .last-refresh-time-stale {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 156px;
    background-color: ${({ theme }) => theme.colors["refreshtime-stale"]};
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }

  .info-icon {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .ui.search.dropdown.active > input.search,
  .ui.search.dropdown.visible > input.search {
    color: ${({ theme }) => theme.colors["foreground-color"]};
  }
}

.ui.dropdown .angle.double.right.icon {
  margin: 0px;
}

.help-icon {
  margin: 0 !important;
  font-size: 18px !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

// Secondary Menu Tab Styles (Settings Page and Inventory Page)
.ui.secondary.menu {
  background-color: ${({ theme }) =>
    theme.colors["secondary_menu_tab-background"]} !important;

  .active.item {
    background-color: ${({ theme }) =>
      theme.colors["secondary_menu_tab-background"]} !important;
    color: ${({ theme }) =>
      theme.colors["secondary_menu_tab-text-active"]} !important;
    border-bottom: 1px solid ${({ theme }) =>
      theme.colors["secondary_menu_tab-text-active"]} !important;
    font-weight: 700 !important;
    border-radius: 0px !important;
  }

  .item:hover {
    background-color: ${({ theme }) =>
      theme.colors["secondary_menu_tab-background"]} !important;
    color: ${({ theme }) =>
      theme.colors["secondary_menu_tab-text-hover"]} !important;
  }
}

.ui.message {
  background-color: ${({ theme }) => theme.colors["input-label-bg"]};
  color: ${({ theme }) => theme.colors["ui-msg-color"]};
  border: 1px solid ${({ theme }) =>
    theme.colors["dropdown-border"]} !important;
  border-radius: 5px !important;
}

.beta-label {
  height: 55px !important;
  width: 55px !important;
}

.action-progress-item {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.initiated {
    background-color: ${({ theme }) =>
      theme.colors["progress_bar-initiated"]};  
  }

  &.completed {
    background-color: ${({ theme }) => theme.colors["progress_bar-completed"]}; 
  }

  &.failed {
    background-color: ${({ theme }) => theme.colors["progress_bar-failed"]}; 
  }

  &.queued {
    background-color: ${({ theme }) => theme.colors["progress_bar-queued"]}; 
  }

  &.in-progress {
    background-color: ${({ theme }) =>
      theme.colors["progress_bar-in_progress"]}; 
  }

  &.scheduled {
    background-color: ${({ theme }) => theme.colors["progress_bar-scheduled"]}; 
  }

  &.pending_approval{
    background-color: ${({ theme }) => theme.colors["progress_bar-pending_approval"]};
  }
}

.action-progress-pulsating-circle {
  position: absolute;
  width: 50px;
  height: 50px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: 2;
    animation-fill-mode: forwards;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  &.initiated {
    &:after {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-initiated"]}; 
    }
    &:before {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-initiated"]}; 
    }
  }

  &.completed {
    &:after {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-completed"]}; 
    }
    &:before {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-completed"]}; 
    }
  }

  &.failed {
    &:after {
      background-color: ${({ theme }) => theme.colors["progress_bar-failed"]}; 
    }
    &:before {
      background-color: ${({ theme }) => theme.colors["progress_bar-failed"]}; 
    }
  }

  &.queued {
    &:after {
      background-color: ${({ theme }) => theme.colors["progress_bar-queued"]}; 
    }
    &:before {
      background-color: ${({ theme }) => theme.colors["progress_bar-queued"]}; 
    }
  }

  &.in-progress {
    &:after {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-in_progress"]}; 
    }
    &:before {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-in_progress"]}; 
    }
  }

  &.scheduled {
    &:after {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-scheduled"]}; 
    }
    &:before {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-scheduled"]}; 
    }
  }

  &.pending_approval{
    &:after {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-pending_approval"]}; 
    }
    &:before {
      background-color: ${({ theme }) =>
        theme.colors["progress_bar-pending_approval"]}; 
    }
  }
}

.action-progress-connector {
  height: 5px;
  width: 150px;

  &.active {
    background-color: ${({ theme }) => theme.colors["progress_bar-completed"]};
  }

  &.inactive {
    background-color: ${({ theme }) => theme.colors["progress_bar-queued"]}; 
  }

  &.failed {
    background-color: ${({ theme }) => theme.colors["progress_bar-failed"]}; 
  }
}

.main-navbar {
  background-color: ${({ theme }) =>
    theme.colors["navbar-background"]} !important;
    color: ${({ theme }) => theme.colors["navbar-item-text-color"]} !important;
    border-bottom: 1px solid  ${({ theme }) =>
      theme.colors["modal-separation-line-color"]};
  .item {
    color: ${({ theme }) => theme.colors["navbar-item-text-color"]} !important;
    opacity: 0.8 !important;
    &:hover {
      opacity: 1 !important;
      color: ${({ theme }) =>
        theme.colors["navbar-item-text-hover-color"]} !important;
      background-color: ${({ theme }) =>
        theme.colors["navbar-item-background-hover-color"]} !important;
      box-shadow: ${({ theme }) =>
        theme.colors["navbar-vertical-menu-box-shadow-hover"]} !important;
    }
    &::before {
      width: 0px !important;
    };
  }

  .right.menu {
    .item {
      opacity: 1!important;

      .ui.button {
        background-color: unset;
        color: unset;
      }
    }
  }

  .active.item {
    color: ${({ theme }) =>
      theme.colors["navbar-active-item-text-color"]} !important;
    background-color: ${({ theme }) =>
      theme.colors["navbar-active-item-background-color"]} !important;
    opacity: 1 !important;
    
    &:hover {
      color: ${({ theme }) =>
        theme.colors["navbar-item-text-hover-color"]} !important;
      background-color: ${({ theme }) =>
        theme.colors["navbar-active-item-background-hover-color"]} !important;
      box-shadow: none !important;
    }
  }
}

.big-number-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.big-number-value {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
}

.big-number-timestamp {
  height: 25px;
  margin-bottom: 10px;
}

.timeline-container {
  display: flex;
  height: 20px;
  width: 100%;
  padding: 0px 10px;
}

.timeline-event {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fullpage-mode .bytebeam-panel {
  position: absolute !important;
  top: 0px  !important;
  left: 0px  !important;
  height: 100vh  !important;
  width: 100vw  !important;
  border: none !important;
  border-radius: 0px !important;
  z-index: 1000  !important;
}

.fullpage-mode,
.fullscreen-enabled .bytebeam-panel:hover {
  border: none !important;
}

.fullpage-mode .app {
  display: block;
  min-height: unset  !important;
  height: 100vh  !important;
  width: 100vw  !important;
  overflow: hidden  !important;
}

.fullpage-mode .dashboard-header,
.fullpage-mode .ui.menu:not(.pagination),
.fullpage-mode .bytebeam-dashboard .ui.divider,
.fullpage-mode .bytebeam-panel-icons.mob-view {
  display: none  !important;
}

.fullpage-mode .bytebeam-panel-icons.desktop-view {
  display: flex !important;
}

.overflowScrollX{
  overflow-x: auto;
  height: max-content
}

.fullpage-mode .app-container,
.fullpage-mode .app,
.fullpage-mode .bytebeam-dashboard {
  padding: 0px !important;
  margin: 0px !important;
  min-width: 100% !important;
}

.fullpage-mode .view-panel {
  position: static !important;
}

.bartext.bartext-outside {
  color: ${({ theme }) => theme.colors["chart-text-color"]} !important;
}

.offline-icon-position {
  position: relative;
  top: 2px;
}

@media (max-width: ${breakpoints.md}px) {
  .dashboard-header {

    .dashboard-header-row-1 { 
      flex-wrap: wrap !important;

      .header-left {
        width: 100% !important;

        .dashboard-title {
          display: inline-block !important;
          max-width: 60% !important;
        }
      }

      .header-right {
        flex-wrap: wrap !important;
        gap: 12px !important;
        margin-top: 12px !important;
      }
    }
  }
}

@media (max-width: ${breakpoints.lg}px) {
  .app {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: ${breakpoints.sm}px) {
  .ui.app-container.container {
    margin: 0 !important;
  }

  .bytebeam-panel-header {
    display: none;
  }

  .bytebeam-panel {
    &:hover, &:focus, &:active {
      .bytebeam-panel-header {
        display: flex;
      }
    }
  }
  .bold-text {
    font-weight: 600 !important;
  }

  .desktop-view {
    display: none !important;
  }
  .responsive-table {
    border: none !important;
  }
  .ui.table tr.desktop-view {
      display: none !important;
  }

  .mob-view .menu.mob-menu .item:hover {
    background: transparent !important;
  }

  .fullpage-mode .bytebeam-panel-icons.desktop-view,
  .fullpage-mode .bytebeam-panel-header {
    display: flex !important;
    opacity: 1 !important;
  }
}

@media (min-width: ${breakpoints.sm + 1}px) {
  .mob-view {
    display: none !important;
  }
}

@media (max-width: ${breakpoints.sm}px) {
  .copyright-footer {
    padding: 0px 16px !important;
  }
}
`;
