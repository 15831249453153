import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useStatusBarContext } from "../../context/StatusBar.context";
import moment from "moment";
import { Icon } from "semantic-ui-react";

const StatusBarContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showExpiredStatus",
})<{ showExpiredStatus: boolean }>`
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme, showExpiredStatus }) =>
    theme.colors[
      showExpiredStatus
        ? "status_bar-background-color-warning"
        : "status_bar-background-color"
    ]};
  color: ${({ theme, showExpiredStatus }) =>
    theme.colors[
      showExpiredStatus
        ? "status_bar-text-color-warning"
        : "status_bar-text-color"
    ]};
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 48px;
  height: 36px;
  font-size: 13px;
  z-index: 1000;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
  transition:
    background-color 0.3s,
    color 0.3s;
`;

const StatusBarSections = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StatusBarTabs = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "showWarning" && prop !== "showExpiredStatus",
})<{ showWarning: boolean; showExpiredStatus: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 100%;
  width: fit-content;
  font-weight: 600;
  &:hover {
    background: ${({ theme, showExpiredStatus }) =>
      theme.colors[
        showExpiredStatus
          ? "status_bar-background-color-warning-hover"
          : "status_bar-background-color-hover"
      ]};
    cursor: pointer;

    .notification-indicator {
      border: 2px solid
        ${({ theme, showExpiredStatus }) =>
          theme.colors[
            showExpiredStatus
              ? "status_bar-background-color-warning-hover"
              : "status_bar-background-color-hover"
          ]};
    }
  }
`;

const WarningText = styled.strong`
  color: #fff;
`;

const PopupContainer = styled.div`
  position: absolute;
  bottom: 42px;
  right: 12px;
  background-color: ${({ theme }) =>
    theme.colors["status_bar-background-color"]};
  color: ${({ theme }) => theme.colors["status_bar-text-color"]};
  border: 1px solid #555;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 420px;
  z-index: 1100;
`;

const PopupMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-bottom: 16px;
`;

const CenterAlignedIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop) => prop !== "showExpiredStatus",
})<{ showExpiredStatus: boolean; color?: string }>`
  && {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: ${({ theme, color, showExpiredStatus }) =>
      color ??
      theme.colors[
        showExpiredStatus
          ? "status_bar-text-color-warning"
          : "status_bar-text-color"
      ]};
    margin: 0px;
    padding: 0px;
  }
`;

const BellContainer = styled.div`
  position: relative;
  font-size: 15px;
`;

const Indicator = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showExpiredStatus",
})<{ showExpiredStatus: boolean }>`
  position: absolute;
  top: -2px;
  right: -1px;
  width: 10px;
  height: 10px;
  background-color: ${({ theme, showExpiredStatus }) =>
    theme.colors[
      showExpiredStatus
        ? "status_bar-text-color-warning"
        : "status_bar-text-color"
    ]};
  border-radius: 50%;
  border: 2px solid
    ${({ theme, showExpiredStatus }) =>
      theme.colors[
        showExpiredStatus
          ? "status_bar-background-color-warning"
          : "status_bar-background-color"
      ]};
`;

export const StatusBar = ({ planExpiryDate }) => {
  const { autoRefresh, devicesInfo } = useStatusBarContext();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const bellRef = useRef<HTMLDivElement>(null);

  const isDateExpired = planExpiryDate
    ? new Date(planExpiryDate) < new Date()
    : false;
  const showPlanExpiryWarning = Boolean(planExpiryDate) && !isDateExpired;
  const showWarningStatusBar = Boolean(planExpiryDate) && isDateExpired;

  const formattedDate = planExpiryDate
    ? new Date(planExpiryDate).toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : null;

  const showWarning =
    showPlanExpiryWarning ||
    autoRefresh.isRefreshPaused ||
    autoRefresh.isRefreshStale;

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current?.contains(event.target) &&
        bellRef.current &&
        !bellRef.current?.contains(event.target)
      ) {
        setIsPopupVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <StatusBarContainer showExpiredStatus={showWarningStatusBar}>
      <StatusBarSections>
        {showWarningStatusBar && (
          <WarningText>
            Your current plan is expired on {formattedDate}, please ensure all
            dues are cleared to avoid service interruption.
          </WarningText>
        )}
      </StatusBarSections>
      <StatusBarSections>
        {devicesInfo.totalCount > 0 && (
          <StatusBarTabs
            showWarning={showPlanExpiryWarning}
            showExpiredStatus={showWarningStatusBar}
          >
            <CenterAlignedIcon
              name={devicesInfo.filterStatus === "active" ? "wifi" : "bolt"}
              color={devicesInfo.filterStatus === "active" ? "green" : ""}
              style={{ marginRight: "8px" }}
              showExpiredStatus={showWarningStatusBar}
            />
            <span>Devices: {devicesInfo.totalCount}</span>
          </StatusBarTabs>
        )}
        {autoRefresh.lastRefreshedTime && (
          <StatusBarTabs
            showExpiredStatus={showWarningStatusBar}
            showWarning={showPlanExpiryWarning}
          >
            <CenterAlignedIcon
              name="refresh"
              style={{ marginRight: "8px" }}
              showExpiredStatus={showWarningStatusBar}
            />
            <span>Last refreshed:</span>{" "}
            <span>
              {moment(autoRefresh.lastRefreshedTime).format("HH:mm:ss")}
            </span>
          </StatusBarTabs>
        )}
        <StatusBarTabs
          ref={bellRef}
          showWarning={showPlanExpiryWarning}
          showExpiredStatus={showWarningStatusBar}
          onClick={togglePopup}
        >
          <BellContainer>
            <CenterAlignedIcon
              name="bell"
              showExpiredStatus={showWarningStatusBar}
            />
            {showWarning && (
              <Indicator
                className="notification-indicator"
                showExpiredStatus={showWarningStatusBar}
              />
            )}
          </BellContainer>
        </StatusBarTabs>
      </StatusBarSections>
      {isPopupVisible && (
        <PopupContainer ref={popupRef}>
          {showWarning ? (
            <div>
              {showPlanExpiryWarning && !isDateExpired && (
                <PopupMessage>
                  <CenterAlignedIcon
                    name="warning sign"
                    color="yellow"
                    showExpiredStatus={false}
                  />
                  Your current plan will expire on {formattedDate}.
                </PopupMessage>
              )}
              {autoRefresh.isRefreshPaused &&
                autoRefresh.refreshPage === "device-management" && (
                  <PopupMessage>
                    <CenterAlignedIcon
                      name="warning sign"
                      color="yellow"
                      showExpiredStatus={false}
                    />
                    Auto-refresh paused due to active device shadow filters
                  </PopupMessage>
                )}
              {autoRefresh.isRefreshStale &&
                autoRefresh.refreshPage === "dashboard" && (
                  <PopupMessage>
                    <CenterAlignedIcon
                      name="warning sign"
                      color="yellow"
                      showExpiredStatus={false}
                    />
                    The dashboard is synchronizing the latest updates. Please
                    wait while we ensure everything is up-to-date.
                  </PopupMessage>
                )}
            </div>
          ) : (
            <div>
              <PopupMessage>NO NEW NOTIFICATIONS</PopupMessage>
            </div>
          )}
        </PopupContainer>
      )}
    </StatusBarContainer>
  );
};
