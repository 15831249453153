import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, Modal } from "semantic-ui-react";
import {
  ActionStatusType,
  AppendPhaseDataType,
  Device,
  DeviceFilterOption,
  Filters,
  Key,
  PendingDeviceActionsResponse,
  SearchDeviceResponse,
  appendPhaseToAction,
  fetchAllMetadataKeys,
  fetchDeviceFilterOptions,
  getPendingActionsOnDevices,
  searchDevices,
} from "../../../../BytebeamClient";
import {
  NewActionLabelContainer,
  PhaseData,
  StyledNonBoldHeader,
  TimestampTriggerType,
} from "../NewAction/NewAction";
import {
  AddFiltersButton,
  PhaseSectionContainer,
} from "../NewAction/PhaseControlSection";
import { FlexContainer } from "../../../common/ActionsUtils";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import ActionFilterNestedDropdown from "../ActionsV3/ActionFilterNestedDropdown";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  AdvancedSectionContainer,
  AdvancedSectionItem,
} from "../NewAction/AdvancedPhasedSection";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import { ThinDivider } from "../../Dashboards/Panel/util";
import DeviceSelectionSection from "../NewAction/DeviceSelectionSection";
import LoadingAnimation from "../../../common/Loader";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import ActionDisplaySection from "../NewAction/ActionDisplaySection";
import {
  ButtonPosition,
  NestedDropdownOptions,
  convertDecimalToRoman,
  getSelectedPhaseIndex,
} from "../util";
import { debounce } from "lodash";
import { beamtoast } from "../../../common/CustomToast";
import ActionWarningModal from "../NewAction/ActionWarningModal";
import { useUser } from "../../../../context/User.context";

function getAddPhaseTriggerTime(lastPhaseTimestamp: Date): Date {
  const currentTime = new Date();
  let triggerTime: Date = lastPhaseTimestamp;
  // Check if the last phase's time has already passed
  if (
    Number.isNaN(lastPhaseTimestamp.getTime()) ||
    lastPhaseTimestamp.getTime() < currentTime.getTime()
  ) {
    triggerTime = currentTime; // If the last phase's time has passed, use the current time
  }
  return triggerTime;
}

type AddPhaseModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly action: ActionStatusType;
  readonly fetchActionData: (actionId?: number) => Promise<void>;
};

function AddPhaseModal(props: AddPhaseModalProps) {
  const { isOpen, onClose, action, fetchActionData } = props;
  const { user } = useUser();
  const viewMetadataPermission: "all" | string[] =
    user?.role?.permissions?.viewMetadata;

  const actionPhases = action?.schedule?.phases?.sort(
    (a, b) => a.trigger_on.timestamp - b.trigger_on.timestamp
  );
  const actionPhasesWithTimestamp: PhaseData[] = action?.schedule?.phases
    ?.filter((phase: PhaseData) => "timestamp" in phase.trigger_on)
    ?.sort(
      (a, b) => getSelectedPhaseIndex(a.name) - getSelectedPhaseIndex(b.name)
    );
  const lastPhaseTimestamp = new Date(
    (
      actionPhasesWithTimestamp?.at(-1)?.trigger_on as TimestampTriggerType
    )?.timestamp
  );
  const newPhaseName: string = `Phase ${convertDecimalToRoman(action?.schedule?.phases?.length + 1)}`;

  const [phaseData, setPhaseData] = useState<AppendPhaseDataType>({
    name: newPhaseName,
    trigger_on: {
      timestamp: moment(getAddPhaseTriggerTime(lastPhaseTimestamp))
        .add(15, "minutes")
        .toDate(),
    },
    info: {
      type: "fixed-list",
      device_ids: [],
    },
  });

  const [selectedDevices, setSelectedDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [page, setPage] = useState<number>(1);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);

  const [addPhaseButtonLoading, setAddPhaseButtonLoading] =
    useState<boolean>(false);

  const [devices, setDevices] = useState<SearchDeviceResponse>({
    devices: [],
    count: 0,
  });
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({});
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showInsideFilterDropdown, setShowInsideFilterDropdown] =
    useState<boolean>(false);
  const [actionNestedDropdownOptions, setActionNestedDropdownOptions] =
    useState<NestedDropdownOptions[]>([]);
  const [showActionDisplayDropdown, setShowActionDisplayDropdown] =
    useState<boolean>(false);
  const [allowedFilterBys, setAllowedFilterBys] = useState<Key[]>([]);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [metadataKeysToShow, setMetadataKeysToShow] = useState<string[]>([]);

  const [depth, setDepth] = useState(1);
  const [currentDepth, setCurrentDepth] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});
  const [filtersExist, setFiltersExist] = useState<boolean>(false);

  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();

  const [pendingActionsOnDevices, setPendingActionsOnDevices] =
    useState<PendingDeviceActionsResponse>({});
  const [pendingActionsOnDevicesLoading, setPendingActionsOnDevicesLoading] =
    useState<boolean>(false);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);

  const resetFilters = () => {
    setDevicesLoading(true);
    setFilterLoading(true);
    setPage(1);
    setAllowedFilterBys([]);
    setFilters({});
    setSelectedDevices({ devices: [], count: 0 });
    setAllSelected(false);
    setSelectedFilters({});
    setCurrentDepth(0);
    setShowFilterDropdown(false);
    setShowInsideFilterDropdown(false);
  };

  const handleModalClose = () => {
    setPhaseData({
      name: newPhaseName,
      trigger_on: {
        timestamp: moment(getAddPhaseTriggerTime(lastPhaseTimestamp))
          .add(15, "minutes")
          .toDate(),
      },
      info: {
        type: "fixed-list",
        device_ids: [],
      },
    });
    setPageLimit(5);
    setMetadataKeysToShow([]);

    resetFilters();

    onClose();
  };

  const selectedDeviceInPrevPhases = (
    computedPhaseData: AppendPhaseDataType
  ) => {
    const allDeviceIds =
      action?.schedule?.phases.map((phase) => {
        const filterValue = phase.info?.filter?.value;
        const parsedFilterValue = JSON.parse(filterValue);

        return parsedFilterValue?.id ?? [];
      }) ?? [];

    // get list all the devices which are already present in the previous phases
    const allDevices = allDeviceIds.flat();
    const selectedDevices = computedPhaseData.info.device_ids ?? [];
    const repeatedDevices = selectedDevices.filter((device) =>
      allDevices.includes(device)
    );

    if (repeatedDevices?.length > 0)
      beamtoast.error(
        `Device Id ${repeatedDevices.join(", ")} is already present in the previous phases!`
      );
    return repeatedDevices?.length > 0;
  };

  const handleSubmit = async (
    checkPendingActions: boolean = true
  ): Promise<void> => {
    const finalPhaseData: AppendPhaseDataType = {
      ...phaseData,
      trigger_on: {
        timestamp: new Date(phaseData.trigger_on.timestamp).getTime(),
      },
    };

    if (allSelected) {
      const allDevices = (
        filters?.["id"]?.length > 0
          ? filters["id"]
          : filterOptions?.find((option) => option?.filterName === "id")
              ?.filterValues ?? []
      ).map((id) => String(id));

      finalPhaseData.info.device_ids = allDevices;
    }

    // for Timestamp validation
    const timestamp = new Date(phaseData.trigger_on.timestamp);

    if (finalPhaseData.info.device_ids?.length === 0 && !allSelected) {
      beamtoast.error("Please select at least one device!");
      return;
    } else if (isNaN(timestamp.getTime())) {
      beamtoast.error("Invalid timestamp");
      return;
    } else if (selectedDeviceInPrevPhases(finalPhaseData)) {
      return;
    } else {
      let areTherePendingActionsOnDevices = false;
      if (checkPendingActions) {
        areTherePendingActionsOnDevices = await areTherePendingActions();
      }

      if (areTherePendingActionsOnDevices && checkPendingActions) {
        setIsWarningModalOpen(true); // Warning modal will be opened if there are pending actions on devices.
      } else {
        setAddPhaseButtonLoading(true);
        try {
          // just adding this translation function to avoid changing data format in other places for now
          await appendPhaseToAction(action.action_id, {
            name: finalPhaseData.name,
            filter: { id: finalPhaseData.info.device_ids },
            fraction: 100,
            schedule_timestamp: finalPhaseData.trigger_on.timestamp as number,
          });
          await fetchActionData(action.action_id);

          beamtoast.success("Phase added successfully!");

          // If successful, close the modal
          handleModalClose();
        } catch (error: any) {
          console.error("Failed to submit phase data:", error);
          if (error["name"] === "bad_request") {
            beamtoast.error(error["message"]);
          } else {
            beamtoast.error("Failed to add phase!");
          }
        } finally {
          setAddPhaseButtonLoading(false);
        }
      }
    }
  };

  let abortController = new AbortController();
  const refreshDevices = async (pageNumber: number, myFilters: Filters) => {
    setDevicesLoading(true);

    abortController.abort();
    abortController = new AbortController();

    // setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    setTimeout(async () => {
      const status = "active";
      await searchDevices(
        { metaDatafilters: myFilters },
        pageNumber,
        pageLimit,
        status,
        abortController.signal
      ).then((devices) => {
        setDevices(devices);
        setDevicesLoading(false);
      });
    });
  };

  /**
   * Updates the filter options based on the provided device filters and optional keys.
   * This function fetches the latest filter options, maps them according to the provided keys (if any),
   * and updates the state to reflect the new filter options.
   *
   * @param {DeviceFilters} filters - The current set of filters applied to devices.
   * @param {Key[]} [keys] - Optional. A list of keys to filter the filter options by. If not provided, all filter options are used.
   * @returns {Promise<void>} A promise that resolves when the filter options have been refreshed and the state has been updated.
   **/
  async function refreshFilterOptions(
    filters: Filters,
    keys?: Key[]
  ): Promise<void> {
    setFilterLoading(true);

    try {
      const allFilterOptions = await fetchDeviceFilterOptions(filters);
      const filterOptionsMap: { [key: string]: any[] } = {};

      allFilterOptions.forEach(({ filterName, filterValues }) => {
        filterOptionsMap[filterName] = filterValues;
      });

      if (keys && keys?.length > 0) {
        const mappedFilterOptions = keys.map(({ key }) => ({
          filterName: key,
          filterValues: filterOptionsMap[key] || [], // Fallback to an empty array if not found
        }));

        setFilterOptions(mappedFilterOptions);
      } else {
        // If no specific keys are provided, use all filter options as is
        setFilterOptions(allFilterOptions);
      }
    } catch (error) {
      console.error("Failed to refresh filter options:", error);
    } finally {
      setFilterLoading(false);
    }
  }

  const updateFilters = async (myFilters: Filters, allowedFilterBy: Key[]) => {
    setPage(1);
    setDevicesLoading(true);
    setSelectedDevices({ devices: [], count: 0 });

    await refreshFilterOptions(myFilters, allowedFilterBy);
    await refreshDevices(1, myFilters);

    // select all devices when filters are applied
    await handleAllDevicesSelect(Object.keys(myFilters)?.length !== 0);
  };

  const debouncedUpdateFilters = useCallback(debounce(updateFilters, 1000), []);

  const onFilterChange = useCallback(
    (name: any, value: any) => {
      setDevicesLoading(true);

      const filterName = name;
      const filterValues = value as string[];

      const currentlySelectedFilters = { ...selectedFilters };
      if (filterValues?.length > 0) {
        currentlySelectedFilters[filterName] = filterValues;
      } else {
        delete currentlySelectedFilters[filterName];
      }

      if (Object.keys(currentlySelectedFilters)?.length === 0)
        setFiltersExist(false);
      else setFiltersExist(true);

      setSelectedFilters(currentlySelectedFilters);
      setFilters(currentlySelectedFilters);
      debouncedUpdateFilters(currentlySelectedFilters, allowedFilterBys);
    },
    [
      selectedFilters,
      allowedFilterBys,
      setDevicesLoading,
      setSelectedFilters,
      debouncedUpdateFilters,
    ]
  );

  const handleAllDevicesSelect = async (selectAll: boolean) => {
    setAllSelected(selectAll);
    const allDevices = (
      filters?.["id"]?.length > 0
        ? filters["id"]
        : filterOptions?.find((option) => option?.filterName === "id")
            ?.filterValues ?? []
    ).map((id) => String(id));

    if (selectAll) {
      setSelectedDevices({
        devices: allDevices.map((id) => ({
          id: parseInt(id),
        })) as Device[],
        count: allDevices?.length,
      });
      // Here phaseData state changes unexpectedly, so we are using the previous state
      setPhaseData((prevPhaseData) => ({
        ...prevPhaseData,
        info: {
          type: "fixed-list",
          device_ids: allDevices,
        },
      }));
    }
  };

  const selectDevice = (device: Device) => {
    let updatedDevices: SearchDeviceResponse = {
      devices: [...selectedDevices.devices, device],
      count: selectedDevices.count + 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the selected devices ids
    setPhaseData({
      ...phaseData,
      info: {
        type: "fixed-list",
        device_ids: updatedDevices.devices.map((device) => String(device.id)),
      },
    });
  };

  const clearDevice = (device: Device) => {
    const filteredDevices = selectedDevices.devices.filter(
      (selectedDevice) => selectedDevice.id !== device.id
    );

    const updatedDevices: SearchDeviceResponse = {
      devices: filteredDevices,
      count: selectedDevices.count - 1,
    };
    setSelectedDevices(updatedDevices);

    // Update the phase info with the removed devices ids
    setPhaseData({
      ...phaseData,
      info: {
        type: "fixed-list",
        device_ids: updatedDevices.devices.map((device) => String(device.id)),
      },
    });
  };

  const onPageChange = useCallback(
    (e, { activePage }) => {
      setDevicesLoading(true);
      setPage(activePage as number);
      refreshDevices(activePage, filters);
    },
    [filters] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function getObjectDepth(obj: any): number {
    let depth = 0;

    function calculateDepth(object: any, currentDepth: number): void {
      if (typeof object === "object" && object !== null) {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            calculateDepth(object[key], currentDepth + 1);
          }
        }
      } else {
        depth = Math.max(depth, currentDepth);
      }
    }

    calculateDepth(obj, 0);

    return depth;
  }

  const fetchMetadataKeys = useCallback(async () => {
    setDevicesLoading(true);
    await fetchAllMetadataKeys().then((keys) => {
      const idKey = { key: "id" };
      const filteredKeys = keys.filter(
        (metadata) =>
          viewMetadataPermission === "all" ||
          viewMetadataPermission.includes(metadata.key)
      );
      const allKeys = [idKey, ...filteredKeys];

      setAllowedFilterBys(allKeys);
      refreshFilterOptions(filters, allKeys);
    });
  }, [filters, viewMetadataPermission]);

  const getButtonPosition = (id: string) => {
    const buttonElement = document.getElementById(id);
    const modalElement = document.getElementById("add-phase-modal");
    if (buttonElement && modalElement) {
      const buttonPosition = buttonElement.getBoundingClientRect();
      const modalPosition = modalElement.getBoundingClientRect();
      const relativePosition = {
        top: buttonPosition.top - modalPosition.top,
        left: buttonPosition.left - modalPosition.left,
        width: buttonPosition.width,
        height: buttonPosition.height,
      };
      setAddFiltersButtonPosition(relativePosition);
    }
  };

  /**
   * Fetches the pending actions on devices for the selected devices.
   * @returns {Promise<boolean>} - A promise that resolves to true if there are pending actions on devices, else false.
   */
  async function areTherePendingActions(
    computedPhaseData?: AppendPhaseDataType
  ): Promise<boolean> {
    let pendingActionData: PendingDeviceActionsResponse = {};
    setPendingActionsOnDevicesLoading(true);

    async function getPendingActionInfo(filter: Filters) {
      const response = await getPendingActionsOnDevices(filter);
      pendingActionData = { ...pendingActionData, ...response };
    }

    try {
      await getPendingActionInfo({
        id: (computedPhaseData ?? phaseData).info.device_ids?.map(String) ?? [],
      });

      return (
        Object.values(pendingActionData)
          .flat()
          .filter(
            (action) =>
              action.type !== "cancel_action" && action.type !== "launch_shell"
          )?.length > 0
      );
    } catch (error) {
      console.log("Error in fetching pending actions", error);
      return false;
    } finally {
      setPendingActionsOnDevicesLoading(false);
      setPendingActionsOnDevices(pendingActionData);
    }
  }

  useEffect(() => {
    let array: NestedDropdownOptions[] = [];
    filterOptions.forEach((option) => {
      const children = option.filterValues.flatMap(
        (filterValue: string | number) => {
          const value = filterValue?.toString()?.trim();
          return value ? [{ text: value, value }] : [];
        }
      );

      array.push({
        label: option.filterName,
        children,
      });
    });

    setActionNestedDropdownOptions(array);

    if (array?.length !== 0) setDepth(getObjectDepth(array) - 2);
  }, [filterOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const initialFetch = () => {
      fetchMetadataKeys();
      refreshDevices(1, {});
    };

    if (
      Object.keys(filters)?.length === 0 &&
      (!allowedFilterBys || allowedFilterBys?.length === 0) &&
      isOpen
    ) {
      initialFetch();
    }
  }, [filters, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen) {
      setPhaseData({
        name: newPhaseName,
        trigger_on: {
          // trigger time is either 15mins ahead of the last phase's time or the current time
          timestamp: moment(getAddPhaseTriggerTime(lastPhaseTimestamp))
            .add(15, "minutes")
            .toDate(),
        },
        info: {
          type: "fixed-list",
          device_ids: [],
        },
      });
    }
  }, [isOpen, action.action_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="large"
      className="dark"
      id={"add-phase-modal"}
    >
      <Modal.Header>Add Phase to action #{action?.action_id}</Modal.Header>
      <Modal.Content>
        <PhaseSectionContainer
          id={"phaseSection-container"}
          style={{ minHeight: "550px" }}
        >
          {filterOptions && filterOptions?.length > 0 && (
            <>
              <NewActionLabelContainer style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      minHeight: "35px",
                    }}
                  >
                    <div
                      className="dashboard-header"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <FlexContainer>
                        <div>
                          <ActionButtonV3
                            onClick={() => {
                              getButtonPosition(
                                "create-action-metadata-filters-button"
                              );
                              setShowFilterDropdown(false);
                              setShowInsideFilterDropdown(true);
                            }}
                            border_style={"dashed"}
                            label={"Filters"}
                            icon={"filter"}
                            margin_left="0px"
                            id={"create-action-metadata-filters-button"}
                          />
                          <div style={{ position: "relative" }}>
                            {showInsideFilterDropdown && (
                              <ActionFilterNestedDropdown
                                setShowDropdown={(value) =>
                                  setShowInsideFilterDropdown(value)
                                }
                                depth={depth}
                                onClick={(filterOption, filterValue) => {
                                  onFilterChange(filterOption, filterValue);
                                }}
                                nestedDropdownOptions={
                                  actionNestedDropdownOptions
                                }
                                filters={filters}
                                currentDepth={currentDepth}
                                setCurrentDepth={(value) =>
                                  setCurrentDepth(value)
                                }
                                search
                              />
                            )}
                          </div>
                        </div>
                      </FlexContainer>
                    </div>
                  </div>
                  {filtersExist &&
                    Object.keys(selectedFilters)?.length !== 0 && (
                      <div
                        style={{
                          alignSelf: "center",
                        }}
                      >
                        <StyledNonBoldHeader
                          as="h3"
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "1.1rem",
                            whiteSpace: "nowrap",
                          }}
                          className={`${
                            Object.keys(selectedFilters)?.length === 0
                              ? "color-disabled"
                              : "selectable-item underline hover-underline color-blue"
                          }`}
                          onClick={() => {
                            resetFilters();
                          }}
                        >
                          Clear All
                        </StyledNonBoldHeader>
                      </div>
                    )}
                </div>
              </NewActionLabelContainer>

              {filtersExist && Object.keys(selectedFilters)?.length !== 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      width: "100%",
                    }}
                  >
                    {Object.keys(selectedFilters).map((key) => (
                      <SelectedFilter
                        key={key}
                        filterName={key}
                        filterValues={filters[key] as string[]}
                        filterNameIcon={"file"}
                        filterValueIcon={"info circle"}
                        onCrossClick={(filterOption, filterValue) => {
                          onFilterChange(filterOption, filterValue);
                        }}
                        multiple
                      />
                    ))}

                    <AddFiltersButton
                      name="plus"
                      id={"phase-modal-add-filters-button"}
                      onClick={() => {
                        setShowFilterDropdown(true);
                        getButtonPosition("phase-modal-add-filters-button");
                      }}
                    />
                    <div style={{ position: "relative" }}>
                      {showFilterDropdown && (
                        <ActionFilterNestedDropdown
                          setShowDropdown={(value) =>
                            setShowFilterDropdown(value)
                          }
                          depth={depth}
                          onClick={(filterOption, filterValue) => {
                            onFilterChange(filterOption, filterValue);
                          }}
                          nestedDropdownOptions={actionNestedDropdownOptions}
                          filters={filters}
                          currentDepth={currentDepth}
                          setCurrentDepth={(value) => setCurrentDepth(value)}
                          parentButtonPosition={addFiltersButtonPosition}
                          search
                        />
                      )}
                    </div>
                    <Divider />
                  </div>
                  <ThinDivider />
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {!filterLoading &&
            !devicesLoading &&
            devices &&
            devices.devices?.length !== 0 && (
              <ActionDisplaySection
                showActionDisplayDropdown={showActionDisplayDropdown}
                setShowActionDisplayDropdown={setShowActionDisplayDropdown}
                setMetadataKeysToShow={setMetadataKeysToShow}
                metadataKeysToShow={metadataKeysToShow}
                allowedMetadataKeys={allowedFilterBys}
              />
            )}
          {(filterLoading || devicesLoading) && (
            <LoadingAnimation
              loaderContainerHeight={filtersExist ? "475px" : "600px"}
              loadingText="Loading devices"
              fontSize="20px"
              loaderSize="48px"
            />
          )}
          {!filterLoading &&
            !devicesLoading &&
            devices?.devices?.length !== 0 && (
              <>
                <ActionWarningModal
                  isWarningModalOpen={isWarningModalOpen}
                  setIsWarningModalOpen={setIsWarningModalOpen}
                  pendingActionsOnDevices={pendingActionsOnDevices}
                  triggerAction={handleSubmit}
                />
                <DeviceSelectionSection
                  devices={devices}
                  allSelected={allSelected}
                  setAllSelected={handleAllDevicesSelect}
                  selectedDevices={selectedDevices}
                  setSelectedDevices={setSelectedDevices}
                  selectDevice={selectDevice}
                  clearDevice={clearDevice}
                  page={page}
                  pageLimit={pageLimit}
                  onPageChange={onPageChange}
                  metadataKeysToShow={metadataKeysToShow}
                  phase={`Phase ${convertDecimalToRoman(actionPhases?.length + 1)}`}
                />

                <ThinDivider />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "32px 0px 30px 0px",
                  }}
                >
                  <AdvancedSectionContainer>
                    <AdvancedSectionItem>
                      <StyledNonBoldHeader
                        as="h3"
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          fontSize: "1.1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        When to trigger
                      </StyledNonBoldHeader>
                    </AdvancedSectionItem>
                    <AdvancedSectionItem>
                      <DateTimeInput
                        minDate={moment(
                          getAddPhaseTriggerTime(lastPhaseTimestamp)
                        )
                          .add(15, "minutes")
                          .format("YYYY-MM-DD HH:mm:ss")}
                        placeholder="Date Time"
                        popupPosition="bottom right"
                        name="dateTime"
                        closable
                        hideMobileKeyboard
                        value={
                          phaseData.trigger_on.timestamp
                            ? phaseData.trigger_on.timestamp.toLocaleString(
                                "en-GB"
                              )
                            : ""
                        }
                        iconPosition="left"
                        dateTimeFormat={moment.defaultFormat}
                        preserveViewMode={false}
                        autoComplete="off"
                        onChange={(e, data) => {
                          setPhaseData({
                            ...phaseData,
                            trigger_on: {
                              timestamp: new Date(data.value),
                            },
                          });
                        }}
                        closeOnMouseLeave={false}
                      />
                    </AdvancedSectionItem>
                  </AdvancedSectionContainer>
                </div>
              </>
            )}
          {!filterLoading &&
            !devicesLoading &&
            devices?.devices?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorMessage
                  marginTop="30px"
                  marginBottom="30px"
                  message={"No devices found!"}
                />
              </div>
            )}
        </PhaseSectionContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel_button"
          secondary
          onClick={() => {
            handleModalClose();
          }}
        >
          Discard
        </Button>
        <Button
          style={{
            cursor: `${addPhaseButtonLoading ? "not-allowed" : "pointer"}`,
          }}
          id="submit_button"
          primary
          disabled={addPhaseButtonLoading}
          loading={addPhaseButtonLoading || pendingActionsOnDevicesLoading}
          onClick={async () => {
            await handleSubmit();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddPhaseModal;
