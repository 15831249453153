import React from "react";
import styled from "styled-components";

const StyledSVG = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "height" && prop !== "altColor",
})<{ height: string; altColor?: string }>`
  svg {
    path {
      fill: ${(props) =>
        props.altColor ?? props.theme.colors["bytebeam_logo-color"]};
      transition: 0.15s ease all;
    }
    width: auto;
    height: ${(props) => props.height} !important;
  }
`;

type StyledSVGIconProps = {
  readonly svgContent: string;
  readonly height: string;
  readonly altColor?: string;
};

function StyledSVGIcon(props: StyledSVGIconProps) {
  const { height, altColor, svgContent, ...rest } = props;

  return (
    <StyledSVG
      height={height}
      {...rest}
      altColor={altColor}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
}

export default StyledSVGIcon;
