import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type ActionButtonProps = {
  onClick: () => void;
  label: string;
  icon?: any;
  selected?: boolean;
  border_color?: string;
  border_radius?: string;
  margin_left?: string;
  margin_bottom?: string;
  padding?: string;
  border_style?: string;
  style?: Record<string, string>;
  id?: string;
  disabled?: boolean;
  title?: string;
  button_height?: string;
};

const ActionButtonWrapper = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "selected" &&
    prop !== "border_radius" &&
    prop !== "margin_left" &&
    prop !== "margin_bottom" &&
    prop !== "padding" &&
    prop !== "border_color" &&
    prop !== "border_style" &&
    prop !== "disabled" &&
    prop !== "button_height",
})<{
  selected: boolean;
  border_radius: string;
  margin_left: string;
  margin_bottom: string;
  padding: string;
  border_color?: string;
  border_style?: string;
  disabled?: boolean;
  button_height?: string;
}>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 200ms;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors["add-button-border-color"]
      : "transparent"};
  border: 1px solid
    ${(props) =>
      props.border_color
        ? props.border_color
        : props.theme.colors["add-button-border-color"]};
  border-style: ${(props) => props.border_style};
  border-radius: ${(props) => props.border_radius};
  padding: ${(props) => props.padding};
  color: ${(props) =>
    props.selected ? props.theme.colors["body"] : props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.margin_left};
  margin-bottom: ${(props) => props.margin_bottom};
  width: fit-content !important;
  height: ${(props) => props.button_height ?? "35px"};
  white-space: nowrap;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const ActionButtonV3 = React.forwardRef<HTMLButtonElement, ActionButtonProps>(
  (props, ref) => {
    return (
      <ActionButtonWrapper
        title={props.title}
        ref={ref}
        onClick={() => props.onClick()}
        selected={props.selected ? props.selected : false}
        border_color={props.border_color}
        border_radius={props.border_radius ? props.border_radius : "17px"}
        margin_left={props.margin_left ? props.margin_left : "20px"}
        margin_bottom={props.margin_bottom ? props.margin_bottom : "0px"}
        padding={props.padding ? props.padding : "0.5rem 1.5rem"}
        border_style={props.border_style ? props.border_style : "solid"}
        style={props.style}
        id={props.id}
        disabled={props.disabled ?? false}
        button_height={props.button_height}
      >
        {props.icon &&
          (typeof props.icon !== "object" ? (
            <Icon
              name={props.icon}
              style={{
                position: "relative",
                bottom: "1px",
                marginRight: "10px",
              }}
            />
          ) : (
            <div
              style={{
                marginRight: "10px",
                marginTop: "5px",
              }}
            >
              {props.icon}
            </div>
          ))}
        {props.label}
      </ActionButtonWrapper>
    );
  }
);

export default ActionButtonV3;
