import React, { useMemo } from "react";
import { LineChartMetaData, TimeseriesAxisMap } from "./PanelDef";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { TimeseriesSelector } from "./TimeseriesSelector";
import { RelativeTimeRange } from "../../Datetime/TimeRange";
import { Tab, Input, Label, Table, Accordion, Icon } from "semantic-ui-react";
import isEqual from "lodash/isEqual";
import * as uuid from "uuid";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  LabelHeading,
  DisplayValueDiv,
  ToggleLabel,
  ThinDivider,
  EditAnimatedMetaInput,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

type EditLineChartMetaProps = {
  readonly tables: TableInfo;
  readonly titleRef: React.RefObject<HTMLInputElement>;
  readonly descriptionRef: React.RefObject<HTMLInputElement>;
  readonly timeseriesRefs: {
    [key: number]: React.RefObject<TimeseriesSelector>;
  };
  readonly minRangeRef: React.RefObject<HTMLInputElement>;
  readonly maxRangeRef: React.RefObject<HTMLInputElement>;
  readonly upperThresholdRef: React.RefObject<HTMLInputElement>;
  readonly lowerThresholdRef: React.RefObject<HTMLInputElement>;
  readonly xAxisLabelRef: React.RefObject<HTMLInputElement>;
  readonly yAxisLabelRef: React.RefObject<HTMLInputElement>;
  readonly xAxisUnitRef: React.RefObject<HTMLInputElement>;
  readonly yAxisUnitRef: React.RefObject<HTMLInputElement>;
  readonly groupByRef: React.RefObject<HTMLInputElement>;
  readonly panelMeta: LineChartMetaData;
  readonly statePanelMeta: LineChartMetaData;
  readonly rowKeys: number[];
  readonly activeIndex: number;
  readonly dashboardType?: string;
  readonly currentAlternateAxisMap: TimeseriesAxisMap[];
  readonly addRow: () => void;
  readonly removeRow: (i: number) => void;
  readonly onToggleAdvancedSettingsOpen: () => void;
  readonly handleAccordionClick: (e, titleProps) => void;
  readonly onToggleAreaChart: (checked: boolean) => void;
  readonly onToggleNullValues: (checked: boolean) => void;
  readonly onToggleShowMarkers: (checked: boolean) => void;
  readonly handleSliderChange: (event) => void;
  readonly handleMarkerSliderChange: (event) => void;
  readonly onToggleXAxis: (checked: boolean) => void;
  readonly onToggleYAxis: (checked: boolean) => void;
  readonly onToggleAutoMarginEnable: (checked: boolean) => void;
  readonly handleMarginSliderChange: (event) => void;
  readonly errorObject: {
    [key: string]: {
      errorMsg: string;
    };
  };
};

function EditLineChartTabs({
  tables,
  titleRef,
  descriptionRef,
  timeseriesRefs,
  minRangeRef,
  maxRangeRef,
  upperThresholdRef,
  lowerThresholdRef,
  xAxisLabelRef,
  yAxisLabelRef,
  xAxisUnitRef,
  yAxisUnitRef,
  groupByRef,
  rowKeys,
  activeIndex,
  panelMeta,
  statePanelMeta,
  dashboardType,
  currentAlternateAxisMap,
  errorObject,
  onToggleAreaChart,
  onToggleNullValues,
  onToggleShowMarkers,
  handleSliderChange,
  handleMarkerSliderChange,
  onToggleXAxis,
  onToggleYAxis,
  onToggleAutoMarginEnable,
  handleMarginSliderChange,
  addRow,
  removeRow,
  onToggleAdvancedSettingsOpen,
  handleAccordionClick,
}: EditLineChartMetaProps) {
  const title = panelMeta ? panelMeta.title : "";
  const description = panelMeta ? panelMeta.description : "";
  const minRange = panelMeta ? panelMeta.minRange : undefined;
  const maxRange = panelMeta ? panelMeta.maxRange : undefined;
  const currentUpperThreshold = panelMeta ? panelMeta.threshold : undefined;
  const currentLowerThreshold = panelMeta
    ? panelMeta.lowerThreshold
    : undefined;
  const currentXAxisLabel = panelMeta ? panelMeta.xAxisLabel : "";
  const currentYAxisLabel = panelMeta ? panelMeta.yAxisLabel : "";
  const currentXAxisUnit = panelMeta ? panelMeta.xAxisUnit : "";
  const currentYAxisUnit = panelMeta ? panelMeta.yAxisUnit : "";

  // Cannot make Tab panes in separate child components because of a bug in semantic-react-ui "Tabs"
  //https://github.com/Semantic-Org/Semantic-UI-React/issues/2168
  const panes = useMemo(
    () => [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <Table>
                <Table.Body>
                  {rowKeys.map((rowKey, i) => {
                    return (
                      <React.Fragment key={rowKey}>
                        {i !== 0 ? <ThinDivider /> : <></>}
                        <TimeseriesSelector
                          key={rowKey}
                          ref={timeseriesRefs[rowKey]}
                          defaultValue={currentAlternateAxisMap[i]}
                          showRemoveIcon={rowKeys.length !== 1 || i > 0}
                          showAddIcon={
                            i === rowKeys.length - 1 &&
                            dashboardType === "device"
                          }
                          onRemoveRow={() => removeRow(rowKey)}
                          onAddRow={addRow}
                          tables={tables}
                          elementid={i.toString()}
                          showAlternateAxisToggle={
                            dashboardType === "device" && rowKeys.length !== 1
                          }
                          dashboardType={dashboardType ?? ""}
                          setIsAdvancedSettingsOpen={
                            onToggleAdvancedSettingsOpen
                          }
                        />
                      </React.Fragment>
                    );
                  })}
                </Table.Body>
              </Table>

              {/* Disabling the GroupBy until we fix it */}
              {false ? (
                <EditMetaRow>
                  <Input className="add-panel-title-input" labelPosition="left">
                    <Label>Group By</Label>
                    <input
                      ref={groupByRef}
                      defaultValue={""}
                      placeholder="Group data by"
                    />
                  </Input>
                </EditMetaRow>
              ) : (
                ""
              )}
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Graph Settings </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "30px" }}
                >
                  <EditMetaRow>
                    <ToggleLabel>Area Chart</ToggleLabel>
                    <ToggleSwitch
                      id="showAreaChartToggle"
                      defaultChecked={statePanelMeta.showAreaChart}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleAreaChart}
                    />
                  </EditMetaRow>
                  <EditMetaRow>
                    <ToggleLabel>Connect Null Values</ToggleLabel>
                    <ToggleSwitch
                      id="connectNullToggle"
                      defaultChecked={statePanelMeta.connectNullValues}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleNullValues}
                    />
                  </EditMetaRow>
                  <EditMetaRow>
                    <ToggleLabel>Show Points (Markers)</ToggleLabel>
                    <ToggleSwitch
                      id="showPointerToggle"
                      defaultChecked={statePanelMeta.showMarkers}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleShowMarkers}
                    />
                  </EditMetaRow>
                  <div className="AxisRangeDiv">
                    <ToggleLabel
                      style={{
                        justifyContent: "space-between",
                        paddingTop: "5px",
                      }}
                    >
                      Custom Y-Axis Range
                    </ToggleLabel>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        id="minRange"
                        defaultRef={minRangeRef}
                        defaultValue={minRange}
                        label="Min*"
                        type={"number"}
                      />
                    </div>
                    <div
                      style={{ width: "2%", justifyContent: "space-between" }}
                    ></div>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        id="maxRange"
                        defaultRef={maxRangeRef}
                        defaultValue={maxRange}
                        label="Max*"
                        type={"number"}
                      />
                    </div>
                  </div>
                  {errorObject?.range?.errorMsg && (
                    <div style={{ color: "red", marginTop: "10px" }}>
                      {errorObject?.range?.errorMsg}
                    </div>
                  )}
                  <ThinDivider />
                  <EditMetaRow>
                    <LabelHeading>Line Width</LabelHeading>
                    <DisplayValueDiv>
                      {statePanelMeta.lineWidth ? statePanelMeta.lineWidth : 2}
                    </DisplayValueDiv>
                    <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                      <input
                        style={{ width: "100%", height: "5%" }}
                        id="typeinp"
                        type="range"
                        min="0"
                        max="10"
                        value={
                          statePanelMeta.lineWidth
                            ? statePanelMeta.lineWidth
                            : 2
                        }
                        onChange={handleSliderChange}
                        step="0.1"
                      />
                    </div>
                  </EditMetaRow>
                  <EditMetaRow>
                    <LabelHeading>Marker Radius</LabelHeading>
                    <DisplayValueDiv>
                      {statePanelMeta.markerRadius
                        ? statePanelMeta.markerRadius
                        : 2}
                    </DisplayValueDiv>
                    <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                      <input
                        style={{ width: "100%", height: "5%" }}
                        id="typeinp"
                        type="range"
                        min="1"
                        max="20"
                        value={
                          statePanelMeta.markerRadius
                            ? statePanelMeta.markerRadius
                            : 6
                        }
                        onChange={handleMarkerSliderChange}
                        step="0.5"
                      />
                    </div>
                  </EditMetaRow>
                  <ThinDivider />
                  <EditMetaRow>
                    <LabelHeading>Thresholds</LabelHeading>
                    <EditAnimatedMetaInput
                      defaultRef={lowerThresholdRef}
                      defaultValue={currentLowerThreshold}
                      label="Lower Threshold"
                      type={"number"}
                    />
                    <EditAnimatedMetaInput
                      defaultRef={upperThresholdRef}
                      defaultValue={currentUpperThreshold}
                      label="Upper Threshold"
                      type={"number"}
                    />
                    {errorObject?.threshold?.errorMsg && (
                      <div style={{ color: "red" }}>
                        {errorObject?.threshold?.errorMsg}
                      </div>
                    )}
                  </EditMetaRow>
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Axis Settings </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 1}
                  style={{ paddingLeft: "30px" }}
                >
                  <LabelHeading>Grid Options</LabelHeading>
                  <EditMetaRow>
                    <ToggleLabel>Show X-Axis Grids</ToggleLabel>
                    <ToggleSwitch
                      id="showXAxisToggle"
                      defaultChecked={statePanelMeta.xAxisGrids}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleXAxis}
                    />
                  </EditMetaRow>
                  <EditMetaRow>
                    <ToggleLabel>Show Y-Axis Grids</ToggleLabel>
                    <ToggleSwitch
                      id="showYAxisToggle"
                      defaultChecked={statePanelMeta.yAxisGrids}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleYAxis}
                    />
                  </EditMetaRow>
                  <ThinDivider />
                  <div className="AxisRangeDiv">
                    <ToggleLabel
                      style={{
                        justifyContent: "space-between",
                        paddingTop: "5px",
                      }}
                    >
                      Label Options{" "}
                    </ToggleLabel>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        defaultRef={xAxisLabelRef}
                        defaultValue={currentXAxisLabel}
                        label="X-Axis"
                        type={"text"}
                      />
                    </div>
                    <div
                      style={{ width: "2%", justifyContent: "space-between" }}
                    ></div>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        defaultRef={yAxisLabelRef}
                        defaultValue={currentYAxisLabel}
                        label="Y-Axis"
                        type={"text"}
                      />
                    </div>
                  </div>
                  <ThinDivider />
                  <div className="AxisRangeDiv">
                    <ToggleLabel
                      style={{
                        justifyContent: "space-between",
                        paddingTop: "5px",
                      }}
                    >
                      Units Options{" "}
                    </ToggleLabel>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        defaultRef={xAxisUnitRef}
                        defaultValue={currentXAxisUnit}
                        label="X-Axis"
                        type={"text"}
                      />
                    </div>
                    <div
                      style={{ width: "2%", justifyContent: "space-between" }}
                    ></div>
                    <div className="AxisRangeInputs">
                      <EditAnimatedMetaInput
                        defaultRef={yAxisUnitRef}
                        defaultValue={currentYAxisUnit}
                        label="Y-Axis"
                        type={"text"}
                      />
                    </div>
                  </div>
                  <ThinDivider />
                  <EditMetaRow>
                    <p>
                      * Disable auto-margin below, to handle graph shrinking
                    </p>
                    <ToggleLabel style={{ fontWeight: "bold" }}>
                      Auto margin for Y-Axis
                    </ToggleLabel>
                    <ToggleSwitch
                      id="showRangeZeroToggle"
                      defaultChecked={statePanelMeta.autoMarginEnable}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={onToggleAutoMarginEnable}
                    />
                  </EditMetaRow>
                  <EditMetaRow
                    style={{
                      display:
                        statePanelMeta.autoMarginEnable === false
                          ? "flex"
                          : "none",
                      marginLeft: "10px",
                    }}
                  >
                    <LabelHeading style={{ fontWeight: "normal" }}>
                      Left Margin (Y-axis)
                    </LabelHeading>
                    <DisplayValueDiv>
                      {statePanelMeta.leftMarginYaxis ?? 25}
                    </DisplayValueDiv>
                    <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                      <input
                        style={{ width: "100%", height: "5%" }}
                        id="typeinp3"
                        type="range"
                        min="25"
                        max="200"
                        value={statePanelMeta.leftMarginYaxis ?? 25}
                        onChange={handleMarginSliderChange}
                        step="1"
                      />
                    </div>
                  </EditMetaRow>
                </Accordion.Content>
              </Accordion>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tables,
      rowKeys,
      titleRef,
      descriptionRef,
      timeseriesRefs,
      minRangeRef,
      maxRangeRef,
      upperThresholdRef,
      lowerThresholdRef,
      xAxisLabelRef,
      yAxisLabelRef,
      xAxisUnitRef,
      yAxisUnitRef,
      groupByRef,
      statePanelMeta,
      dashboardType,
      currentAlternateAxisMap,
      errorObject,
      activeIndex,
      currentLowerThreshold,
      currentUpperThreshold,
      currentXAxisLabel,
      currentXAxisUnit,
      currentYAxisLabel,
      currentYAxisUnit,
      description,
      maxRange,
      minRange,
      title,
    ]
  );

  return <Tab menu={{}} panes={panes} renderActiveOnly={false} />;
}

type TimeseriesRefsType = {
  [key: number]: React.RefObject<TimeseriesSelector>;
};

export type EditLineChartMetaState = {
  rowKeys: number[];
  timeseriesRefs: TimeseriesRefsType;
  panelMeta: LineChartMetaData;
  activeIndex: number;
  errorObject: {
    [key: string]: {
      errorMsg: string;
    };
  };
  currentAlternateAxisMap: TimeseriesAxisMap[];
  isAdvancedSettingsOpen: boolean;
};

export class EditLineChartMeta extends PanelEditComponent<
  LineChartMetaData,
  EditLineChartMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  minRangeRef = React.createRef<HTMLInputElement>();
  maxRangeRef = React.createRef<HTMLInputElement>();
  groupByRef = React.createRef<HTMLInputElement>();
  lineWidthRef = React.createRef<HTMLInputElement>();
  markerRadiusRef = React.createRef<HTMLInputElement>();
  upperThresholdRef = React.createRef<HTMLInputElement>();
  lowerThresholdRef = React.createRef<HTMLInputElement>();
  xAxisLabelRef = React.createRef<HTMLInputElement>();
  yAxisLabelRef = React.createRef<HTMLInputElement>();
  xAxisUnitRef = React.createRef<HTMLInputElement>();
  yAxisUnitRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditLineChartMetaState {
    return {
      rowKeys: [0],
      timeseriesRefs: { 0: React.createRef<TimeseriesSelector>() },
      activeIndex: 0,
      errorObject: {},
      panelMeta: {
        type: "line_chart",
        title: "",
        description: "",
        minRange: undefined,
        maxRange: undefined,
        id: uuid.v4(),
        timeseries: [],
        alternateAxisMap: [],
        showAreaChart: false,
        connectNullValues: false,
        lineWidth: 2,
        markerRadius: 6,
        threshold: undefined,
        lowerThreshold: undefined,
        xAxisLabel: "",
        yAxisLabel: "",
        xAxisUnit: "",
        yAxisUnit: "",
        xAxisGrids: true,
        yAxisGrids: true,
        showMarkers: false,
        rangeToZero: false,
        autoMarginEnable: true,
        leftMarginYaxis: 100,
      },
      currentAlternateAxisMap: [],
      isAdvancedSettingsOpen: false,
    };
  }

  constructor(props) {
    super(props);

    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditLineChartMeta.defaultState();
    }
  }

  getStateFromPanelMeta(props) {
    let rowKeys = props.panelMeta.timeseries.map((_m, i) => i);

    if (rowKeys.length === 0) {
      rowKeys = [0];
    }

    const timeseriesRefs = {};

    rowKeys.forEach((k) => {
      timeseriesRefs[k] = React.createRef<TimeseriesSelector>();
    });

    // initially update state with prop data coming from /panel-data api
    let currentAlternateAxisMap: TimeseriesAxisMap[] =
      this.props.panelMeta.timeseries.map((item, index) => ({
        table: item.table,
        column: item.column,
        aggregator: item.aggregator,
        alternateAxis:
          props.panelMeta.alternateAxisMap?.[index]?.alternateAxis ?? false,
        query: item.query !== undefined ? item.query : undefined,
        groupBys: item.groupBys ?? undefined,
      }));

    return {
      panelMeta: props.panelMeta,
      errorObject: {},
      rowKeys: rowKeys,
      timeseriesRefs: timeseriesRefs,
      data: [],
      timeRange: new RelativeTimeRange(15, "minutes"),
      activeIndex: 0,
      currentAlternateAxisMap,
      isAdvancedSettingsOpen: false,
    };
  }

  addRow = () => {
    this.setState(({ rowKeys, timeseriesRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs: TimeseriesRefsType = {
        ...timeseriesRefs,
        [newRowKey]: React.createRef(),
      };

      return {
        rowKeys: [...rowKeys, newRowKey],
        timeseriesRefs: newRefs,
      };
    });
  };

  removeRow = (i: number) => {
    delete this.state.timeseriesRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      timeseriesRefs: this.state.timeseriesRefs,
    });
  };

  getTimeseries() {
    return this.state.rowKeys.map((key) => {
      const ref = this.state.timeseriesRefs[key];

      if (ref.current == null) {
        throw new Error("Empty ref");
      }

      return ref.current.getTimeseries();
    });
  }

  getPanelMeta = (type): PartialMetaData<LineChartMetaData> => {
    let isPanelMetaValid = true;

    const upperThreshold = this.upperThresholdRef.current?.value
      ? parseFloat(this.upperThresholdRef.current?.value)
      : undefined;
    const lowerThreshold = this.lowerThresholdRef.current?.value
      ? parseFloat(this.lowerThresholdRef.current?.value)
      : undefined;

    // Validation: Ensure upper threshold is greater than the lower threshold
    if (upperThreshold !== undefined && lowerThreshold !== undefined) {
      if (upperThreshold < lowerThreshold) {
        isPanelMetaValid = false;
        this.setState((prevState) => ({
          errorObject: {
            ...prevState.errorObject,
            threshold: {
              errorMsg:
                "Upper Threshold must be greater than the Lower Threshold.",
            },
          },
        }));
      } else {
        this.setState((prevState) => ({
          errorObject: {
            ...prevState.errorObject,
            threshold: {
              errorMsg: "",
            },
          },
        }));
      }
    }

    if (
      (this.maxRangeRef.current?.value && !this.minRangeRef.current?.value) ||
      (!this.maxRangeRef.current?.value && this.minRangeRef.current?.value)
    ) {
      isPanelMetaValid = false;
      this.setState((prevState) => ({
        errorObject: {
          ...prevState.errorObject,
          range: {
            errorMsg: "Both Min and Max are required.",
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        errorObject: {
          ...prevState.errorObject,
          range: {
            errorMsg: "",
          },
        },
      }));
    }

    const validAxisTimeseriesMapping = this.state.rowKeys
      .map((key) => this.state.timeseriesRefs[key])
      .filter((ref) => {
        return ref?.current?.isFilled?.(type);
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }

        return ref.current.getTimeseriesWithAltAxis();
      });

    const validTimeseries = this.state.rowKeys
      .map((key) => this.state.timeseriesRefs[key])
      .filter((ref) => {
        return ref?.current?.isFilled?.(type);
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }
        // alternateAxis (boolean) data comes from validAxisTimeseriesMapping
        // adding it here too breaks the api request
        const { alternateAxis, ...rest } = ref.current.getTimeseries();
        return rest;
      });

    const meta: LineChartMetaData = {
      type: "line_chart",
      id: this.state.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      minRange: this.minRangeRef.current?.value
        ? parseFloat(this.minRangeRef.current?.value)
        : undefined,
      maxRange: this.maxRangeRef.current?.value
        ? parseFloat(this.maxRangeRef.current?.value)
        : undefined,
      showAreaChart: this.state.panelMeta.showAreaChart,
      connectNullValues: this.state.panelMeta.connectNullValues,
      lineWidth: this.state.panelMeta.lineWidth
        ? this.state.panelMeta.lineWidth
        : 2,
      markerRadius: this.state.panelMeta.markerRadius
        ? this.state.panelMeta.markerRadius
        : 6,
      threshold: this.upperThresholdRef.current?.value
        ? parseFloat(this.upperThresholdRef.current?.value)
        : undefined,
      lowerThreshold: this.lowerThresholdRef.current?.value
        ? parseFloat(this.lowerThresholdRef.current?.value)
        : undefined,
      xAxisLabel: this.xAxisLabelRef.current?.value
        ? this.xAxisLabelRef.current?.value
        : "",
      yAxisLabel: this.yAxisLabelRef.current?.value
        ? this.yAxisLabelRef.current?.value
        : "",
      xAxisUnit: this.xAxisUnitRef.current?.value
        ? this.xAxisUnitRef.current?.value
        : "",
      yAxisUnit: this.yAxisUnitRef.current?.value
        ? this.yAxisUnitRef.current?.value
        : "",
      xAxisGrids: this.state.panelMeta.xAxisGrids,
      autoMarginEnable: this.state.panelMeta.autoMarginEnable,
      leftMarginYaxis: this.state.panelMeta.leftMarginYaxis,
      yAxisGrids: this.state.panelMeta.yAxisGrids,
      rangeToZero: this.state.panelMeta.rangeToZero,
      showMarkers: this.state.panelMeta.showMarkers,
      alternateAxisMap: validAxisTimeseriesMapping,
      timeseries: validTimeseries,
      groupBys: this.groupByRef.current?.value
        ? [this.groupByRef.current?.value]
        : [],
    };

    return {
      meta: meta,
      complete:
        validTimeseries.length > 0 &&
        !!(
          ((meta.minRange || meta.minRange === 0) && !!meta.maxRange) ||
          (!(meta.minRange || meta.minRange === 0) && !meta.maxRange)
        ) &&
        isPanelMetaValid &&
        // Restricting the panel data API call if Advanced settings modal is open
        !this.state.isAdvancedSettingsOpen,
      errorMessage:
        this.state.errorObject?.range?.errorMsg ||
        this.state.errorObject?.threshold?.errorMsg,
    };
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  onToggleAreaChart = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        showAreaChart: checked,
      },
    }));
  };

  onToggleNullValues = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        connectNullValues: checked,
      },
    }));
  };

  onToggleShowMarkers = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        showMarkers: checked,
      },
    }));
  };

  onToggleXAxis = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        xAxisGrids: checked,
      },
    }));
  };

  onToggleYAxis = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        yAxisGrids: checked,
      },
    }));
  };

  onToggleRangeToZero = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        rangeToZero: checked,
      },
    }));
  };

  handleSliderChange = (event) => {
    event.persist();
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        lineWidth: parseFloat(event.target.value),
      },
    }));
  };

  handleMarginSliderChange = (event) => {
    event.persist();
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        leftMarginYaxis: parseFloat(event.target.value),
      },
    }));
  };

  onToggleAutoMarginEnable = (checked) => {
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        autoMarginEnable: checked,
      },
    }));
  };

  onToggleAdvancedSettingsOpen = () => {
    this.setState((prevState) => ({
      isAdvancedSettingsOpen: !prevState.isAdvancedSettingsOpen,
    }));
  };

  handleMarkerSliderChange = (event) => {
    event.persist();
    this.setState((prevState) => ({
      panelMeta: {
        ...prevState.panelMeta,
        markerRadius: parseFloat(event.target.value),
      },
    }));
  };

  // update data being shown in ui coming from /panel-data
  componentDidUpdate(): void {
    const { timeseriesRefs, currentAlternateAxisMap } = this.state;
    const timeseriesRefsKeys = Object.keys(timeseriesRefs);

    const updatedAlternateAxisMap = timeseriesRefsKeys.map((ts) => {
      return timeseriesRefs[ts].current?.getTimeseries();
    });

    const needsUpdate =
      timeseriesRefsKeys.length !== currentAlternateAxisMap.length ||
      !updatedAlternateAxisMap.every((timeseries, index) =>
        isEqual(timeseries, currentAlternateAxisMap[index])
      );

    if (needsUpdate) {
      this.setState({ currentAlternateAxisMap: updatedAlternateAxisMap });
    }
  }

  render() {
    const { activeIndex } = this.state;
    const rowKeys = this.state.rowKeys;

    // Setting default value to Grids to true in case its undefined, to correctlt render checkbox toggle
    if (this.state.panelMeta && this.state.panelMeta.xAxisGrids === undefined) {
      this.setState((prevState) => ({
        panelMeta: {
          ...prevState.panelMeta,
          xAxisGrids: true,
        },
      }));
    }
    if (this.state.panelMeta && this.state.panelMeta.yAxisGrids === undefined) {
      this.setState((prevState) => ({
        panelMeta: {
          ...prevState.panelMeta,
          yAxisGrids: true,
        },
      }));
    }

    return (
      <EditMetaRoot>
        <EditLineChartTabs
          tables={this.props.tables}
          titleRef={this.titleRef}
          descriptionRef={this.descriptionRef}
          timeseriesRefs={this.state.timeseriesRefs}
          minRangeRef={this.minRangeRef}
          maxRangeRef={this.maxRangeRef}
          upperThresholdRef={this.upperThresholdRef}
          lowerThresholdRef={this.lowerThresholdRef}
          xAxisLabelRef={this.xAxisLabelRef}
          yAxisLabelRef={this.yAxisLabelRef}
          xAxisUnitRef={this.xAxisUnitRef}
          yAxisUnitRef={this.yAxisUnitRef}
          groupByRef={this.groupByRef}
          panelMeta={this.props.panelMeta}
          statePanelMeta={this.state.panelMeta}
          rowKeys={rowKeys}
          activeIndex={activeIndex}
          dashboardType={this.props.dashboardType}
          currentAlternateAxisMap={this.state.currentAlternateAxisMap}
          addRow={this.addRow}
          removeRow={this.removeRow}
          onToggleAdvancedSettingsOpen={this.onToggleAdvancedSettingsOpen}
          handleAccordionClick={this.handleAccordionClick}
          onToggleAreaChart={this.onToggleAreaChart}
          onToggleNullValues={this.onToggleNullValues}
          onToggleShowMarkers={this.onToggleShowMarkers}
          errorObject={this.state.errorObject}
          handleSliderChange={this.handleSliderChange}
          handleMarkerSliderChange={this.handleMarkerSliderChange}
          onToggleXAxis={this.onToggleXAxis}
          onToggleYAxis={this.onToggleYAxis}
          onToggleAutoMarginEnable={this.onToggleAutoMarginEnable}
          handleMarginSliderChange={this.handleMarginSliderChange}
        />
      </EditMetaRoot>
    );
  }
}
