import React from "react";
import {
  Divider,
  MenuItem,
  Pagination,
  PaginationProps,
} from "semantic-ui-react";
import {
  DeviceFilterOption,
  searchDevices,
  Filters,
  Device,
  fetchDeviceFilterOptions,
  devicesPerPageOptions,
} from "../../../../BytebeamClient";

import { SearchHeader } from "./SearchHeader";
import { SearchResults } from "./SearchResults";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
import LoadingAnimation from "../../../common/Loader";
import { SearchPageInput } from "../../util";
import { validateWholeNumber } from "../../../../util";
import { beamtoast } from "../../../common/CustomToast";
import { Prompt } from "react-router";
import { StyledDevicePerPageWidget } from "../../../common/commonStyledComps";
import { breakpoints } from "../../../common/breakpoints";
import ForcedDirDropdownStyled from "../../../common/ForceDirDropdownStyled";

type DeviceSearchProps = {
  title: string;
  allowedFilterBys: string[];
  metadataKeysToShow: string[];
  onDeviceSelected: (device: Device) => void;
  isDirty: boolean;
  updateWarningModal: (arg0: boolean) => void;
  showWarningModal: boolean;
  onDashboardSave: () => void;
  comparisonVisible?: boolean;
  filters: Filters;
  deviceFilters: Filters;
};

type DeviceSearchState = {
  filters: Filters;
  filterOptions: DeviceFilterOption[];
  devices: Device[];
  page: number;
  pageCount: number;
  devicesPerPage: number;
  loading: boolean;
  showInActive: boolean;
  inputPageNumber: number;
  screenWidth: number;
  navigationLocation: string;
};

export class DeviceSearch extends React.Component<
  DeviceSearchProps,
  DeviceSearchState
> {
  constructor(props) {
    super(props);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.state = {
      filters: {
        ...props.deviceFilters,
      },
      filterOptions: [],
      devices: [],
      page: 1,
      pageCount: 10,
      devicesPerPage: 0,
      loading: true,
      showInActive: false,
      inputPageNumber: 0,
      screenWidth: window.innerWidth,
      navigationLocation: "",
    };
  }

  abortController = new AbortController();

  async handleToggleClick() {
    const showInActiveDevice = !this.state.showInActive;
    this.setState({ showInActive: showInActiveDevice, loading: true });
    this.refreshDevices();
  }

  refreshDevices() {
    //setTimeout here is to allow change in filters/page using setState to take affect before refreshing devices;
    this.abortController.abort();
    this.abortController = new AbortController();
    setTimeout(async () => {
      const status = this.state.showInActive ? "all" : "active";
      const devices = await searchDevices(
        { metaDatafilters: this.state.filters },
        this.state.page,
        this.state.devicesPerPage,
        status,
        this.abortController.signal
      );
      this.setState({
        devices: devices.devices,
        pageCount: Math.ceil(devices.count / this.state.devicesPerPage),
        loading: false,
      });
    });
  }

  async refreshFilterOptions(filters: Filters) {
    const allFilterOptions = await fetchDeviceFilterOptions(filters);

    const filterOptionsMap = Object.fromEntries(
      allFilterOptions.map(({ filterName, filterValues }) => [
        filterName,
        filterValues,
      ])
    );

    this.setState({
      filterOptions: this.props.allowedFilterBys.map((filterName) => {
        return {
          filterName: filterName,
          filterValues: filterOptionsMap[filterName] || [],
        };
      }),
    });
  }

  updateFilters(filterName: string, filterValues: string[]) {
    const filters = Object.assign({}, this.state.filters);

    if (filterValues.length > 0) {
      filters[filterName] = filterValues;
    } else {
      delete filters[filterName];
    }

    this.setState({
      filters: filters,
      page: 1,
      loading: true,
    });

    this.refreshFilterOptions(filters);
    this.refreshDevices();
  }

  handlePaginationInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    this.setState({
      inputPageNumber: newValue,
    });
  };

  handlePaginationInputKeyDown = (event) => {
    const { inputPageNumber, pageCount } = this.state;

    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      if (validateWholeNumber(inputPageNumber.toString())) {
        let newPageNumber = 1; // Default to the first page if the input is invalid or out of bounds

        if (inputPageNumber > 0) {
          newPageNumber = Math.min(inputPageNumber, pageCount);
        }

        this.onPageChange(event, {
          activePage: newPageNumber,
          totalPages: pageCount,
        });

        this.setState({
          inputPageNumber: 0,
        });
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  onPageChange(_e: React.MouseEvent, data: PaginationProps) {
    if (data.activePage) {
      this.setState({
        page: data.activePage as number,
      });

      this.refreshDevices();
    }
  }

  changeDevicesPerPage(e, data) {
    try {
      this.setState({
        loading: true,
        devicesPerPage: parseInt(data.value),
        page: 1,
      });
      window.localStorage.setItem("dashboardDevicesPerPage", data.value);
      this.refreshDevices();
    } catch (e) {
      beamtoast.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    const deviceCountPerPage = parseInt(
      window.localStorage.getItem("dashboardDevicesPerPage") ?? "25"
    );
    this.setState({ devicesPerPage: deviceCountPerPage });
    this.refreshFilterOptions(this.state.filters);
    this.refreshDevices();

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this.abortController.abort();

    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  // Event handler for beforeunload event
  handleBeforeUnload = (e) => {
    if (this.props.isDirty) {
      // Prevent the default browser behavior
      e.preventDefault();

      // Show the custom confirmation modal
      // this.setState({ showWarningModal: true });

      // Chrome requires returnValue to be set
      e.returnValue = "";
    }
  };

  // Function to handle navigation
  handleNavigation = (location: any) => {
    // this prop (location) contains the path which the user is trying to currently go to
    // Check if there are unsaved changes
    if (this.props.isDirty) {
      // check only if search queries are changed in the same route pathname and allow routing without prompting the warning modal
      if (
        window.location.pathname === location.pathname &&
        location.search &&
        window.location.search !== location.search
      ) {
        // allow routing
        return true;
      }
      // Display a confirmation dialog if whole route pathname is being changed aka user is trying to move out from the current page entirely without saving their changes
      else if (window.location.pathname !== location.pathname)
        this.setState({ navigationLocation: location.pathname }, () =>
          // this.props.updateWarningModal(true)
          this.letUserNavigate()
        );

      return false;
    }

    // Continue with navigation if there are no unsaved changes
    return true;
  };

  letUserNavigate() {
    window.location.href = this.state.navigationLocation;
  }

  render() {
    let filterOptions: DeviceFilterOption[] = this.state.filterOptions;

    if (filterOptions.length === 0) {
      filterOptions = this.props.allowedFilterBys.map((filterBy) => {
        return {
          filterName: filterBy,
          filterValues: [],
        };
      });
    }

    const showOverflowXScroll =
      this.props.comparisonVisible && this.state.screenWidth > breakpoints.sm;

    return (
      <div
        className={`bytebeam-dashboard ${showOverflowXScroll ? "overflowScrollX" : ""}`}
      >
        {/* custom modal for prompting if dashboard has unsaved changes */}
        {/* {this.props.showWarningModal && (
          <Modal
            className="dark"
            open={this.props.showWarningModal}
            size="tiny"
            onClose={() => this.props.updateWarningModal(false)}
          >
            <Modal.Header> Save Dashboard ? </Modal.Header>
            <Modal.Content>
              <div style={{ fontSize: "15px", marginBottom: "8px" }}>
                Are you sure you want to leave the dashboard without saving your
                changes ?
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                onClick={() => this.props.updateWarningModal(false)}
              >
                No
              </Button>
              <Button primary onClick={() => this.letUserNavigate()}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        )} */}

        <Prompt when={this.props.isDirty} message={this.handleNavigation} />

        <SearchHeader
          filterOptions={filterOptions}
          title={this.props.title}
          onFilterSelected={this.updateFilters.bind(this)}
          showInactive={this.state.showInActive}
          handleToggleClick={this.handleToggleClick}
          filters={this.props.deviceFilters}
        />

        <Divider />

        {this.state.loading ? (
          <LoadingAnimation
            loaderContainerHeight="65vh"
            loadingText="Loading results"
            fontSize="20px"
          />
        ) : (
          <>
            <SearchResults
              devices={this.state.devices}
              metadataKeysToShow={this.props.metadataKeysToShow}
              onDeviceSelected={this.props.onDeviceSelected}
              filters={this.props.filters as Record<string, string[]>}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "16px",
                marginBottom: "30px",
              }}
            >
              <Pagination
                activePage={this.state.page}
                onPageChange={this.onPageChange.bind(this)}
                totalPages={this.state.pageCount}
                boundaryRange={0}
                ellipsisItem={null}
              />

              <SearchPageInput
                icon="search"
                placeholder="Jump to page..."
                name="activePage"
                min={1}
                onChange={this.handlePaginationInputChange}
                onKeyDown={this.handlePaginationInputKeyDown}
                type="number"
                value={
                  this.state.inputPageNumber ? this.state.inputPageNumber : ""
                }
              />

              <StyledDevicePerPageWidget>
                <MenuItem>Devices per page</MenuItem>
                <MenuItem style={{ padding: "0px" }}>
                  <ForcedDirDropdownStyled
                    dropdownComponent={SelectDevicesPerPage}
                    pointing="top"
                    compact
                    selection
                    options={devicesPerPageOptions}
                    value={this.state.devicesPerPage}
                    onChange={this.changeDevicesPerPage.bind(this)}
                  />
                </MenuItem>
              </StyledDevicePerPageWidget>
            </div>
          </>
        )}
      </div>
    );
  }
}
