import React from "react";
import styled from "styled-components";

type LoadingCircleWrapperProps = {
  styleprops: LoadingAnimationProps;
};

const LoadingCircleWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "styleprops",
})<LoadingCircleWrapperProps>`
  width: 100%;
  display: flex;
  margin-top: ${(props: LoadingCircleWrapperProps) =>
    props.styleprops.marginTopLoader ?? "0px"};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;

  .loading-circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00000000; // Last two digits of 8 digit hex code are opacity
    height: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.loaderContainerHeight ?? "100%"} !important;
    margin: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.loaderContainerMargin};
  }

  .loading-circle {
    position: relative;
    min-height: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.loaderSize ?? "52px"};
    width: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.loaderSize ?? "52px"};
    height: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.loaderSize ?? "52px"};
    border-radius: 50%;
    border: ${(props) => props.styleprops.loaderBorderSize ?? "5px"} solid
      ${({ theme }) => theme.colors["loading-circle-foreground-color"]};
    border-top-color: ${(props) =>
      props.styleprops.loaderColor ?? props.theme.colors["text"]};
    animation: rotate 0.6s infinite linear;
  }

  .loading-text {
    margin-top: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.marginTopText ?? "10px"};
    font-size: ${(props: LoadingCircleWrapperProps) =>
      props.styleprops.fontSize ?? "16px"};
    color: ${(props) =>
      props.styleprops.textColor ?? props.theme.colors["text"]};
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

type LoadingAnimationProps = {
  loadingText?: string;
  loaderBorderSize?: string;
  loaderSize?: string;
  fontSize?: string;
  marginTopText?: string;
  marginTopLoader?: string;
  loaderContainerMargin?: string;
  loaderColor?: string;
  textColor?: string;
  loaderContainerHeight?: string;
};

function LoadingAnimation(props: LoadingAnimationProps) {
  return (
    <LoadingCircleWrapper styleprops={props}>
      <div className="loading-circle-container">
        <div className="loading-circle" />
        {props.loadingText && (
          <div className="loading-text">{props.loadingText}</div>
        )}
      </div>
    </LoadingCircleWrapper>
  );
}

export default LoadingAnimation;
