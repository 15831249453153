import React from "react";
import { Chart } from "react-google-charts";
import { PanelViewComponent } from "../PanelDef";
import { AbsoluteTimeRange } from "../../Datetime/TimeRange";
import { AbsoluteTimestamp } from "../../Datetime/Timestamp";
import styled from "styled-components";
import {
  StateTimelineData,
  StateTimelineMetaData,
  TimestampedState,
} from "./PanelDef";
import ThemeSchema from "../../../../../theme/schema";
import { UserContext } from "../../../../../context/User.context";
import { beamtoast } from "../../../../common/CustomToast";
import { DateTimeStreamsFieldsTypes } from "../util";

const TimeLineContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 50px;
  padding: 10px;
`;

type ProcessedData = {
  start: number;
  end: number;
  duration: number;
  status: string;
};

export class ViewStateTimeline extends PanelViewComponent<
  StateTimelineMetaData,
  StateTimelineData
> {
  onRelayout(event) {
    if ("xaxis.range[0]" in event && this.props.onTimeRangeChange) {
      const startTime = event["xaxis.range[0]"];
      const endTime = event["xaxis.range[1]"];

      this.props.onTimeRangeChange(
        new AbsoluteTimeRange(
          new AbsoluteTimestamp(new Date(startTime)),
          new AbsoluteTimestamp(new Date(endTime))
        )
      );
    }
  }

  applyFillColorToTextElements(color: string) {
    if (!color) color = "#808080";
    const container = document.getElementById("stateTimelineContainer"); // Replace with your chart container ID
    if (!container) return; // Ensure the container exists

    const labels = container.getElementsByTagName("text");
    Array.prototype.forEach.call(labels, function (label) {
      if (label.getAttribute("text-anchor") === "middle") {
        label.setAttribute("fill", color);
      }
    });
  }

  handleBarClick = (chartWrapper) => {
    if (this.props.panelMeta.toggleBarClick === false) {
      return;
    }
    let index = chartWrapper.getChart().getSelection()[0].row;
    let data = chartWrapper.getDataTable();
    const state = data.getValue(index, 1);
    const startTime = new Date(data.getValue(index, 2));
    const endTime = new Date(data.getValue(index, 3));
    beamtoast.info(`Selected state is ${state}`);
    if (this.props.onTimeRangeChange) {
      this.props.onTimeRangeChange(
        new AbsoluteTimeRange(
          new AbsoluteTimestamp(new Date(startTime)),
          new AbsoluteTimestamp(new Date(endTime))
        )
      );
    }
  };

  render() {
    const columnType = this.props.tables[this.props.panelMeta.table]?.find(
      (column) => column?.name === this.props.panelMeta.stateColumn
    )?.type;

    let data: TimestampedState[] = [];
    if (this.props.data[0]?.data && this.props.data[0].data.length > 0) {
      data = this.props.data[0].data.map((row) => {
        let value = row.state;
        if (DateTimeStreamsFieldsTypes.includes(columnType as string)) {
          if (typeof value === "string") {
            value = new Date(value + "z").toLocaleString("en-GB");
          } else if (typeof value === "number") {
            value = new Date(value).toLocaleString("en-GB");
          }
        }

        return {
          timestamp: row.timestamp,
          state: value,
        };
      });
    }

    let statusColorMap = this.props.panelMeta.colorMapping;

    const ObjStatusColorMap: Record<string, string> = {};
    statusColorMap.forEach((item) => {
      ObjStatusColorMap[item.state] = item.color;
    });

    const processedData: ProcessedData[] = [];

    if (data.length === 0) {
      return <div className="panel-no-data">No Data</div>;
    }

    if (data.length - 1 === 0) {
      let endTimeDuration =
        data[0].timestamp -
        this.props.timeRange.getEndTime().toDate().getTime();
      processedData.push({
        start: data[0].timestamp,
        end: this.props.timeRange.getEndTime().toDate().getTime(),
        duration: endTimeDuration,
        status: data[0].state,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let totalDuration = 0;
    for (let i = 0; i < data.length - 1; i++) {
      let duration = data[i + 1].timestamp - data[i].timestamp;
      totalDuration += duration;

      processedData.push({
        start: data[i].timestamp,
        end: data[i + 1].timestamp,
        duration: duration,
        status: data[i].state,
      });
    }

    let options = {
      // colors: ["#cbb69d", "#603913", "#c69c6e"],
      timeline: { showRowLabels: false },
      avoidOverlappingGridLines: false,
      hAxis: {
        format: "dd/MM/YY-HH:mm:ss", // Change the format as needed
      },
    };

    const chartData = [
      [
        { type: "string", id: "Position" },
        { type: "string", id: "Name" },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
      ],
      ...processedData.map((event) => [
        "States",
        event.status,
        new Date(event.start),
        new Date(event.end),
      ]),
    ];

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <TimeLineContainer id="stateTimelineContainer">
            <Chart
              chartType="Timeline"
              data={chartData}
              options={{
                ...options,
                backgroundColor:
                  ThemeSchema.data[userContext.user?.settings?.theme ?? "dark"]
                    ?.colors["background-color"],
                hAxis: {
                  ...options.hAxis,
                  textStyle: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
                vAxis: {
                  textStyle: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
              }}
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) =>
                    this.handleBarClick(chartWrapper),
                },
                {
                  eventName: "ready",
                  callback: () =>
                    this.applyFillColorToTextElements(
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"]
                    ),
                },
              ]}
              width="100%"
              height="100%"
            />
          </TimeLineContainer>
        )}
      </UserContext.Consumer>
    );
  }
}
