import React, { useState } from "react";
import { Icon, Table } from "semantic-ui-react";
import { ButtonIcon, DisplayIf, copyContentToClipboard } from "../../util";
import { deleteApiKey, editApiKey } from "../../../../BytebeamClient";
import ConfirmationModal from "../../common/ConfirmationModal";
import { ApiKey, Permission, Role } from "../../../../util";
import CreateOrEditApiKeyModal from "./CreateOrEditApiKeyModal";
import { Mixpanel } from "../../common/MixPanel";
import { beamtoast } from "../../../common/CustomToast";

type APIKeyRowType = {
  roles: Role[];
  apiKey: ApiKey;
  allApiKeys: ApiKey[];
  onUpdate: () => Promise<void>;
  permissions: Permission;
};

type DeleteApiKeyConfirmationModalMessageProps = {
  apiKey: ApiKey;
};

function DeleteApiKeyConfirmationModalMessage(
  props: DeleteApiKeyConfirmationModalMessageProps
) {
  const { apiKey } = props;
  return (
    <>
      <p>
        <span>Are you absolutely sure?</span>
        <br />
        You are going to delete selected API Key created by{" "}
        <b>{apiKey["created-by"]}</b>.
        <br />
      </p>
      <div
        style={{
          padding: "8px",
          color: "#c50000",
          textAlign: "center",
          background: "#f7d4d6",
          border: "1px solid #c50000",
          borderRadius: "4px",
        }}
      >
        <b>Warning:</b> This action is not reversible. Please be certain.
      </div>
      <p>
        <br /> Please type <b>Yes</b> to proceed or press Cancel to close.
      </p>
    </>
  );
}

interface EditApikeyButtonProps {
  onUpdate: () => void;
  roles: Role[];
  apiKey: ApiKey;
  allApiKeys: ApiKey[];
}

/**
 * Renders a modal for editing an API key.
 * EditApiKeyModal sends a request to the server to edit the API key, with the new name & key.
 * @param props - The component props.
 * @returns The rendered component.
 */
function EditApiKeyModal(props: EditApikeyButtonProps) {
  return (
    <CreateOrEditApiKeyModal
      type="edit"
      onSubmit={async (apiKey) => {
        try {
          await editApiKey(props.apiKey.key, { name: apiKey.name });
          Mixpanel.track("Edit API Key", {});
          props.onUpdate();
          beamtoast.success(`Successfully updated the ${apiKey?.name} API Key`);
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "API Key Edit",
            error: JSON.stringify(e),
          });
          beamtoast.error(`Failed to edit the ${apiKey?.name} API Key`);
          console.log(e);
        }
      }}
      apiKey={{
        name: props.apiKey.name,
        role: props.apiKey.role,
      }}
      allApiKeys={props.allApiKeys}
      roles={props.roles}
      trigger={
        <Icon
          name={"edit"}
          style={{ cursor: "pointer", marginRight: "16px" }}
          title={"Edit the API Key"}
        />
      }
    />
  );
}

function APIKeyRow(props: APIKeyRowType) {
  const { apiKey, permissions, onUpdate } = props;
  const [showKey, setShowKey] = useState<boolean>(false);

  function maskKey(key) {
    return (
      key.slice(0, 4) +
      "*****-*****-*****-*****-*********" +
      apiKey.key.slice(-4)
    );
  }

  async function handleKeyDelete() {
    await deleteApiKey(apiKey.key);
    beamtoast.success(`API Key ${apiKey.name} deleted successfully`);
    onUpdate();
  }

  return (
    <Table.Row>
      <Table.Cell>{showKey ? apiKey.key : maskKey(apiKey.key)}</Table.Cell>
      <Table.Cell>{apiKey["name"]}</Table.Cell>
      <Table.Cell>{apiKey["created-by"]}</Table.Cell>
      <DisplayIf cond={permissions.viewRoles}>
        <Table.Cell>{apiKey.role}</Table.Cell>
      </DisplayIf>
      <Table.Cell>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "nowrap",
            gap: "12px",
          }}
        >
          <Icon
            name={showKey ? "eye slash" : "eye"}
            style={{ cursor: "pointer", marginRight: "16px" }}
            onClick={() => setShowKey(!showKey)}
            title={showKey ? "Hide API Key" : "Show API Key"}
          />
          <Icon
            name={"copy"}
            style={{ cursor: "pointer", marginRight: "16px" }}
            onClick={async () =>
              await copyContentToClipboard(apiKey.key, false)
            }
            title={"Copy API Key to Clipboard"}
          />
          <EditApiKeyModal
            onUpdate={onUpdate}
            roles={props.roles}
            apiKey={apiKey}
            allApiKeys={props.allApiKeys}
          />
          <ConfirmationModal
            prefixContent="Delete API Key"
            expectedText={"Yes"}
            onConfirm={async () => {
              await handleKeyDelete();
            }}
            trigger={<ButtonIcon link name="trash" title={"Delete API Key"} />}
            message={<DeleteApiKeyConfirmationModalMessage apiKey={apiKey} />}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}

export default APIKeyRow;
