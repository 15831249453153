import React, { useState, useEffect } from "react";
import { Modal, Icon, Grid } from "semantic-ui-react";
import { DownloadMetadataModal } from "./DownloadMetadataModal";
import { DownloadFilteredMetadataModal } from "./DownloadFilteredMetadataModal";
import BulkMetadataUpdateModal from "./BulkMetadataUpdateModal";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { searchDevicesFilters } from "../../../../../BytebeamClient";
import { StyledButtonWithBorder } from "../Devices";

type BulkMetadataOperationsModalProps = {
  readonly isOpen: boolean;
  readonly close: (...args: any[]) => any;
  readonly setLoading: (...args: any[]) => void;
  readonly getDevicesFilters: () => searchDevicesFilters;
  readonly selectedDevices: string[];
  readonly refreshDevices: (inLoop?: boolean) => Promise<void>;
  readonly isRefreshPaused: boolean;
};

function BulkMetadataOperationsModal(props: BulkMetadataOperationsModalProps) {
  const {
    isOpen,
    close,
    setLoading,
    getDevicesFilters,
    selectedDevices,
    refreshDevices,
    isRefreshPaused,
  } = props;
  const [renderBulkDownloadMetadata, setRenderBulkDownloadMetadata] =
    useState(false);
  const [renderFilteredDownloadMetadata, setRenderFilteredDownloadMetadata] =
    useState(false);
  const [renderBulkUpdateMetadata, setRenderBulkUpdateMetadata] =
    useState(false);

  const devicesSearchFilters = getDevicesFilters();

  const BulkOperationButtonStyle = {
    padding: "10px 16px",
    marginBottom: "15px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "16px",
  } as React.CSSProperties;

  useEffect(() => {
    return () => {
      setRenderBulkDownloadMetadata(false);
      setRenderFilteredDownloadMetadata(false);
      setRenderBulkUpdateMetadata(false);
    };
  }, [isOpen]);

  return (
    <Modal closeIcon open={isOpen} onClose={close} size="tiny" className="dark">
      <Modal.Header>Metadata Bulk Operations</Modal.Header>
      {!renderBulkDownloadMetadata &&
        !renderBulkUpdateMetadata &&
        !renderFilteredDownloadMetadata && (
          <Modal.Content>
            <Grid>
              {/* Bulk Download Section */}
              <Grid.Row>
                <Grid.Column
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: "18px", fontWeight: 700 }}>
                    Bulk Download
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "16px",
                      opacity: 0.8,
                    }}
                  >
                    Download metadata for multiple devices at once
                  </div>
                  {(devicesSearchFilters?.stateFilters ||
                    devicesSearchFilters.metaDatafilters ||
                    selectedDevices.length > 0) && (
                    <StyledButtonWithBorder
                      onClick={() => setRenderFilteredDownloadMetadata(true)}
                      style={BulkOperationButtonStyle}
                    >
                      <Icon
                        name="cloud download"
                        style={{ fontSize: "18px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "6px",
                        }}
                      >
                        <span>Download Filtered Metadata</span>
                        <span style={{ fontSize: "12px", opacity: 0.8 }}>
                          Download metadata for currently filtered devices upto
                          10000 devices
                        </span>
                      </div>
                    </StyledButtonWithBorder>
                  )}
                  <StyledButtonWithBorder
                    onClick={() => setRenderBulkDownloadMetadata(true)}
                    style={BulkOperationButtonStyle}
                  >
                    <Icon name="download" style={{ fontSize: "18px" }} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "6px",
                      }}
                    >
                      <span>Download All Metadata</span>
                      <span style={{ fontSize: "12px", opacity: 0.8 }}>
                        Download metadata for all available devices upto 10000
                        devices
                      </span>
                    </div>
                  </StyledButtonWithBorder>
                </Grid.Column>
              </Grid.Row>

              <ThinDivider />

              {/* Bulk Update Section */}
              <Grid.Row>
                <Grid.Column
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: "18px", fontWeight: 700 }}>
                    Bulk Update
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "16px",
                      opacity: 0.8,
                    }}
                  >
                    Update metadata for multiple devices simultaneously
                  </div>
                  <StyledButtonWithBorder
                    onClick={() => setRenderBulkUpdateMetadata(true)}
                    style={BulkOperationButtonStyle}
                  >
                    <Icon name="cloud upload" style={{ fontSize: "18px" }} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "6px",
                      }}
                    >
                      <span>Update Metadata</span>
                      <span style={{ fontSize: "12px", opacity: 0.8 }}>
                        Update metadata for multiple devices upto 10000 devices
                      </span>
                    </div>
                  </StyledButtonWithBorder>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        )}
      {renderBulkDownloadMetadata && !renderBulkUpdateMetadata && (
        <DownloadMetadataModal
          close={() => setRenderBulkDownloadMetadata(false)}
        />
      )}
      {renderFilteredDownloadMetadata && !renderBulkUpdateMetadata && (
        <DownloadFilteredMetadataModal
          devicesSearchFilters={
            selectedDevices.length > 0
              ? { metaDatafilters: { id: selectedDevices } }
              : devicesSearchFilters
          }
          close={() => setRenderFilteredDownloadMetadata(false)}
        />
      )}
      {!renderBulkDownloadMetadata && renderBulkUpdateMetadata && (
        <BulkMetadataUpdateModal
          close={() => setRenderBulkUpdateMetadata(false)}
          refreshDevices={refreshDevices}
          isRefreshPaused={isRefreshPaused}
          setLoading={setLoading}
        />
      )}
    </Modal>
  );
}

export default BulkMetadataOperationsModal;
