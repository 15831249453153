import React from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
  NumericalStreamFieldsTypes,
  DateTimeStreamsFieldsTypes,
} from "../Dashboards/Panel/util";
import { queryOperators } from "../Dashboards/util";
import { FilterState } from "../DeviceManagement/Devices/DevicesFilterUI/DevicesFilterUI";

type QueryBuild = {
  column: string;
  operator: string;
  value: string;
};

type QuerySelectorProps = {
  showRemoveIcon: boolean;
  showAddIcon: boolean;
  onAddRow: () => any;
  onRemoveRow: () => any;
  defaultValue?: QueryBuild;
  columns: { key: string; value: string; text: string }[];
  elementid: string;
};

type QuerySelectorState = {
  column: string;
  columnType: string;
  operator: string;
  value: string;
};

export class QuerySelector extends React.Component<
  QuerySelectorProps,
  QuerySelectorState
> {
  valueRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    if (props.defaultValue) {
      this.state = props.defaultValue;
    } else {
      this.state = {
        column: "",
        columnType: "",
        operator: "",
        value: "",
      };
    }
  }

  setOperator(_event, data) {
    if (data.value === "IS NULL" || data.value === "IS NOT NULL") {
      this.setState({
        operator: data.value === "IS NULL" ? "=" : "not=",
        value: "null",
      });
    } else {
      this.setState({
        operator: data.value,
      });
    }
  }

  setColumn(_event, data) {
    const columnType = data.options.filter(
      (item) => item.value === data.value
    )[0].type;

    this.setState({
      column: data.value,
      columnType: columnType,
      value: "",
      operator: "",
    });
  }

  setValue(data) {
    this.setState({
      value: data,
    });
  }

  getQuery() {
    const { column, operator, value } = this.state;
    // ["=" ,  { "column": "id"}, {"value": 2} ]
    return { column, operator, value };
  }

  isFilled() {
    return !!this.state.column && !!this.state.operator && !!this.state.value;
  }

  clearForm() {
    this.setState({
      column: "",
      operator: "",
      value: "",
    });
  }

  render() {
    let operatorOptions: Array<{ key: string; value: string; text: string }> =
      queryOperators;

    return (
      <Table.Row
        style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
      >
        <Table.Cell style={{ width: "50%" }}>
          <EditAnimatedMetaDropdown
            placeholder="Field"
            text={this.state.column || "Select Field"}
            search
            selection
            options={this.props.columns}
            onChange={this.setColumn.bind(this)}
            value={this.state.column}
            elementid={`column${this.props.elementid}`}
            disabled={this.props.columns.length === 0}
          />
        </Table.Cell>

        <Table.Cell style={{ width: "50%" }}>
          <EditAnimatedMetaDropdown
            placeholder="Operators"
            text={this.state.operator || "Select Operator"}
            search
            selection
            options={operatorOptions}
            onChange={this.setOperator.bind(this)}
            value={this.state.operator}
            elementid={`operator${this.props.elementid}`}
            disabled={this.props.columns.length === 0 || !this.state.column}
          />
        </Table.Cell>

        <Table.Cell style={{ width: "50%" }}>
          <EditAnimatedMetaInput
            type={
              [
                ...NumericalStreamFieldsTypes,
                ...DateTimeStreamsFieldsTypes,
              ].includes(this.state.columnType) && this.state.value !== "null"
                ? "number"
                : "text"
            }
            // step="any"
            defaultRef={this.valueRef}
            value={this.state.value}
            label="Value"
            disabled={this.props.columns.length === 0 || !this.state.operator}
            onChangeEvent={(e) => this.setValue(e.target.value)}
          />
        </Table.Cell>

        <Table.Cell>
          <div>
            {this.props.showRemoveIcon ? (
              <Button secondary onClick={this.props.onRemoveRow} icon>
                <Icon name="minus" />
              </Button>
            ) : (
              ""
            )}
            {this.props.showAddIcon ? (
              <Button primary onClick={this.props.onAddRow} icon>
                <Icon name="add" />
              </Button>
            ) : (
              ""
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export function queryToObject(query) {
  let n = query.length;
  let objectQuery: Array<{ value: string; column: string; operator: string }> =
    [];
  for (let i = 1; i < n; i++) {
    objectQuery.push({
      operator: query[i][0],
      column: query[i][1]["column"],
      value: query[i][2]["value"],
    });
  }
  // Output need to be in following format
  // return [{value: "100", column: "0", operator: ">"}, {value: "245", column: "4", operator: "<="}];
  return objectQuery;
}

/**
 * Converts an object to a formatted query array.
 * @param query - The object representing the query.
 * @returns The formatted query array.
 * @example return [ "and", ["=" ,  { "column": "id"}, {"value": 2} ], ["not=", { "column": "soc"}, {"value": 2}] ];
 */
interface QueryType
  extends Omit<FilterState, "id" | "type" | "field" | "value"> {
  column: string;
  value: string | number | boolean | null;
}

export function objectToQuery(query) {
  let formattedQuery: any = [];
  formattedQuery.push("and");

  for (const condition of query) {
    // converting value to number
    let { value, columnType, isNullOperator, operator, column } = condition;

    if (typeof value === "string") {
      if (isNullOperator) value = null;
      // Check if the string can be parsed to a boolean, when the column type is given as boolean or if it's not present do the conversion
      else if (!columnType || columnType === "boolean") {
        if (value?.toLowerCase() === "true") value = true;
        else if (value?.toLowerCase() === "false") value = false;
      }
      // Check if the string can be parsed to a float
      else if (
        /^[-+]?\d*\.?\d+([eE][-+]?\d+)?$/.test(value) &&
        columnType !== "string"
      ) {
        let parsedValue = parseFloat(value);
        if (!Number.isNaN(parsedValue)) {
          value = parsedValue;
        }
      }
    }

    formattedQuery.push([operator, { column }, { value }]);
  }
  // Output need to be in following format
  // return [
  //     "and",
  //         ["=" ,  { "column": "id"}, {"value": 2} ],
  //         ["not=", { "column": "soc"}, {"value": 2}]
  //   ];
  return formattedQuery;
}
