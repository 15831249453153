import React, { useState, ReactNode, useEffect } from "react";
import { Button, Input, Modal, Label } from "semantic-ui-react";
import { hasSpecialCharacters } from "../../util";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";

interface CreateMetadataModalProps {
  readonly title: string;
  readonly onSubmit: (key: string) => void;
  readonly trigger: ReactNode;
  readonly metadataKeysSet: Set<string>;
}

export default function CreateMetadataModal(props: CreateMetadataModalProps) {
  const { trigger, onSubmit, metadataKeysSet } = props;
  const [metadataKey, setMetadataKey] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [metaDataErrorMessages, setMetaDataErrorMessages] =
    useState<string>("");

  // Reset the modal state when it closes
  const onModalClose = () => {
    setMetadataKey("");
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit(metadataKey.trim());
    onModalClose();
  };

  useEffect(() => {
    if (metadataKey.trim() === "") {
      setButtonDisabled(true);
      setMetaDataErrorMessages("");
    } else if (metadataKey.length > 63) {
      setButtonDisabled(true);
      setMetaDataErrorMessages("Key cannot be more than 63 characters");
    } else if (metadataKeysSet.has(metadataKey)) {
      setButtonDisabled(true);
      setMetaDataErrorMessages("Key already exists");
    } else if (
      metadataKey.toLowerCase().trim() === "status" ||
      metadataKey.toLowerCase().trim() === "id" ||
      metadataKey.toLowerCase().trim() === "tenant_id"
    ) {
      setButtonDisabled(true);
      setMetaDataErrorMessages(
        `Key cannot be '${metadataKey.toLowerCase().trim()}'`
      );
    } else if (hasSpecialCharacters(metadataKey.trim())) {
      setButtonDisabled(true);
      setMetaDataErrorMessages(
        "Key cannot contain special characters except underscore"
      );
    } else {
      setButtonDisabled(false);
    }
  }, [metadataKey, metadataKeysSet]);

  return (
    <Modal
      className="dark"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={trigger}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <div>
          <Input labelPosition="left">
            <Label>Metadata Key</Label>
            <input
              placeholder="Enter metadata key..."
              autoFocus
              value={metadataKey}
              onChange={(e) =>
                setMetadataKey(e.target.value?.replace(/\s+/g, "_"))
              }
            />
          </Input>
        </div>
        <div>
          {buttonDisabled && (
            <ErrorMessage style={{ fontWeight: 700, top: "5px" }}>
              {metaDataErrorMessages}
            </ErrorMessage>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
