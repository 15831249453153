import React, { useState } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import styled from "styled-components";
import {
  Filters,
  PendingDeviceActionsResponse,
  getTenantFromURL,
  completeAllActionsForDevices,
} from "../../../../BytebeamClient";
import { useUser } from "../../../../context/User.context";

const StyledHeader = styled(Header)`
  border-bottom: none;
  margin: 0;
`;

export const StyledContent = styled(Modal.Content)`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const StyledActionNavItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  margin: 4px 0;
  white-space: nowrap;

  .icon {
    color: ${({ theme }) => theme.colors["link-text-color"]} !important;
  }

  &:hover {
    color: ${({ theme }) => theme.colors["link-text-color"]} !important;
    text-decoration: underline;
    cursor: pointer;
  }
`;

type PendingActionWithDeviceList = {
  action_id: number;
  status: string;
  type: string;
  device_ids: number[];
};

type ActionWarningModalProps = {
  readonly isWarningModalOpen: boolean;
  readonly setIsWarningModalOpen: (isOpen: boolean) => void;
  readonly pendingActionsOnDevices: PendingDeviceActionsResponse;
  readonly triggerAction: (checkPendingActions?: boolean) => Promise<void>;
};

const ActionWarningModal: React.FC<ActionWarningModalProps> = (props) => {
  const {
    isWarningModalOpen,
    setIsWarningModalOpen,
    pendingActionsOnDevices,
    triggerAction,
  } = props;
  const { user } = useUser();
  const closeWarningModal = () => setIsWarningModalOpen(false);
  const isMarkActionsAsCompleteAllowed =
    user?.role?.permissions?.allowMarkActionAsCompleted || false;

  const [loading, setLoading] = useState(false);

  function getPendingActionsWithDeviceList(
    pendingActionsOnDevices: PendingDeviceActionsResponse
  ): PendingActionWithDeviceList[] {
    const actionMap: { [key: number]: PendingActionWithDeviceList } = {};

    for (const [deviceId, actions] of Object.entries(pendingActionsOnDevices)) {
      const deviceIdNum = parseInt(deviceId, 10);

      for (const action of actions) {
        const { action_id, status, type } = action;

        if (!actionMap[action_id]) {
          actionMap[action_id] = {
            action_id,
            status,
            type,
            device_ids: [],
          };
        }

        actionMap[action_id].device_ids.push(deviceIdNum);
      }
    }

    return Object.values(actionMap).filter(
      (action) =>
        action.type !== "cancel_action" && action.type !== "launch_shell"
    );
  }

  async function handleCompleteAllActions(filter: Filters) {
    try {
      if (filter) {
        await completeAllActionsForDevices(filter);
      }
    } catch (e) {
      console.log(`Failed to mark all actions as complete for ${filter} `, e);
    }
  }

  // Complete the running action and proceed with the new action
  async function completeRunningActionAndProceed() {
    try {
      setLoading(true);

      const devicesWithPendingActions = Object.keys(pendingActionsOnDevices);

      await handleCompleteAllActions({
        id: devicesWithPendingActions,
      });

      await triggerAction(false);
      closeWarningModal();
    } catch (e) {
      console.error(
        "Error occurred during completing and proceeding with the new action",
        e
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      closeIcon
      className="dark"
      size="tiny"
      open={isWarningModalOpen}
      onClose={closeWarningModal}
      onOpen={() => setIsWarningModalOpen(true)}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Icon
          name="warning sign"
          color="yellow"
          style={{ marginRight: "16px" }}
        />
        Warning
      </Modal.Header>
      <StyledContent>
        <StyledHeader as="h3">
          Below Action(s) are already running on some of the selected device(s).
        </StyledHeader>
        <StyledContent>
          {getPendingActionsWithDeviceList(pendingActionsOnDevices).map(
            (action) => (
              <StyledActionNavItem
                key={action.action_id}
                onClick={() => {
                  window.open(
                    `/projects/${getTenantFromURL()}/actions?action_id=${action.action_id}`,
                    "_blank"
                  );
                }}
              >
                {`Action ${action.action_id} - ${action.type} (${action.device_ids?.length} Devices)`}
                <Icon name="share square" />
              </StyledActionNavItem>
            )
          )}
        </StyledContent>
        {isMarkActionsAsCompleteAllowed && (
          <>
            <StyledHeader as="h4">
              Would you like to mark all actions as complete for the selected
              devices and proceed with the new action?
            </StyledHeader>
            <Button
              id="confirm_button"
              secondary
              onClick={completeRunningActionAndProceed}
              loading={loading}
              disabled={loading}
            >
              Complete Actions on Selected Devices and Proceed
            </Button>

            <StyledHeader as="h4">OR</StyledHeader>
          </>
        )}

        <StyledHeader as="h4">
          Would you like to proceed with the running actions and trigger the new
          action?
        </StyledHeader>
        <Button
          id="proceed_button"
          primary
          onClick={() => {
            triggerAction(false);
            closeWarningModal();
          }}
          disabled={loading}
        >
          Proceed Anyway with New Action
        </Button>
      </StyledContent>
    </Modal>
  );
};

export default ActionWarningModal;
