import {
  Alert,
  AlertAggregate,
  AlertSummary,
  AlertsData,
  AlertsMetaData,
} from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import React, { ReactNode } from "react";
import { capitalizeFirstLetter } from "../../../util";
import {
  Button,
  Dimmer,
  Label,
  MenuItem,
  Modal,
  PaginationProps,
  Popup,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import {
  AlertRule,
  AlertRuleCriticality,
  validateWholeNumber,
} from "../../../../../util";
import {
  DateTimeStreamsFieldsTypes,
  StyledSemanticTabs,
  StyledTabPaneNoBorder,
  TableContainer,
  formatValue,
} from "../util";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../../common/commonStyledComps";
import { SelectDevicesPerPage } from "../../../DeviceManagement/Devices/Devices";
import { beamtoast } from "../../../../common/CustomToast";
import LoadingAnimation from "../../../../common/Loader";
import {
  fetchPanelData,
  rowsPerPageOptions,
} from "../../../../../BytebeamClient";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import styled from "styled-components";
import { StyledDimmerDimmable } from "../../../DeviceManagement/Devices/ActionModals/ShowDeviceStreamsModal";
import { isEqual, sortBy } from "lodash";
import { DashboardLinks } from "../../DashboardLinks";
import { AbsoluteTimeRange } from "../../Datetime/TimeRange";
import { AbsoluteTimestamp } from "../../Datetime/Timestamp";
import { DashboardsInfo } from "../../ViewDashboard";

const AlertCardComponent = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{
  backgroundColor?: string;
}>`
  height: 80px;
  width: calc(100% - 10px);
  padding: 20px;
  /** theme === dark => white text
     *  theme === light => if background color => white text else black text
     */
  color: ${({ theme, backgroundColor }) =>
    theme.colors["text"] !== "#000000"
      ? "white"
      : backgroundColor
        ? "white"
        : "black"};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ backgroundColor }) =>
    backgroundColor ? "none" : "1px solid #CCCCCC"};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
`;

export const colorMap: Record<AlertRuleCriticality, string> = {
  critical: "#c53f4f",
  warning: "#9E9335",
  info: "#6D95D2",
};

const RowsperpageMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "tableContainerHeight",
})<{
  tableContainerHeight: number;
}>`
  & .visible.menu {
    height: ${({ tableContainerHeight }) =>
      tableContainerHeight && tableContainerHeight <= 198
        ? `${tableContainerHeight}px`
        : "fit-content"} !important;
  }
`;

type AlertListModalProps = {
  readonly alerts: Alert[];
  readonly tabName: string;
  readonly trigger: React.ReactNode;
  readonly loading: boolean;
  readonly totalPages: number;
  readonly currentPage: number;
  readonly handlePaginationChange: (
    arg0: React.MouseEvent<HTMLAnchorElement>,
    arg1: PaginationProps
  ) => void;
  readonly handlePaginationInputChange: (arg0: any) => void;
  readonly handlePaginationInputKeyDown: (arg0: any, arg1: number) => void;
  readonly inputPageNumber: number;
  readonly alertsPerPage: number;
  readonly changeDevicesPerPage: (arg0: any, arg1: any) => void;
  readonly aggregates: AlertAggregate[];
  readonly alertRules?: AlertRule[];
  readonly dashboards: DashboardsInfo[];
  readonly currentDashboardType: string;
  readonly tables: Record<string, any[]>;
};

export default function AlertListModal(props: AlertListModalProps) {
  const [open, setOpen] = React.useState(false);
  const [selectedRuleName, setSelectedRuleName] = React.useState<string | null>(
    null
  );

  const handleOpen = () => {
    setSelectedRuleName(props.tabName);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRuleName(null);
  };

  // Filter alerts to only show the selected rule's alerts
  const displayAlerts = selectedRuleName
    ? props.alerts.filter(
        (alert) => alert.alert_rule?.name === selectedRuleName
      )
    : props.alerts;

  const serialMetadataKey =
    displayAlerts.length > 0 &&
    displayAlerts[0]["-serial_metadata"] &&
    Object.keys(displayAlerts[0]["-serial_metadata"]).length > 0 &&
    capitalizeFirstLetter(Object.keys(displayAlerts[0]["-serial_metadata"])[0]);

  return (
    <Modal
      className="dark"
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      size="large"
      trigger={props.trigger}
    >
      <Modal.Header>{props.tabName} list</Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <TableContainer style={{ paddingTop: "10px" }}>
          <div className="tableContentContainer">
            <StyledDimmerDimmable style={{ zIndex: 1 }} dimmed={props.loading}>
              <Dimmer active={props.loading}>
                <LoadingAnimation
                  loadingText="Loading"
                  loaderSize="42px"
                  loaderColor="#FFFFFF"
                  textColor="#FFFFFF"
                />
              </Dimmer>
              <Table>
                <Table.Header>
                  <Table.HeaderCell textAlign="center">
                    {serialMetadataKey ? `#${serialMetadataKey}` : "Device ID"}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Alert Rule
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Start Time
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    End Time
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Duration
                  </Table.HeaderCell>

                  {props.aggregates?.map((aggregate) => (
                    <Table.HeaderCell
                      style={{ cursor: "unset" }}
                      key={`${aggregate.name}-${aggregate.table}-${aggregate.column}`}
                      textAlign="center"
                    >
                      {aggregate.name}
                    </Table.HeaderCell>
                  ))}
                  <Table.HeaderCell
                    style={{ cursor: "unset" }}
                    textAlign="center"
                  >
                    Dashboard Link
                  </Table.HeaderCell>
                </Table.Header>

                <Table.Body>
                  {displayAlerts.length !== 0 ? (
                    displayAlerts?.map((alert, index) => {
                      const dashboardId = props.alertRules?.find(
                        (rule) => rule?.id === alert?.alert_rule?.id
                      )?.deepdive_dashboard_id;

                      return (
                        <Table.Row key={index}>
                          {alert?.["-serial_metadata"] ? (
                            <Popup
                              inverted
                              hideOnScroll
                              position="top left"
                              content={
                                <>
                                  {alert?.["-serial_metadata"] && (
                                    <p>Device ID: {alert?.device_id}</p>
                                  )}
                                  {alert?.metadata &&
                                  Object.entries(alert.metadata).length > 0
                                    ? Object.entries(alert.metadata).map(
                                        ([key, value]) => {
                                          return (
                                            <p key={key}>
                                              {value ? (
                                                `${key} : ${value as ReactNode}`
                                              ) : (
                                                <></>
                                              )}
                                            </p>
                                          );
                                        }
                                      )
                                    : "No metadata"}
                                </>
                              }
                              trigger={
                                <Table.Cell textAlign="center">
                                  {
                                    Object.values(
                                      alert?.["-serial_metadata"]
                                    )[0] as ReactNode
                                  }
                                </Table.Cell>
                              }
                            />
                          ) : (
                            <Table.Cell textAlign="center">
                              {alert?.device_id}
                            </Table.Cell>
                          )}
                          <Table.Cell textAlign="center">
                            {alert.alert_rule?.name}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {new Date(alert.start_time).toLocaleString()}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {new Date(alert.end_time).toLocaleString()}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {moment
                              .duration(alert.end_time - alert.start_time)
                              .humanize({ ss: -1 })}
                          </Table.Cell>

                          {props.aggregates?.map((aggregate) => {
                            const column = props.tables[aggregate?.table]?.find(
                              (column) => column?.name === aggregate?.column
                            );
                            let aggVal = alert.aggregates[aggregate.name];
                            let value = aggVal;

                            if (
                              DateTimeStreamsFieldsTypes.includes(
                                column?.type as string
                              )
                            ) {
                              if (typeof value === "string") {
                                value = new Date(value + "z").toLocaleString(
                                  "en-GB"
                                );
                              } else if (typeof value === "number") {
                                value = new Date(value).toLocaleString("en-GB");
                              }
                            } else {
                              value = formatValue(value, column?.unit);
                            }
                            return (
                              <Table.Cell key={`${aggregate.name}`}>
                                {value}
                              </Table.Cell>
                            );
                          })}

                          <Table.Cell textAlign="center">
                            {dashboardId ? (
                              <DashboardLinks
                                currentDashboardType={
                                  props.currentDashboardType
                                }
                                dashboardIds={[dashboardId]}
                                allDashboards={props.dashboards}
                                deviceId={String(alert?.device_id)}
                                timeRange={
                                  new AbsoluteTimeRange(
                                    new AbsoluteTimestamp(
                                      new Date(alert.start_time)
                                    ),
                                    new AbsoluteTimestamp(
                                      new Date(alert.end_time)
                                    )
                                  )
                                }
                              />
                            ) : (
                              "--"
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={6 + props.aggregates.length}>
                        <ErrorMessage message="No data" iconSize="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>

              {!props.loading && props.totalPages !== 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "16px",
                    }}
                  >
                    <StyledPagination
                      activePage={props.currentPage}
                      siblingRange={1}
                      totalPages={props.totalPages}
                      onPageChange={props.handlePaginationChange}
                    />

                    <StyledCardSearchPageInput
                      icon="search"
                      placeholder="Jump to page..."
                      name="activePage"
                      min={1}
                      onChange={props.handlePaginationInputChange}
                      onKeyDown={(event) =>
                        props.handlePaginationInputKeyDown(
                          event,
                          props.totalPages
                        )
                      }
                      type="number"
                      value={props.inputPageNumber ? props.inputPageNumber : ""}
                    />
                    <StyledSecondaryDevicePerPageWidget>
                      <MenuItem>Rows per page</MenuItem>
                      <MenuItem style={{ padding: "0px" }}>
                        <SelectDevicesPerPage
                          pointing="bottom"
                          compact
                          selection
                          options={rowsPerPageOptions}
                          value={props.alertsPerPage}
                          onChange={props.changeDevicesPerPage}
                        />
                      </MenuItem>
                    </StyledSecondaryDevicePerPageWidget>
                  </div>
                </div>
              )}
            </StyledDimmerDimmable>
          </div>
        </TableContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

type AlertCardProps = {
  readonly tabName: string;
  readonly totalCount: number;
  readonly background?: string;
  readonly alertsPerPage: number;
  readonly onClick: () => void;
};

const TextContainer = styled.div`
  padding: 8px 0px;
  font-size: 22px;
  margin-left: 25px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function AlertCard(props: AlertCardProps) {
  const numberStyle = {
    fontSize: "50px",
    fontWeight: "bold",
  } as React.CSSProperties;

  return (
    <AlertCardComponent
      backgroundColor={props.background}
      onClick={props.onClick}
    >
      <div style={numberStyle}>{props.totalCount}</div>
      <TextContainer>{props.tabName}</TextContainer>
    </AlertCardComponent>
  );
}

type ViewAlertsState = {
  data?: AlertsData;
  activeTab: string;
  tabList: string[];
  activeIndex: number;
  currentPage: number;
  alertsPerPage: number;
  inputPageNumber: number;
  totalPages: number;
  loading: boolean;
  tableDivOffsetHeight: number;
};

export class ViewAlerts extends PanelViewComponent<
  AlertsMetaData,
  AlertsData,
  ViewAlertsState
> {
  state = {} as ViewAlertsState;
  tableDivOffsetHeight: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "All Alerts",
      activeIndex: 0,
      tabList: [],
      currentPage: 1,
      alertsPerPage: 20,
      inputPageNumber: 0,
      totalPages: 10, // actual value is computed upon data fetch using total data / items per page
      loading: false,
      tableDivOffsetHeight: 0,
    };
    this.tableDivOffsetHeight = React.createRef();
  }

  async refreshData(
    pageNumber: number,
    alertsPerPage: number,
    tabName: string
  ) {
    try {
      this.setState({ loading: true });

      const { tabByAlertGroups, tabList } = this.props.panelMeta;

      const alertArray =
        tabName === "All Alerts"
          ? tabList === "all"
            ? undefined
            : this.state.tabList
          : [tabName];

      const panelMeta: AlertsMetaData = {
        ...this.props.panelMeta,
        page: pageNumber,
        limit: alertsPerPage,
        ...(tabByAlertGroups
          ? { alert_groups: alertArray }
          : { alert_names: alertArray }),
      };
      const response = await fetchPanelData(
        this.props.dashboardId,
        panelMeta.id,
        [panelMeta],
        this.props.fetchParams,
        null,
        null
      );

      this.setState({
        data: response[0].data,
        currentPage: pageNumber,
        totalPages: Math.ceil(
          response[0].data.totalCount / this.state.alertsPerPage
        ),
        loading: false,
      });
    } catch (error) {
      console.error(
        "Error occurred in viewDashboard viewAlerts refreshData.",
        error
      );
    }
  }

  handlePaginationInputChange = (event) => {
    const newValue = event.target.value;
    this.setState({
      inputPageNumber: Number(newValue),
    });
  };

  handlePaginationInputKeyDown = (event, totalPages: number) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(this.state.inputPageNumber.toString())) {
        this.handlePaginationChange(event, {
          activePage:
            this.state.inputPageNumber && this.state.inputPageNumber > 0
              ? this.state.inputPageNumber > totalPages
                ? (totalPages as number)
                : this.state.inputPageNumber
              : 1,
          totalPages: totalPages,
        });
        this.setState({
          inputPageNumber: 0,
        });
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  async handlePaginationChange(
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    if (data.activePage) {
      this.refreshData(
        data.activePage as number,
        this.state.alertsPerPage,
        this.state.activeTab
      );
    }
  }

  changeDevicesPerPage(e, data) {
    try {
      this.setState({
        alertsPerPage: parseInt(data.value),
        currentPage: 1,
      });
      this.refreshData(1, data.value, this.state.activeTab);
    } catch (e) {
      beamtoast.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  }

  handleTabChange = (e: React.MouseEvent, { activeTab }: any) => {
    this.setState({ activeTab });
  };

  fetchAlertTabs(alertRulesData: AlertRule[] = []) {
    let alertRules = alertRulesData ?? [];
    if (this.props.panelMeta.tabByAlertGroups) {
      this.setState({
        tabList: Array.from(new Set(alertRules.map((rule) => rule.group))),
      });
    } else {
      this.setState({
        tabList: [...alertRules.map((rule) => rule.name)],
      });
    }
  }

  groupAlertsByGroupName(data: AlertsData, groupNames: string[]) {
    // Use a Set to keep track of provided group names
    const groupNamesSet = new Set(groupNames);

    // Create a new object to store grouped alerts based on matched alert rules
    const groupedAlerts = {};

    // Initialize all group names with an empty array
    groupNamesSet.forEach((name) => {
      groupedAlerts[name] = [];
    });

    // filter alert rules based on the group names from the tab array
    const filteredAlertRules = data.alertRules.filter((rule) =>
      groupNamesSet.has(rule.group)
    );

    // Iterate over alert rules and group alerts by their group name
    filteredAlertRules.forEach((rule) => {
      const groupName = rule.group;

      // Filter alerts that match the current alert rule ID
      const matchingAlerts = data.alerts.filter(
        (alert) => alert.alert_rule?.id === rule?.id
      );

      // Append the new matchingAlerts to the existing array
      groupedAlerts[groupName] =
        groupedAlerts[groupName].concat(matchingAlerts);
    });

    return groupedAlerts;
  }

  groupAlertsByAlertRuleName(data: AlertsData, ruleNames: string[]) {
    // Use a Set to keep track of provided rule names (case-sensitive)
    const alertRuleNamesSet = new Set(ruleNames);

    // Create a new object to store grouped alerts based on matched alert rule names
    const groupedAlerts = {};

    // Initialize all rule names with an empty array
    ruleNames.forEach((name) => {
      groupedAlerts[name] = [];
    });

    // Filter alert rules based on the provided alert rule names
    const matchedAlertRules =
      data?.alertRules?.filter((rule) => {
        return alertRuleNamesSet.has(rule?.name);
      }) ?? [];

    // Group alerts by the matched alert rule names
    matchedAlertRules.forEach((rule) => {
      const ruleName = rule?.name;
      const matchingAlerts = data?.alerts?.filter(
        (alert) => alert?.alert_rule.id === rule?.id
      );

      // Initialize or append alerts to the ruleName key in groupedAlerts
      if (!groupedAlerts[ruleName]) {
        groupedAlerts[ruleName] = [];
      }

      // Append the new matchingAlerts to the existing array
      groupedAlerts[ruleName] = groupedAlerts[ruleName].concat(matchingAlerts);
    });

    // Level map for sorting based on criticality
    const levelMap = {
      critical: 3,
      warning: 2,
      info: 1,
    };

    // Convert the grouped alerts object into an array and sort based on alert rule criticality
    const sortedGroupedAlerts = Object.entries(groupedAlerts)
      .sort((a, b) => {
        // Find the criticality of the alert rules
        const ruleA = matchedAlertRules.find((rule) => rule.name === a[0]);
        const ruleB = matchedAlertRules.find((rule) => rule.name === b[0]);

        // Handle potential undefined rule cases
        const criticalityA = ruleA ? levelMap[ruleA.criticality] : 0;
        const criticalityB = ruleB ? levelMap[ruleB.criticality] : 0;

        // Compare their criticality using the levelMap
        return criticalityB - criticalityA;
      })
      .reduce((acc, [ruleName, alerts]) => {
        acc[ruleName] = alerts;
        return acc;
      }, {});

    return sortedGroupedAlerts;
  }

  sortTabbedAlerts(
    tabbedAlerts: Record<string, Alert[]>,
    summary: AlertSummary,
    tabByAlertGroups: boolean
  ) {
    if (tabByAlertGroups) {
      // Sort by alert groups: Calculate the sum of counts for each group
      const groupCounts: Record<string, number> = {};

      for (const [group, rules] of Object.entries(summary)) {
        const totalCount = Object.values(rules).reduce(
          (sum, ruleInfo) => sum + ruleInfo.count,
          0
        );
        groupCounts[group] = totalCount;
      }

      // Sort the tabbedAlerts object by group count and then alphabetically if count is zero
      const sortedGroups = Object.fromEntries(
        Object.entries(tabbedAlerts).sort(([groupA], [groupB]) => {
          const countA = groupCounts[groupA] || 0;
          const countB = groupCounts[groupB] || 0;
          if (countA === countB) {
            // If counts are the same (including zero), sort alphabetically
            return groupA.localeCompare(groupB);
          }
          return countB - countA; // descending
        })
      );

      return sortedGroups;
    } else {
      // Sort by alert rules: get count for rules directly from summary
      const ruleCounts: Record<string, number> = {};

      for (const rules of Object.values(summary)) {
        for (const [rule, ruleInfo] of Object.entries(rules)) {
          ruleCounts[rule] = ruleInfo.count;
        }
      }

      // Sort the tabbedAlerts object by rule count and then alphabetically if count is zero
      const sortedRules = Object.fromEntries(
        Object.entries(tabbedAlerts).sort(([ruleA], [ruleB]) => {
          const countA = ruleCounts[ruleA] || 0;
          const countB = ruleCounts[ruleB] || 0;
          if (countA === countB) {
            // If counts are the same (including zero), sort alphabetically
            return ruleA.localeCompare(ruleB);
          }
          return countB - countA; // descending
        })
      );

      return sortedRules;
    }
  }

  areTabListsEqual = (
    prevTabList: string[] | "all",
    newTabList: string[] | "all"
  ) => {
    if (Array.isArray(prevTabList) && Array.isArray(newTabList)) {
      return isEqual(sortBy(prevTabList), sortBy(newTabList));
    }

    return isEqual(prevTabList, newTabList);
  };

  hasAlertsChanged(
    prevAlertRules: AlertRule[],
    currentAlertRules: AlertRule[]
  ) {
    // Check if the length of the arrays has changed
    if (prevAlertRules?.length !== currentAlertRules?.length) {
      return true;
    }

    // Map the names of each alert rule name
    const prevNames = prevAlertRules?.map((alert) => alert?.name);
    const currentNames = currentAlertRules?.map((alert) => alert?.name);

    // Map the names of each alert group
    const prevGroups = new Set(prevAlertRules?.map((alert) => alert?.group));
    const currentGroups = new Set(
      currentAlertRules?.map((alert) => alert?.group)
    );

    // Check if any 'name' field has changed
    return (
      !isEqual(prevNames, currentNames) || !isEqual(prevGroups, currentGroups)
    );
  }

  findGroupName(ruleName: string) {
    const alertRules = this.state.data?.alertRules;
    const rule = alertRules?.find((rule) => rule.name === ruleName);

    return rule?.group || null;
  }

  componentDidMount(): void {
    const data = this.state.data || this.props.data;

    if (
      this.props.panelMeta.tabList === "all" ||
      this.props.panelMeta.tabList === undefined
    )
      this.fetchAlertTabs(data.alertRules);
    else this.setState({ tabList: this.props.panelMeta.tabList });

    this.setState({
      data: this.props.data,
      totalPages: Math.ceil(data.totalCount / this.state.alertsPerPage),
    });

    // Set the initial height of the table container, for determining the height of the rows per page menu
    if (this.tableDivOffsetHeight.current) {
      this.setState({
        tableDivOffsetHeight: this.tableDivOffsetHeight.current.offsetHeight,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { panelMeta, data, timeRange } = this.props;
    const { tabbedView, tabList, tabByAlertGroups } = panelMeta;
    const currentData = this.state.data;

    // Update the height of the table container if it has changed, to determine the height of the rows per page menu.s
    if (
      this.tableDivOffsetHeight.current &&
      this.tableDivOffsetHeight.current.offsetHeight !==
        this.state.tableDivOffsetHeight
    ) {
      this.setState({
        tableDivOffsetHeight: this.tableDivOffsetHeight.current.offsetHeight,
      });
    }

    if (
      this.hasAlertsChanged(
        prevProps.data.alertRules,
        this.props.data.alertRules
      )
    ) {
      this.fetchAlertTabs(data.alertRules);
      this.setState({ currentPage: 1, activeIndex: 0 });
    }

    if (prevProps.timeRange !== timeRange)
      this.setState({ data, currentPage: 1, activeIndex: 0 });

    if (!isEqual(prevProps.data, data)) this.setState({ data });

    // Handle tabbedView change
    if (prevProps.panelMeta.tabbedView !== tabbedView && tabbedView) {
      this.setState({
        activeTab: "All Alerts",
        activeIndex: 0,
        currentPage: 1,
      });
    }

    // Handle tabList or tabByAlertGroups change
    if (
      !this.areTabListsEqual(prevProps.panelMeta.tabList, tabList) ||
      prevProps.panelMeta.tabByAlertGroups !== tabByAlertGroups
    ) {
      this.setState({ data, activeIndex: 0, currentPage: 1 });

      if (tabList === "all") {
        this.fetchAlertTabs(data.alertRules);
      } else {
        this.setState({ tabList });
      }
    }

    // Handle totalCount change in data
    const prevTotalCount = prevProps?.data?.totalCount;
    const currentTotalCount = data.totalCount;
    const prevStateTotalCount = prevState?.data?.totalCount;
    const currentStateTotalCount = this.state?.data?.totalCount;

    if (
      currentData &&
      (prevTotalCount !== currentTotalCount ||
        prevStateTotalCount !== currentStateTotalCount)
    ) {
      this.setState({
        totalPages: Math.ceil(
          currentData.totalCount / this.state.alertsPerPage
        ),
      });
    }
  }

  render() {
    const tabbedView = this.props.panelMeta.tabbedView ?? false;
    const aggregates = this.props.panelMeta.aggregates ?? [];
    const summary = this.props.data.summary ?? {};
    const allAlerts = this.state.data?.alerts;
    const tabByAlertGroups = this.props.panelMeta.tabByAlertGroups;
    const tabList = this.state.tabList;
    const alertRules = this.state.data?.alertRules;

    const tabbedAlerts: Record<string, Alert[]> = this.state.data
      ? tabByAlertGroups
        ? this.groupAlertsByGroupName(this.state.data, this.state.tabList ?? [])
        : this.groupAlertsByAlertRuleName(
            this.state.data,
            this.state.tabList ?? []
          )
      : {};

    const sortedTabbedAlerts = this.sortTabbedAlerts(
      tabbedAlerts,
      summary,
      tabByAlertGroups
    );

    let totalCount = this.props.data.totalCount;

    /** Calculation of total count for all alerts tab:
     * When no tab filter is applied, we simply use the totalCount fetched from response, therefore below logic is skipped
     * If the tab list is not set to "all":
     *  * If filtering by alert groups (`tabByAlertGroups` is true), filter the summary to include only the specified groups and sum their counts.
     *  * If not filtering by alert groups, sum the counts for specific rules within each group that match the tab list.
     */
    if (this.props.panelMeta.tabList !== "all") {
      totalCount = 0;
      if (tabByAlertGroups) {
        const filteredSummary = Object.fromEntries(
          Object.entries(summary).filter(([key]) => tabList?.includes(key))
        );

        Object.keys(filteredSummary).forEach((group) => {
          Object.keys(filteredSummary[group]).forEach((rule) => {
            totalCount += filteredSummary[group][rule].count;
          });
        });
      } else {
        Object.keys(summary).forEach((group) => {
          Object.keys(summary[group]).forEach((rule) => {
            if (tabList?.includes(rule))
              totalCount += summary[group][rule].count;
          });
        });
      }
    }

    const serialMetadataKey =
      allAlerts &&
      allAlerts.length > 0 &&
      allAlerts[0]["-serial_metadata"] &&
      Object.keys(allAlerts[0]["-serial_metadata"]).length > 0 &&
      capitalizeFirstLetter(Object.keys(allAlerts[0]["-serial_metadata"])[0]);

    let tabs = [
      {
        menuItem: (
          <MenuItem
            key="All Alerts"
            onClick={(e, { children }) => {
              let activeTab = (children && children[0]) || "All alerts";
              this.setState({
                activeTab,
                activeIndex: 0,
                currentPage: 1,
              });
              this.refreshData(1, this.state.alertsPerPage, activeTab);
            }}
          >
            All Alerts
            <Label
              style={{
                background: "#65c369",
              }}
            >
              {totalCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <StyledTabPaneNoBorder key="All Alerts" isalertspaneltablesticky>
            <TableContainer style={{ paddingTop: "10px" }}>
              <div className="tableContentContainer">
                <Dimmer.Dimmable
                  style={{ zIndex: 1 }}
                  dimmed={this.state.loading}
                >
                  <Dimmer active={this.state.loading}>
                    <LoadingAnimation
                      loadingText="Loading"
                      loaderSize="42px"
                      loaderColor="#FFFFFF"
                      textColor="#FFFFFF"
                    />
                  </Dimmer>
                  <div ref={this.tableDivOffsetHeight}>
                    <Table
                      style={{ marginTop: "10px" }}
                      sortable={false}
                      compact
                      selectable
                      unstackable
                      size="small"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            {serialMetadataKey
                              ? `#${serialMetadataKey}`
                              : "Device ID"}
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            Rule Name
                          </Table.HeaderCell>
                          {tabByAlertGroups && (
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              Group Name
                            </Table.HeaderCell>
                          )}
                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            Start Time
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            End Time
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            Duration
                          </Table.HeaderCell>

                          {aggregates?.map((aggregate) => (
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              key={`${aggregate.name}-${aggregate.table}-${aggregate.column}`}
                            >
                              {aggregate.name}
                            </Table.HeaderCell>
                          ))}

                          <Table.HeaderCell
                            style={{ cursor: "unset" }}
                            textAlign="center"
                          >
                            Dashboard Link
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      {allAlerts && allAlerts.length !== 0 ? (
                        allAlerts?.map((alert) => {
                          const alertGroupName = this.findGroupName(
                            alert.alert_rule.name
                          );
                          const dashboardId = alertRules?.find(
                            (rule) => rule.id === alert.alert_rule.id
                          )?.deepdive_dashboard_id;

                          return (
                            <Table.Row key={alert.id}>
                              {alert?.["-serial_metadata"] ? (
                                <Popup
                                  inverted
                                  hideOnScroll
                                  position="top left"
                                  content={
                                    <>
                                      {alert?.["-serial_metadata"] && (
                                        <p>Device ID: {alert?.device_id}</p>
                                      )}
                                      {alert?.metadata &&
                                      Object.entries(alert.metadata).length > 0
                                        ? Object.entries(alert.metadata).map(
                                            ([key, value]) => {
                                              return (
                                                <p key={key}>
                                                  {value ? (
                                                    `${key} : ${value as ReactNode}`
                                                  ) : (
                                                    <></>
                                                  )}
                                                </p>
                                              );
                                            }
                                          )
                                        : "No metadata"}
                                    </>
                                  }
                                  trigger={
                                    <Table.Cell textAlign="center">
                                      {
                                        Object.values(
                                          alert?.["-serial_metadata"]
                                        )[0] as ReactNode
                                      }
                                    </Table.Cell>
                                  }
                                />
                              ) : (
                                <Table.Cell textAlign="center">
                                  {alert?.device_id}
                                </Table.Cell>
                              )}
                              <Table.Cell textAlign="center">
                                {alert?.alert_rule?.name}
                              </Table.Cell>
                              {tabByAlertGroups && (
                                <Table.Cell textAlign="center">
                                  {alertGroupName}
                                </Table.Cell>
                              )}
                              <Table.Cell textAlign="center">
                                {new Date(alert.start_time).toLocaleString()}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {new Date(alert.end_time).toLocaleString()}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {moment
                                  .duration(alert.end_time - alert.start_time)
                                  .humanize({ ss: -1 })}
                              </Table.Cell>

                              {aggregates?.map((aggregate) => {
                                const column = this.props.tables[
                                  aggregate?.table
                                ]?.find(
                                  (column) => column?.name === aggregate?.column
                                );
                                let aggVal = alert.aggregates[aggregate.name];
                                let value = aggVal;

                                if (
                                  DateTimeStreamsFieldsTypes.includes(
                                    column?.type as string
                                  )
                                ) {
                                  if (typeof value === "string") {
                                    value = new Date(
                                      value + "z"
                                    ).toLocaleString("en-GB");
                                  } else if (typeof value === "number") {
                                    value = new Date(value).toLocaleString(
                                      "en-GB"
                                    );
                                  }
                                } else {
                                  value = formatValue(value, column?.unit);
                                }
                                return (
                                  <Table.Cell key={`${aggregate.name}`}>
                                    {value}
                                  </Table.Cell>
                                );
                              })}

                              <Table.Cell textAlign="center">
                                {dashboardId ? (
                                  <DashboardLinks
                                    currentDashboardType={
                                      this.props.dashboardMeta.type
                                    }
                                    dashboardIds={[dashboardId]}
                                    allDashboards={this.props.dashboards}
                                    deviceId={String(alert?.device_id)}
                                    timeRange={
                                      new AbsoluteTimeRange(
                                        new AbsoluteTimestamp(
                                          new Date(alert.start_time)
                                        ),
                                        new AbsoluteTimestamp(
                                          new Date(alert.end_time)
                                        )
                                      )
                                    }
                                  />
                                ) : (
                                  "--"
                                )}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row>
                          <Table.Cell colSpan={7 + aggregates.length}>
                            <ErrorMessage message="No data" iconSize="large" />
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table>
                  </div>
                  {!this.state.loading && this.state.totalPages !== 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "16px",
                        }}
                      >
                        <StyledPagination
                          activePage={this.state.currentPage}
                          siblingRange={1}
                          totalPages={this.state.totalPages}
                          onPageChange={this.handlePaginationChange.bind(this)}
                        />

                        <StyledCardSearchPageInput
                          icon="search"
                          placeholder="Jump to page..."
                          name="activePage"
                          min={1}
                          onChange={this.handlePaginationInputChange.bind(this)}
                          onKeyDown={(event) =>
                            this.handlePaginationInputKeyDown(
                              event,
                              this.state.totalPages
                            )
                          }
                          type="number"
                          value={
                            this.state.inputPageNumber
                              ? this.state.inputPageNumber
                              : ""
                          }
                        />
                        <RowsperpageMenu
                          tableContainerHeight={this.state.tableDivOffsetHeight}
                        >
                          <StyledSecondaryDevicePerPageWidget>
                            <MenuItem>Rows per page</MenuItem>
                            <MenuItem style={{ padding: "0px" }}>
                              <SelectDevicesPerPage
                                pointing="bottom"
                                compact
                                selection
                                options={rowsPerPageOptions}
                                value={this.state.alertsPerPage}
                                onChange={this.changeDevicesPerPage.bind(this)}
                              />
                            </MenuItem>
                          </StyledSecondaryDevicePerPageWidget>
                        </RowsperpageMenu>
                      </div>
                    </div>
                  )}
                </Dimmer.Dimmable>
              </div>
            </TableContainer>
          </StyledTabPaneNoBorder>
        ),
      },

      // Add tabs for individual alert rules/groups
      ...Object.keys(sortedTabbedAlerts)?.map((tabName, key) => {
        const alertsForTab: Alert[] = sortedTabbedAlerts[tabName];
        let totalCount = 0;
        let criticality = "";

        if (tabByAlertGroups) {
          const alertGroupSummary = summary[tabName];

          if (alertGroupSummary)
            Object.keys(alertGroupSummary)?.forEach(
              (rule) => (totalCount += alertGroupSummary[rule].count)
            );
        } else {
          Object.keys(summary).flatMap((group) =>
            Object.keys(summary[group]).forEach((rule) => {
              if (rule === tabName) {
                totalCount = summary[group][rule].count;
                criticality = summary[group][rule].criticality;
              }
            })
          );
        }

        const background =
          totalCount !== 0
            ? criticality
              ? colorMap[criticality]
              : colorMap["info"]
            : "";

        /**
         * Retrieves the serial metadata key from the first alert in the alertsForRule array.
         * @returns The serial metadata key, or undefined if no alerts are present or no serial metadata is found.
         */
        const serialMetadataKey =
          alertsForTab.length > 0 &&
          alertsForTab[0]["-serial_metadata"] &&
          Object.keys(alertsForTab[0]["-serial_metadata"]).length > 0 &&
          capitalizeFirstLetter(
            Object.keys(alertsForTab[0]["-serial_metadata"])[0]
          );

        return {
          menuItem: (
            <MenuItem
              key={key}
              onClick={() => {
                const activeTab = tabName;
                this.setState({
                  activeTab,
                  activeIndex: key + 1,
                  currentPage: 1,
                });
                this.refreshData(1, this.state.alertsPerPage, activeTab);
              }}
            >
              {tabName}
              <Label
                style={{
                  background,
                }}
              >
                {totalCount}
              </Label>
            </MenuItem>
          ),
          render: () => (
            <StyledTabPaneNoBorder key={key} isalertspaneltablesticky>
              <TableContainer style={{ paddingTop: "10px" }}>
                <div className="tableContentContainer">
                  <Dimmer.Dimmable
                    style={{ zIndex: 1 }}
                    dimmed={this.state.loading}
                  >
                    <Dimmer active={this.state.loading}>
                      <LoadingAnimation
                        loadingText="Loading"
                        loaderSize="42px"
                        loaderColor="#FFFFFF"
                        textColor="#FFFFFF"
                      />
                    </Dimmer>
                    <div ref={this.tableDivOffsetHeight}>
                      <Table
                        style={{ marginTop: "10px" }}
                        sortable={false}
                        compact
                        selectable
                        unstackable
                        size="small"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              {serialMetadataKey
                                ? `#${serialMetadataKey}`
                                : "Device ID"}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              Alert Rule
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              Start Time
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              End Time
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              Duration
                            </Table.HeaderCell>

                            {aggregates?.map((aggregate) => (
                              <Table.HeaderCell
                                style={{ cursor: "unset" }}
                                key={`${aggregate.name}-${aggregate.table}-${aggregate.column}`}
                              >
                                {aggregate.name}
                              </Table.HeaderCell>
                            ))}

                            <Table.HeaderCell
                              style={{ cursor: "unset" }}
                              textAlign="center"
                            >
                              Dashboard Link
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        {alertsForTab.length !== 0 ? (
                          alertsForTab?.map((alert) => {
                            const dashboardId = alertRules?.find(
                              (rule) => rule.id === alert.alert_rule.id
                            )?.deepdive_dashboard_id;

                            return (
                              <Table.Row key={alert.id}>
                                {alert?.["-serial_metadata"] ? (
                                  <Popup
                                    inverted
                                    hideOnScroll
                                    position="top left"
                                    content={`Device ID: ${alert?.device_id}`}
                                    trigger={
                                      <Table.Cell textAlign="center">
                                        {
                                          Object.values(
                                            alert?.["-serial_metadata"]
                                          )[0] as ReactNode
                                        }
                                      </Table.Cell>
                                    }
                                  />
                                ) : (
                                  <Table.Cell textAlign="center">
                                    {alert?.device_id}
                                  </Table.Cell>
                                )}
                                <Table.Cell textAlign="center">
                                  {alert?.alert_rule?.name}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {new Date(alert.start_time).toLocaleString()}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {new Date(alert.end_time).toLocaleString()}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {moment
                                    .duration(alert.end_time - alert.start_time)
                                    .humanize({ ss: -1 })}
                                </Table.Cell>

                                {aggregates?.map((aggregate) => {
                                  const column = this.props.tables[
                                    aggregate?.table
                                  ]?.find(
                                    (column) =>
                                      column?.name === aggregate?.column
                                  );
                                  let aggVal = alert.aggregates[aggregate.name];
                                  let value = aggVal;

                                  if (
                                    DateTimeStreamsFieldsTypes.includes(
                                      column?.type as string
                                    )
                                  ) {
                                    if (typeof value === "string") {
                                      value = new Date(
                                        value + "z"
                                      ).toLocaleString("en-GB");
                                    } else if (typeof value === "number") {
                                      value = new Date(value).toLocaleString(
                                        "en-GB"
                                      );
                                    }
                                  } else {
                                    value = formatValue(value, column?.unit);
                                  }
                                  return (
                                    <Table.Cell key={`${aggregate.name}`}>
                                      {value}
                                    </Table.Cell>
                                  );
                                })}

                                <Table.Cell textAlign="center">
                                  {dashboardId ? (
                                    <DashboardLinks
                                      currentDashboardType={
                                        this.props.dashboardMeta.type
                                      }
                                      dashboardIds={[dashboardId]}
                                      allDashboards={this.props.dashboards}
                                      deviceId={String(alert?.device_id)}
                                      timeRange={
                                        new AbsoluteTimeRange(
                                          new AbsoluteTimestamp(
                                            new Date(alert.start_time)
                                          ),
                                          new AbsoluteTimestamp(
                                            new Date(alert.end_time)
                                          )
                                        )
                                      }
                                    />
                                  ) : (
                                    "--"
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })
                        ) : (
                          <Table.Row>
                            <Table.Cell colSpan={6 + aggregates.length}>
                              <ErrorMessage
                                message="No data"
                                iconSize="large"
                              />
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table>
                    </div>
                    {!this.state.loading && this.state.totalPages !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: "16px",
                          }}
                        >
                          <StyledPagination
                            activePage={this.state.currentPage}
                            siblingRange={1}
                            totalPages={this.state.totalPages}
                            onPageChange={this.handlePaginationChange.bind(
                              this
                            )}
                          />

                          <StyledCardSearchPageInput
                            icon="search"
                            placeholder="Jump to page..."
                            name="activePage"
                            min={1}
                            onChange={this.handlePaginationInputChange.bind(
                              this
                            )}
                            onKeyDown={(event) =>
                              this.handlePaginationInputKeyDown(
                                event,
                                this.state.totalPages
                              )
                            }
                            type="number"
                            value={
                              this.state.inputPageNumber
                                ? this.state.inputPageNumber
                                : ""
                            }
                          />
                          <RowsperpageMenu
                            tableContainerHeight={
                              this.state.tableDivOffsetHeight
                            }
                          >
                            <StyledSecondaryDevicePerPageWidget>
                              <MenuItem>Rows per page</MenuItem>
                              <MenuItem style={{ padding: "0px" }}>
                                <SelectDevicesPerPage
                                  pointing="bottom"
                                  compact
                                  selection
                                  options={rowsPerPageOptions}
                                  value={this.state.alertsPerPage}
                                  onChange={this.changeDevicesPerPage.bind(
                                    this
                                  )}
                                />
                              </MenuItem>
                            </StyledSecondaryDevicePerPageWidget>
                          </RowsperpageMenu>
                        </div>
                      </div>
                    )}
                  </Dimmer.Dimmable>
                </div>
              </TableContainer>
            </StyledTabPaneNoBorder>
          ),
        };
      }),
    ];

    const containerStyle = {
      marginTop: "50px",
      marginLeft: "20px",
      marginRight: "20px",
      overflowY: "scroll",
      height: "calc(100% - 50px)",
    } as React.CSSProperties;

    if (!tabbedView) {
      return (
        <div style={containerStyle}>
          {Object.keys(sortedTabbedAlerts)?.map((tabName, key) => {
            const alerts: Alert[] = sortedTabbedAlerts[tabName];
            let totalCount = 0;
            let criticality = "";

            if (tabByAlertGroups) {
              const alertGroupSummary = summary[tabName];

              if (alertGroupSummary)
                Object.keys(alertGroupSummary)?.forEach(
                  (rule) => (totalCount += alertGroupSummary[rule].count)
                );
            } else {
              Object.keys(summary).flatMap((group) =>
                Object.keys(summary[group]).forEach((rule) => {
                  if (rule === tabName) {
                    totalCount = summary[group][rule].count;
                    criticality = summary[group][rule].criticality;
                  }
                })
              );
            }

            const background =
              totalCount !== 0
                ? criticality
                  ? colorMap[criticality]
                  : colorMap["info"]
                : "";

            if (alerts) {
              return (
                <AlertListModal
                  tabName={tabName}
                  tables={this.props.tables}
                  alerts={alerts}
                  aggregates={aggregates}
                  key={key}
                  loading={this.state.loading}
                  totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  handlePaginationChange={this.handlePaginationChange.bind(
                    this
                  )}
                  handlePaginationInputChange={this.handlePaginationInputChange.bind(
                    this
                  )}
                  handlePaginationInputKeyDown={this.handlePaginationInputKeyDown.bind(
                    this
                  )}
                  inputPageNumber={this.state.inputPageNumber}
                  alertsPerPage={this.state.alertsPerPage}
                  changeDevicesPerPage={this.changeDevicesPerPage.bind(this)}
                  trigger={
                    <AlertCard
                      tabName={tabName}
                      totalCount={totalCount}
                      background={background}
                      alertsPerPage={this.state.alertsPerPage}
                      onClick={() => {
                        this.setState({
                          activeTab: tabName,
                          currentPage: 1,
                        });
                        this.refreshData(1, this.state.alertsPerPage, tabName);
                      }}
                    />
                  }
                  alertRules={alertRules}
                  dashboards={this.props.dashboards}
                  currentDashboardType={this.props.dashboardMeta.type}
                />
              );
            }

            return <div key={key} />;
          })}
        </div>
      );
    } else {
      return (
        <div style={containerStyle}>
          <StyledSemanticTabs
            panes={tabs}
            activeIndex={this.state.activeIndex}
          />
        </div>
      );
    }
  }
}
