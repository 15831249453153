import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownItemProps, Modal } from "semantic-ui-react";
import { ApiKey, Role } from "../../../../util";
import { StyledInput, StyledLabel } from "../actiontypes/ActionTypesModal";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";

interface CreateOrEditApiKeyModalProps {
  apiKey: { name: string; role: string };
  allApiKeys: ApiKey[];
  type: "edit" | "create";
  onSubmit: (ApiKey) => void;
  trigger: React.ReactNode;
  roles: Role[];
}

export default function CreateOrEditApiKeyModal(
  props: CreateOrEditApiKeyModalProps
) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<DropdownItemProps[]>([]);
  const [apiKey, setApiKey] = useState<{ name: string; role: string }>(
    props.apiKey
  );
  const [allApiNames, setAllApiNames] = useState<Set<string>>(new Set());
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onModalClose = () => {
    setOpen(false);
    if (props.apiKey.name === "" && props.apiKey.role === "") {
      setApiKey({
        name: "",
        role: "",
      });
    }
    setErrorMessage("");
  };

  useEffect(() => {
    setOptions(
      props.roles.map((role, index) => ({
        key: role.id,
        text: role.name,
        value: index,
      }))
    );

    // Set all the api-names in the set.
    if (props.allApiKeys.length > 0) {
      const namesSet = new Set(
        props.allApiKeys.map((user) => user.name?.trim().toLowerCase())
      );
      if (apiKey.name) {
        namesSet.delete(apiKey.name.trim().toLowerCase()); // Remove the current name from the set
      }

      setAllApiNames(namesSet);
    }
  }, [props.roles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (apiKey.role.length > 0 && apiKey.name.length > 0) {
      if (apiKey.name === props.apiKey.name) {
        setButtonDisabled(true);
        return;
      } else {
        setButtonDisabled(false);
      }
    } else {
      setButtonDisabled(true);
    }
  }, [apiKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && props.apiKey.name !== "" && props.apiKey.role !== "") {
      setApiKey(props.apiKey);
    }
  }, [open, props.apiKey]);

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={props.trigger}
    >
      <Modal.Header>
        {props.type === "edit" ? "Edit API Key" : "Create API Key"}
      </Modal.Header>
      <Modal.Description></Modal.Description>
      <Modal.Content>
        <StyledInput labelPosition="left">
          <StyledLabel>Name</StyledLabel>
          <input
            autoFocus
            placeholder="API Key Name"
            value={apiKey.name}
            onChange={(e) => {
              setApiKey({
                ...apiKey,
                name: e.target.value,
              });
            }}
          />
        </StyledInput>
        {errorMessage && (
          <ErrorMessage id={"userEmailErrorSpan"}>{errorMessage}</ErrorMessage>
        )}
        <StyledInput labelPosition="left">
          <StyledLabel>Select Role</StyledLabel>
          <Dropdown
            placeholder="Please select your Role"
            fluid
            selection
            search
            options={options}
            disabled={props.type === "edit"}
            defaultValue={
              props.type === "edit"
                ? props.roles.findIndex((role) => role.name === apiKey.role)
                : undefined
            }
            onChange={(_e, d) => {
              setApiKey({
                ...apiKey,
                role: props.roles[d.value as number]["name"],
              });
            }}
            style={{
              padding: "8px 12px",
              border: "none",
            }}
          />
        </StyledInput>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onModalClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            // Check if the key name already exists.
            if (allApiNames.has(apiKey.name.trim().toLowerCase())) {
              setErrorMessage("Key name already exists.");
            } else {
              onModalClose();
              props.onSubmit(apiKey);
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
