import _ from "lodash";
import React, { useState } from "react";
import {
  Button,
  Modal,
  Icon,
  Table,
  TableHeaderCell,
  Pagination,
  PaginationProps,
  Container,
  Item,
  Dimmer,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";
import {
  FetchStreamsAPIResponse,
  fetchStreamTimeSeries,
  getTenantFromURL,
} from "../../../../../BytebeamClient";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { DateTimeDropdown } from "../../../Dashboards/Datetime/DateTimeDropdown";
import {
  TimeRange,
  RelativeTimeRange,
} from "../../../Dashboards/Datetime/TimeRange";
import LoadingAnimation from "../../../../common/Loader";
import { capitalizeFirstLetter } from "../../../util";
import {
  DateTimeStreamsFieldsTypes,
  formatValue,
} from "../../../Dashboards/Panel/util";
import { useUser } from "../../../../../context/User.context";
import { TimeRangeContainer } from "../../../Dashboards/Panel/TimeseriesTable/ViewTimeseriesTable";
import { StyledButtonWithBorder } from "../Devices";

type ShowDeviceStreamsProps = {
  readonly deviceId: number;
  readonly isOpen: boolean;
  readonly close: (...args: any[]) => any;
  readonly streams: Record<string, string[]>;
  readonly detailedStreamsList: FetchStreamsAPIResponse;
};

const TableContainerStream = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 10px 5px 5px 10px;
`;

const TimeRangeDiv = styled.div`
  display: inline-block;
  margin-right: 10px;
  min-width: 40px;
`;

export const StyledDimmerDimmable = styled(Dimmer.Dimmable)`
  & .ui.active.dimmer > .content {
    background: transparent !important;
  }
`;

const StyledItem = styled(Item)`
  flex: 1 1 auto;
  min-width: 200px;
  padding: 12px 18px;
  border: 1px solid ${(props) => props.theme.colors["stream-table-cell-border"]} !important;
  color: ${(props) => props.theme.colors["link-text-color"]};
  border-radius: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.theme.colors["stream-table-hover-color"]};
  }
`;

// Have to put back to streams page and time-range selector
export function ShowDeviceStreamsModal(props: ShowDeviceStreamsProps) {
  const { deviceId, isOpen, close, streams, detailedStreamsList } = props;
  const { user } = useUser();
  const serialMetadataKey = user?.["tenant-settings"]?.["serial-key"];
  const [showData, setShowData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [streamData, setStreamData] = useState([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [tableName, setTableName] = useState<string | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [timeRange, setTimeRange] = useState<TimeRange>(
    new RelativeTimeRange(15, "minutes")
  );
  const [currentStartTimestamp, setCurrentStartTimestamp] = useState<number>(0);
  const [currentEndTimestamp, setCurrentEndTimestamp] = useState<number>(0);
  const [showFixedTimerange, setShowFixedTimerange] = useState(false);
  const [requestJSON, setRequestJSON] = useState<any>({});

  const getTimeSeriesPanelData = async (key, val, page, timeRange) => {
    setLoading(true);
    setShowFixedTimerange(false);
    val = _.uniq(val);
    const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
    const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());
    setCurrentStartTimestamp(startTime);
    setCurrentEndTimestamp(endTime);
    let requestBody = {
      dashboard_id: "device-streams-modal",
      panel_id: `timeseries_table-${key}-${deviceId}`,
      startTime: startTime,
      endTime: endTime,
      aggregationInterval: 1500,
      filterBys: {
        id: [deviceId.toString()],
      },
      groupBys: [],
      panels: [
        {
          type: "timeseries_table",
          page: page,
          id: `timeseries_table-${key}-${deviceId}`,
          title: key,
          description: key,
          table: key,
          columns: val,
          sortOrder: "desc",
          rowsPerPage: 20,
        },
      ],
      fetchAll: false,
    };
    setRequestJSON(requestBody);
    let res = (await fetchStreamTimeSeries(requestBody)) ?? [];
    setCurrentPage(page);
    setLoading(false);
    setShowData(true);
    if (res[0]?.data?.data) {
      setNumPages(Math.ceil(res[0].data?.totalRows / 20) || 0);
      setStreamData(res[0].data.data);
    }
  };

  const getTimeSeriesPanelDataFixedTimerange = async (
    key,
    val,
    page,
    timeRange
  ) => {
    setLoading(true);
    setShowFixedTimerange(true);
    val = _.uniq(val);
    const startTime = currentStartTimestamp;
    const endTime = currentEndTimestamp;
    let requestBody = {
      dashboard_id: "device-streams-modal",
      panel_id: `timeseries_table-${key}-${deviceId}`,
      startTime: startTime,
      endTime: endTime,
      aggregationInterval: 1500,
      filterBys: {
        id: [deviceId.toString()],
      },
      groupBys: [],
      panels: [
        {
          type: "timeseries_table",
          page: page,
          id: `timeseries_table-${key}-${deviceId}`,
          title: key,
          description: key,
          table: key,
          columns: val,
          sortOrder: "desc",
          rowsPerPage: 20,
        },
      ],
      fetchAll: false,
    };
    setRequestJSON(requestBody);
    let res = (await fetchStreamTimeSeries(requestBody)) ?? [];
    setCurrentPage(page);
    setLoading(false);
    setShowData(true);
    if (res[0]?.data?.data) {
      setNumPages(Math.ceil(res[0].data?.totalRows / 20) || 0);
      setStreamData(res[0].data.data);
    }
  };

  const downloadRequestBody = () => {
    const filterBys = {
      id: [deviceId.toString()],
    };
    const groupBys = [];

    const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
    const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

    const aggregationInterval = Math.max(
      Math.round((endTime - startTime) / 200),
      1
    );

    document.cookie = `x-bytebeam-tenant=${getTenantFromURL()}`;
    return {
      startTime,
      endTime,
      aggregationInterval,
      filterBys: filterBys,
      groupBys: groupBys,
      panel: requestJSON.panels[0],
    };
  };

  const handlePaginationChange = (event, data: PaginationProps) => {
    if (data.activePage) {
      setCurrentPage(data.activePage as number);
      getTimeSeriesPanelDataFixedTimerange(
        tableName,
        columns,
        data.activePage as number,
        timeRange
      );
    }
  };

  const onTimeRangeChange = (timeRange: TimeRange) => {
    setTimeRange(timeRange);
    getTimeSeriesPanelData(tableName, columns, 1, timeRange);
  };

  const renderValue = (column, value) => {
    if (column === "sequence") {
      return value;
    }

    // Retrieve the column type
    const columnMeta =
      detailedStreamsList[tableName as string]?.fields?.[column];
    const columnType = columnMeta?.type;

    // Check if the column is a date or timestamp
    if (DateTimeStreamsFieldsTypes.includes(columnType as string)) {
      // Return formatted date
      if (typeof value === "string") {
        return new Date(value + "z").toLocaleString("en-GB");
      } else if (typeof value === "number") {
        return new Date(value).toLocaleString("en-GB");
      }
    }

    // For other cases, use a separate formatter function
    return formatValue(value, columnMeta?.unit, false, false, 3, true, false);
  };

  return (
    <Modal
      closeOnEscape={true}
      closeOnDimmerClick={false}
      open={isOpen}
      size="large"
      className="dark"
      onClose={close}
    >
      {!showData ? (
        <>
          <Modal.Header>Device Streams</Modal.Header>
          <Modal.Content>
            {loading || Object.entries(streams).length === 0 ? (
              <LoadingAnimation
                loadingText="Loading Streams..."
                loaderBorderSize="4px"
                loaderContainerHeight="250px"
                loaderSize="42px"
                fontSize="14px"
              />
            ) : (
              <Container
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                {Object.entries(streams).map(([key, val]) => {
                  return (
                    <StyledItem
                      key={key}
                      onClick={async () => {
                        // Filtering out the _timestamp columns
                        setColumns(
                          val.filter((field) => !field.endsWith("_timestamp"))
                        );
                        setTableName(key);
                        setLoading(true);
                        getTimeSeriesPanelData(key, val, 1, timeRange);
                      }}
                    >
                      {key}
                    </StyledItem>
                  );
                })}
              </Container>
            )}
          </Modal.Content>
        </>
      ) : (
        <>
          <Modal.Header>{`Stream: ${tableName}`}</Modal.Header>
          <Modal.Content>
            <div className="dashboard-header">
              <div
                style={{
                  display: "flex",
                  alignContent: "flex-start",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <TimeRangeDiv>
                    <DateTimeDropdown
                      showControls={true}
                      timeRange={timeRange}
                      onTimeRangeChange={onTimeRangeChange}
                      disabled={loading}
                    />
                  </TimeRangeDiv>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignContent: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <span className="bytebeam-panel-icon">
                    <form
                      method="post"
                      action={`/api/v1/panel-data-csv?tenant=${getTenantFromURL()}`}
                    >
                      <input
                        type="hidden"
                        name="json"
                        value={JSON.stringify(downloadRequestBody())}
                      />
                      <StyledButtonWithBorder
                        type="submit"
                        style={{ padding: "10px 16px", marginRight: "8px" }}
                      >
                        <Icon name="download" link />
                        Download
                      </StyledButtonWithBorder>
                    </form>
                  </span>
                  <Button
                    secondary
                    disabled={loading}
                    onClick={(e) => {
                      onTimeRangeChange(timeRange);
                      e.stopPropagation();
                    }}
                    style={{
                      marginRight: "0px",
                      borderRadius: "4px",
                    }}
                  >
                    <Icon
                      name="refresh"
                      style={{
                        marginRight: "12px",
                      }}
                      loading={loading}
                    />
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            <TableContainerStream>
              <StyledDimmerDimmable dimmed={loading}>
                <Dimmer active={loading}>
                  <LoadingAnimation
                    loadingText="Loading Streams Data..."
                    loaderSize="42px"
                    loaderColor="#FFFFFF"
                    textColor="#FFFFFF"
                  />
                </Dimmer>
                <Table compact selectable size="small">
                  <Table.Header>
                    <Table.Row>
                      {serialMetadataKey ? (
                        <TableHeaderCell
                          style={{ cursor: "unset" }}
                          textAlign="center"
                        >
                          {`#${capitalizeFirstLetter(serialMetadataKey)}`}
                        </TableHeaderCell>
                      ) : (
                        <TableHeaderCell
                          style={{ cursor: "unset" }}
                          textAlign="center"
                        >
                          Device ID
                        </TableHeaderCell>
                      )}
                      <TableHeaderCell textAlign="center">
                        Timestamp
                      </TableHeaderCell>

                      {columns
                        .filter((column) => column !== "timestamp")
                        .map((column) => (
                          <TableHeaderCell
                            style={{ cursor: "unset" }}
                            key={column}
                            textAlign="center"
                          >
                            {column}
                          </TableHeaderCell>
                        ))}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {streamData.length !== 0 ? (
                      streamData.map((p: any, i) => {
                        return (
                          <Table.Row key={`${p.id}-${p.timestamp}-${i}`}>
                            {/* // If the "-serial_metadata" is present, then show the first key of the "-serial_metadata" as the device id. */}
                            {p?.["-serial_metadata"] ? (
                              <Table.Cell textAlign="center">
                                {
                                  p?.["-serial_metadata"][
                                    Object.keys(p?.["-serial_metadata"])[0]
                                  ]
                                }
                              </Table.Cell>
                            ) : (
                              <Table.Cell textAlign="center">{p.id}</Table.Cell>
                            )}
                            <Table.Cell textAlign="center">
                              {new Date(p.timestamp).toLocaleString("en-GB")}
                            </Table.Cell>
                            {columns
                              .filter((column) => column !== "timestamp")
                              .map((column) => (
                                <Table.Cell key={column} textAlign="center">
                                  {renderValue(column, p[column])}
                                </Table.Cell>
                              ))}
                          </Table.Row>
                        );
                      })
                    ) : (
                      <Table.Row>
                        <Table.Cell
                          colSpan={columns.length + 1}
                          textAlign="center"
                        >
                          <ErrorMessage
                            message={"No data found!"}
                            iconSize="large"
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </StyledDimmerDimmable>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                {numPages > 0 && (
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    siblingRange={2}
                    totalPages={numPages}
                    activePage={currentPage}
                    onPageChange={handlePaginationChange}
                  />
                )}
                {showFixedTimerange &&
                  currentStartTimestamp !== 0 &&
                  currentEndTimestamp !== 0 && (
                    <TimeRangeContainer>
                      Timerange:{" "}
                      {new Date(currentStartTimestamp).toLocaleString("en-GB")}{" "}
                      - {new Date(currentEndTimestamp).toLocaleString("en-GB")}
                      <Popup
                        content="Timerange fixed due to panel interaction. Click to refresh"
                        position="top center"
                        inverted
                        trigger={
                          <Icon
                            name="redo"
                            color="blue"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() =>
                              getTimeSeriesPanelData(
                                tableName,
                                columns,
                                1,
                                timeRange
                              )
                            }
                          />
                        }
                      />
                    </TimeRangeContainer>
                  )}
              </div>
            </TableContainerStream>
          </Modal.Content>
        </>
      )}
      <Modal.Actions>
        {showData ? (
          <Button
            secondary
            onClick={() => {
              setShowData(false);
              setTimeRange(new RelativeTimeRange(15, "minutes"));
            }}
          >
            Back
          </Button>
        ) : (
          ""
        )}
        <Button
          secondary
          onClick={() => {
            setShowData(false);
            close();
          }}
        >
          <Icon name="remove" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
