import React from "react";
import { createGlobalStyle } from "styled-components";
import { Popup } from "semantic-ui-react";

// Global style to apply the z-index override for the popup parent to render it below the modals and dropdowns
const GlobalStyle = createGlobalStyle`
  .popup-with-low-z-index {
    z-index: 10 !important;
  }
`;

type PopUpPosition =
  | "top center"
  | "top left"
  | "top right"
  | "bottom right"
  | "bottom left"
  | "right center"
  | "left center"
  | "bottom center";

interface CustomPopupProps {
  trigger: React.ReactNode;
  content: string;
  open?: boolean;
  id?: string;
  position?: PopUpPosition;
  disabled?: boolean;
}

const PopupWithLowZIndex: React.FC<CustomPopupProps> = ({
  id = "",
  disabled = false,
  trigger,
  content,
  open,
  position,
}) => {
  return (
    <>
      <GlobalStyle />
      <Popup
        id={id}
        inverted
        disabled={disabled}
        position={position ?? "top center"}
        className="popup-with-low-z-index"
        trigger={trigger}
        content={content}
        on="hover"
        open={open}
      />
    </>
  );
};

export default PopupWithLowZIndex;
