import React, { useEffect, useState } from "react";
import styled from "styled-components";

type ToggleProps = {
  size?: "small" | "medium" | "large";
  checked?: boolean;
  bgcolor?: string;
  // Allowing all other props
  [x: string]: any;
  isLocked?: boolean;
  disabled?: boolean;
};

const ToggleWrapper = styled.label.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "size" &&
    prop !== "checked" &&
    prop !== "bgcolor" &&
    prop !== "isLocked" &&
    prop !== "disabled",
})<ToggleProps>`
  position: relative;
  display: inline-block;
  min-width: ${(props) => getSize(props.size)}px;
  width: ${(props) => getSize(props.size)}px;
  height: ${(props) => getSize(props.size) / 2}px;
  background-color: ${(props) =>
    props.checked ? props.bgcolor ?? "#3498DB" : "#CCCCCC"};
  border-radius: ${(props) => getSize(props.size) / 2}px;
  cursor: pointer;
  transition:
    background-color 0.5s,
    box-shadow 0.4s;
  box-shadow:
    0 6px 12px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ToggleSlider = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "size" &&
    prop !== "checked" &&
    prop !== "bgcolor" &&
    prop !== "isLocked" &&
    prop !== "disabled",
})<ToggleProps>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: ${(props) => getSize(props.size) / 2 - 4}px;
  height: ${(props) => getSize(props.size) / 2 - 4}px;
  border-radius: 50%;
  background-color: white;
  transition:
    transform 0.4s,
    transform 0.5s;
  transform: translateX(
    ${(props) => (props.checked ? getSize(props.size) / 2 - 1 : 1)}px
  );
  box-shadow: "0 2px 4px rgba(0, 0, 0, 0.2), 0 6px 12px rgba(0, 0, 0, 0.1)";
`;

const ToggleInput = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

// Helper function to get size based on the variant
function getSize(size: ToggleProps["size"]) {
  switch (size) {
    case "small":
      return 30;
    case "medium":
      return 35;
    case "large":
      return 40;
    default:
      return 30;
  }
}

const Toggle: React.FC<ToggleProps> = ({
  size = "small",
  checked = false,
  bgcolor = "#3498DB",
  isLocked = false,
  disabled = false,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    if (!isLocked) setIsChecked(!isChecked);
  };

  return (
    <ToggleWrapper size={size} checked={isChecked} bgcolor={bgcolor} {...rest}>
      <ToggleInput
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
        disabled={disabled}
      />
      <ToggleSlider size={size} checked={isChecked} />
    </ToggleWrapper>
  );
};

export default Toggle;
