import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Input,
  Table,
} from "semantic-ui-react";
import styled from "styled-components";
import { StreamFieldDetails } from "../../../BytebeamClient";
import {
  CompositeCondition,
  CompositeConditionOperator,
  Condition,
  dropDownOptionsFromArray,
  SimpleCondition,
} from "../../../util";
import { breakpoints } from "../../common/breakpoints";

const commonOperators = [
  { key: "equals", text: "=", value: "=" },
  { key: "notEquals", text: "!=", value: "!=" },
];

const numericOperators = [
  { key: "greaterThan", text: ">", value: ">" },
  { key: "lessThan", text: "<", value: "<" },
];

const conjunctions = [
  { key: "and", text: "AND", value: "and" },
  { key: "or", text: "OR", value: "or" },
];

const ConditionRowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 492px;
  width: 100%;

  & > .ui.fluid.selection.dropdown {
    margin-right: 12px;
    min-width: 0px;
    flex-shrink: 1;
    flex-grow: 1;

    & > .divider.text {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  & > .ui.fluid.selection.dropdown:nth-of-type(2) {
    max-width: 72px;
  }

  & > .ui.input {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    flex-direction: column;
    gap: 8px;

    & > .ui.fluid.selection.dropdown {
      margin-right: 0px;
    }

    & > .ui.fluid.selection.dropdown:nth-of-type(2) {
      max-width: 100%;
    }

    & > .ui.input {
      width: 100%;
    }
  }
`;

const RowOptionsDropdown = styled(Dropdown)`
  &:focus-within {
    border: none !important;
  }

  & > .dropdown.icon::before {
    display: none;
  }
`;

type ConditionRowProps = {
  condition: SimpleCondition;
  index: number;
  handleChangeCondition: (
    index: number,
    field: keyof SimpleCondition,
    value: string
  ) => void;
  fields: { [key: string]: StreamFieldDetails };
};

const ConditionRow = memo((props: ConditionRowProps) => {
  const { condition, index, handleChangeCondition, fields } = props;

  const [field, setField] = useState<string>(condition.field);

  const columnOptions = dropDownOptionsFromArray(Object.keys(fields));

  const getType = (clickhouseType: string) => {
    if (clickhouseType) {
      if (
        clickhouseType.startsWith("Int") ||
        clickhouseType.startsWith("UInt") ||
        clickhouseType.startsWith("Nullable(UInt") ||
        clickhouseType.startsWith("Nullable(Int")
      ) {
        return "int";
      } else if (
        clickhouseType.startsWith("Float") ||
        clickhouseType.startsWith("Double") ||
        clickhouseType.startsWith("Nullable(Float") ||
        clickhouseType.startsWith("Nullable(Double")
      ) {
        return "float";
      } else {
        return "string";
      }
    } else {
      return "string";
    }
  };

  const getOperatorOptions = (clickhouseType: string) => {
    const type = getType(clickhouseType);

    if (type === "float" || type === "int") {
      return [...commonOperators, ...numericOperators];
    } else {
      return commonOperators;
    }
  };

  let operatorOptionsInit: DropdownItemProps[] = [];

  if (field && fields[field]) {
    operatorOptionsInit = getOperatorOptions(fields[field].type);
  }

  const [operatorOptions, setOperatorOptions] =
    useState<DropdownItemProps[]>(operatorOptionsInit);

  const onFieldChange = (_e, { value }: DropdownProps) => {
    const field = value as string;
    const fieldDetails = fields[field];
    const clickhouseType = fieldDetails?.type;

    if (field && clickhouseType) {
      setField(field);
      setOperatorOptions(getOperatorOptions(clickhouseType));

      handleChangeCondition(index, "field", field);
      handleChangeCondition(index, "operator", "=");
      handleChangeCondition(index, "value", "");
    }
  };

  const onValueChange = (e) => {
    const valueString = e.target.value;
    const fieldDetails = fields[field];
    const clickhouseType = fieldDetails.type;

    const type = getType(clickhouseType);

    let value = valueString;

    if (valueString) {
      if (type === "int") {
        value = parseInt(valueString);
      } else if (type === "float") {
        value = parseFloat(valueString);
      }
    }

    handleChangeCondition(index, "value", value);
  };

  const handleValueFieldType = () => {
    if (!condition.field) return "text";

    const fieldDetails = fields[condition.field];
    const clickhouseType = fieldDetails?.type;

    const type = getType(clickhouseType);
    if (type === "int" || type === "float") {
      return "number";
    } else {
      return "text";
    }
  };

  useEffect(() => {
    if (condition.field) {
      setField(condition.field);
    }
  }, [condition.field]);

  useEffect(() => {
    if (field && fields[field]) {
      setOperatorOptions(getOperatorOptions(fields[field].type));
    }
  }, [field, fields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConditionRowContainer>
      <Dropdown
        placeholder="Field"
        fluid
        search
        selection
        options={columnOptions}
        value={condition.field}
        onChange={onFieldChange}
      />
      <Dropdown
        fluid
        selection
        options={operatorOptions}
        value={condition.operator}
        disabled={!condition.field}
        onChange={(_e, { value }) =>
          handleChangeCondition(index, "operator", value as string)
        }
      />

      <Input
        type={handleValueFieldType()}
        placeholder="Value"
        value={condition.value}
        disabled={!condition.field}
        onChange={onValueChange}
      />
    </ConditionRowContainer>
  );
});

type SqlWhereClauseBuilderProps = {
  condition?: Condition;
  onChange: (newCondition: Condition) => void;
  fields: { [key: string]: StreamFieldDetails };
  showGroupOptions?: boolean;
};

const isCompositeCondition = (
  condition: Condition
): condition is CompositeCondition => {
  return ["and", "or"].includes(condition.operator);
};

const toCompositeCondition = (condition: Condition): CompositeCondition => {
  return { conditions: [condition], operator: "and" };
};

export const defaultCondition: CompositeCondition = {
  conditions: [{ field: "", operator: "=", value: "" }],
  operator: "and",
};

const SqlWhereClauseBuilder = memo((props: SqlWhereClauseBuilderProps) => {
  const condition = props.condition ?? defaultCondition;

  const compositeCondition = isCompositeCondition(condition)
    ? condition
    : toCompositeCondition(condition);

  const [conditions, setConditions] = useState<Condition[]>(
    compositeCondition.conditions
  );
  const [operator, setOperator] = useState<CompositeConditionOperator>(
    compositeCondition.operator
  );
  const { onChange, fields, showGroupOptions = true } = props;

  const getCondition = (conditions: Condition[], operator: string) => {
    if (conditions.length === 1) {
      return conditions[0];
    } else {
      return { conditions, operator } as CompositeCondition;
    }
  };

  const handleAddCondition = () => {
    const newConditions: Condition[] = [
      ...conditions,
      { field: "", operator: "=", value: "" },
    ];
    setConditions(newConditions);

    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const handleAddConditionGroup = () => {
    const newConditions = [...conditions, defaultCondition];
    setConditions(newConditions);

    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const handleChangeCondition = useCallback(
    (index: number, field: keyof SimpleCondition, value: string) => {
      const newConditions = [...conditions];
      if (
        typeof newConditions[index] === "object" &&
        !Array.isArray(newConditions[index])
      ) {
        newConditions[index][field] = value;
      }

      setConditions(newConditions);

      if (onChange) {
        onChange(getCondition(newConditions, operator));
      }
    },
    [conditions, operator] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleChangeConditionGroup = (
    index: number,
    newConditionGroup: Condition
  ) => {
    const newConditions = [...conditions];
    newConditions[index] = newConditionGroup;
    setConditions(newConditions);
    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const handleRemoveCondition = (index: number) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const handleDuplicateCondition = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 0, { ...conditions[index] });
    setConditions(newConditions);
    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const handleTurnIntoGroup = (index: number) => {
    const newConditions = [...conditions];
    if (!("conditions" in newConditions[index])) {
      newConditions[index] = {
        conditions: [newConditions[index]],
        operator: "and",
      };
    }
    setConditions(newConditions);
    if (onChange) {
      onChange(getCondition(newConditions, operator));
    }
  };

  const renderLabel = (index) => {
    if (conditions.length === 1 || index === 0) {
      return (
        <div>
          <b>WHERE</b>
        </div>
      );
    }

    if (index === 1) {
      return (
        <Dropdown
          style={{
            maxWidth: "156px",
          }}
          fluid
          selection
          options={conjunctions}
          value={operator}
          onChange={(e, { value }) =>
            setOperator(value as CompositeConditionOperator)
          }
        />
      );
    }

    return (
      <label>
        <b>{operator.toUpperCase()}</b>
      </label>
    );
  };

  const renderRowOptionsDropdown = (index, condition) => {
    if (conditions.length === 1) {
      return <></>;
    }

    return (
      <RowOptionsDropdown icon="ellipsis horizontal">
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleRemoveCondition(index)}>
            Remove
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDuplicateCondition(index)}>
            Duplicate
          </Dropdown.Item>
          {"conditions" in condition || !showGroupOptions ? null : (
            <Dropdown.Item onClick={() => handleTurnIntoGroup(index)}>
              Turn into Group
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </RowOptionsDropdown>
    );
  };

  const renderCondition = (index, condition) => {
    if ("conditions" in condition) {
      return (
        <SqlWhereClauseBuilder
          condition={condition}
          onChange={(newConditionGroup) =>
            handleChangeConditionGroup(index, newConditionGroup)
          }
          fields={fields}
          showGroupOptions={showGroupOptions}
        />
      );
    } else {
      return (
        <ConditionRow
          condition={condition}
          index={index}
          handleChangeCondition={handleChangeCondition}
          fields={fields}
        />
      );
    }
  };

  const containerStyle = {
    border: "1px solid rgba(200, 200, 200, 0.55)",
    borderRadius: "5px",
    padding: "15px",
    marginBottom: "15px",
    width: "max-content",
    minWidth: "100%",
  };

  useEffect(() => {
    const condition = props.condition ?? defaultCondition;
    const compositeCondition = isCompositeCondition(condition)
      ? (props.condition as CompositeCondition)
      : toCompositeCondition(condition);

    setConditions(compositeCondition?.conditions);
    setOperator(compositeCondition?.operator);
  }, [props.condition, fields]);

  return (
    <div style={containerStyle}>
      <Table style={{ border: "none" }} compact>
        <Table.Body>
          {conditions.map((condition, index) => (
            <Table.Row key={index} verticalAlign="middle">
              <Table.Cell
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {renderLabel(index)}
              </Table.Cell>

              <Table.Cell>{renderCondition(index, condition)}</Table.Cell>

              <Table.Cell>
                {renderRowOptionsDropdown(index, condition)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Dropdown
        id="filterRuleDropdown"
        style={{ border: "none" }}
        text="Add Filter Rule"
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleAddCondition}>
            Add Filter Rule
          </Dropdown.Item>
          {showGroupOptions ? (
            <Dropdown.Item onClick={handleAddConditionGroup}>
              Add Filter Group
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
});

export default SqlWhereClauseBuilder;
