import * as React from "react";
import { SVGProps } from "react";

const HistogramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 101 94" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_269_113)">
      <path
        d="M99.197 0H1.80301C0.806816 0 0 0.812279 0 1.81521V67.1828C0 68.1857 0.806816 68.998 1.80301 68.998H99.195C100.191 68.998 100.998 68.1857 100.998 67.1828V1.81521C100.998 0.812279 100.191 0 99.195 0H99.197ZM97.394 65.3676H3.60799V3.63043H97.394V65.3676Z"
        fill="currentColor"
      />
      <path
        d="M14.428 21.7412C13.4318 21.7412 12.625 22.5535 12.625 23.5564V58.1031C12.625 59.106 13.4318 59.9183 14.428 59.9183C15.4242 59.9183 16.231 59.106 16.231 58.1031V23.5584C16.231 22.5555 15.4242 21.7432 14.428 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M21.6419 21.7412C20.6457 21.7412 19.8389 22.5535 19.8389 23.5564V58.1031C19.8389 59.106 20.6457 59.9183 21.6419 59.9183C22.6381 59.9183 23.4449 59.106 23.4449 58.1031V23.5584C23.4449 22.5555 22.6381 21.7432 21.6419 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M28.8577 10.8955C27.8615 10.8955 27.0547 11.7078 27.0547 12.7107V58.105C27.0547 59.1079 27.8615 59.9202 28.8577 59.9202C29.8539 59.9202 30.6607 59.1079 30.6607 58.105V12.7107C30.6607 11.7078 29.8539 10.8955 28.8577 10.8955Z"
        fill="currentColor"
      />
      <path
        d="M36.0716 21.7412C35.0754 21.7412 34.2686 22.5535 34.2686 23.5564V58.1031C34.2686 59.106 35.0754 59.9183 36.0716 59.9183C37.0678 59.9183 37.8746 59.106 37.8746 58.1031V23.5584C37.8746 22.5555 37.0678 21.7432 36.0716 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M43.2864 10.8955C42.2902 10.8955 41.4834 11.7078 41.4834 12.7107V58.105C41.4834 59.1079 42.2902 59.9202 43.2864 59.9202C44.2826 59.9202 45.0894 59.1079 45.0894 58.105V12.7107C45.0894 11.7078 44.2826 10.8955 43.2864 10.8955Z"
        fill="currentColor"
      />
      <path
        d="M50.5003 21.7412C49.5041 21.7412 48.6973 22.5535 48.6973 23.5564V58.1031C48.6973 59.106 49.5041 59.9183 50.5003 59.9183C51.4965 59.9183 52.3033 59.106 52.3033 58.1031V23.5584C52.3033 22.5555 51.4965 21.7432 50.5003 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M57.7141 27.2363C56.718 27.2363 55.9111 28.0486 55.9111 29.0515V58.1049C55.9111 59.1078 56.718 59.9201 57.7141 59.9201C58.7103 59.9201 59.5171 59.1078 59.5171 58.1049V29.0515C59.5171 28.0486 58.7103 27.2363 57.7141 27.2363Z"
        fill="currentColor"
      />
      <path
        d="M64.928 21.7412C63.9318 21.7412 63.125 22.5535 63.125 23.5564V58.1031C63.125 59.106 63.9318 59.9183 64.928 59.9183C65.9242 59.9183 66.731 59.106 66.731 58.1031V23.5584C66.731 22.5555 65.9242 21.7432 64.928 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M72.1419 12.7109C71.1457 12.7109 70.3389 13.5232 70.3389 14.5262V58.1052C70.3389 59.1081 71.1457 59.9204 72.1419 59.9204C73.1381 59.9204 73.9449 59.1081 73.9449 58.1052V14.5262C73.9449 13.5232 73.1381 12.7109 72.1419 12.7109Z"
        fill="currentColor"
      />
      <path
        d="M79.3577 21.7412C78.3615 21.7412 77.5547 22.5535 77.5547 23.5564V58.1031C77.5547 59.106 78.3615 59.9183 79.3577 59.9183C80.3539 59.9183 81.1607 59.106 81.1607 58.1031V23.5584C81.1607 22.5555 80.3539 21.7432 79.3577 21.7432V21.7412Z"
        fill="currentColor"
      />
      <path
        d="M86.5716 27.2363C85.5754 27.2363 84.7686 28.0486 84.7686 29.0515V58.1049C84.7686 59.1078 85.5754 59.9201 86.5716 59.9201C87.5678 59.9201 88.3746 59.1078 88.3746 58.1049V29.0515C88.3746 28.0486 87.5678 27.2363 86.5716 27.2363Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M94.8839 84.8839C95.372 84.3957 95.372 83.6043 94.8839 83.1161L86.9289 75.1612C86.4408 74.673 85.6493 74.673 85.1612 75.1612C84.673 75.6493 84.673 76.4408 85.1612 76.9289L92.2322 84L85.1612 91.0711C84.673 91.5592 84.673 92.3507 85.1612 92.8388C85.6493 93.327 86.4408 93.327 86.9289 92.8388L94.8839 84.8839ZM6 85.25L94 85.25L94 82.75L6 82.75L6 85.25Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_269_113">
        <rect width="101" height="69" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default HistogramIcon;
