import React, { useState } from "react";
import { Modal, Button, Input, Label, Icon } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { createDevice } from "../../../BytebeamClient";
import { Mixpanel } from "../common/MixPanel";
import { beamtoast } from "../../common/CustomToast";
import { useUser } from "../../../context/User.context";
import LoadingAnimation from "../../common/Loader";
import { AxiosError } from "axios";

type DeviceProvisionModalProps = {
  readonly keys: string[];
  readonly loading: boolean;
  readonly onConfirm: () => void;
};

const DeviceProvisionModal = (props: DeviceProvisionModalProps) => {
  const { user } = useUser();
  const [isDeviceProvisioning, setIsDeviceProvisioning] = useState(false);
  const [open, setOpen] = useState(false);
  const [dedup, setDedup] = useState(true);
  const [metadata, setMetadata] = useState({});
  const serialMetadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  // Filter out null and undefined values, cause `set` includes them.
  const metadataKeys = [
    ...new Set(
      [serialMetadata, ...props.keys].filter(
        (item) => item !== null && item !== undefined && item !== ""
      )
    ),
  ];

  const submitData = async () => {
    if (
      serialMetadata &&
      ((metadata[serialMetadata] && metadata[serialMetadata].length === 0) ||
        !metadata[serialMetadata])
    ) {
      beamtoast.error(`'${serialMetadata}' serial metadata cannot be empty!`);
    } else {
      try {
        setIsDeviceProvisioning(true);
        const res = await createDevice(metadata, dedup);
        if (res?.data?.device_id) {
          beamtoast.success("Device created successfully!");

          Mixpanel.track("Created Device", {
            deviceID: res.data.device_id,
          });

          const file = new File(
            [JSON.stringify(res.data, null, 2)],
            `device_${res.data.device_id}.json`,
            { type: "application/json" }
          );
          saveAs(file);
          props.onConfirm();
          setMetadata({});
          setOpen(false);
        } else {
          beamtoast.error("Device creation failed!");
          Mixpanel.track("Failure", {
            type: "Device creation",
            error: "no backend error",
            response: JSON.stringify(res),
          });
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.data?.message) {
          const errorMessage = axiosError.response.data.message;
          // Check if the error message is due to serial-key value not being unique.
          if (errorMessage.includes("key value should be unique")) {
            beamtoast.error(
              `Please provide a unique value for ' ${serialMetadata}' metadata: ${metadata[serialMetadata]} already exists!`
            );
          } else {
            beamtoast.error(errorMessage);
          }
        } else {
          beamtoast.error("Device creation failed!");
        }
        Mixpanel.track("Failure", {
          type: "Device creation",
          error: JSON.stringify(error),
        });
        console.log(error);
      } finally {
        setIsDeviceProvisioning(false);
      }
    }
  };

  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
    paddingBottom: "10px",
    alignItems: "center",
  } as React.CSSProperties;

  return (
    <Modal
      open={open}
      closeOnEscape={true}
      onClose={() => {
        setMetadata({});
        setOpen(false);
      }}
      className="dark"
      size="tiny"
      trigger={
        <Button
          primary
          floated="right"
          labelPosition="left"
          icon
          onClick={() => setOpen(true)}
          style={{ whiteSpace: "nowrap" }}
        >
          <Icon name="plus" />
          Create Device
        </Button>
      }
    >
      <Modal.Header>Create Device</Modal.Header>
      <Modal.Content>
        {props.loading ? (
          <LoadingAnimation
            loaderSize="36px"
            loaderContainerHeight="200px"
            fontSize="1.25rem"
            loadingText="Loading device metadata..."
          />
        ) : (
          <form id="deviceProvisionForm">
            {metadataKeys?.map((key, i) => (
              <div style={rowStyles} key={key}>
                <Input
                  autoFocus={i === 0}
                  labelPosition="left"
                  style={{
                    width: "92%",
                  }}
                >
                  <Label
                    style={{
                      margin: "0px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      paddingRight: "1.5rem !important",
                      maxWidth: "42%",
                    }}
                  >
                    {serialMetadata && serialMetadata === key
                      ? `${key} *`
                      : key}
                  </Label>
                  <input
                    type="text"
                    value={metadata[key] || ""}
                    onChange={(event) => {
                      setMetadata({ ...metadata, [key]: event.target.value });
                    }}
                  />
                </Input>
              </div>
            ))}
            {metadataKeys?.length > 0 ? (
              <div key="dedup" style={rowStyles}>
                <Input labelPosition="left">
                  <Label
                    style={{
                      margin: "0px",
                    }}
                  >
                    Do not create duplicate device
                  </Label>
                  <input
                    style={{
                      height: "22px",
                      width: "22px",
                      margin: "7px",
                    }}
                    type="checkbox"
                    onChange={(event) => setDedup(event.currentTarget.checked)}
                    checked={dedup}
                  />
                </Input>
              </div>
            ) : (
              <div>
                <h2>Are you sure, you want to create new device?</h2>
                <p>
                  Click on <b>Submit</b> to create new device or click{" "}
                  <b>Cancel</b> to close.
                </p>
              </div>
            )}
          </form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setMetadata({});
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          primary
          disabled={isDeviceProvisioning}
          loading={isDeviceProvisioning}
          onClick={() => {
            submitData();
          }}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeviceProvisionModal;
