import React, { Fragment, ReactNode, useState } from "react";
import {
  Table,
  Dimmer,
  Loader,
  Label,
  MenuItem,
  Popup,
  PaginationProps,
  Icon,
  Button,
} from "semantic-ui-react";
import {
  StyledSemanticTabs,
  StyledTabPaneNoBorder,
  TableContainer,
} from "../../Dashboards/Panel/util";
import {
  Alert,
  AlertsData,
  AlertSummary,
} from "../../Dashboards/Panel/Alerts/PanelDef";
import { capitalizeFirstLetter } from "../../util";
import { AlertRule, validateWholeNumber } from "../../../../util";
import { AbsoluteTimestamp } from "../../Dashboards/Datetime/Timestamp";
import {
  AbsoluteTimeRange,
  TimeRange,
} from "../../Dashboards/Datetime/TimeRange";
import { DashboardsInfo } from "../../Dashboards/ViewDashboard";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import { colorMap } from "../../Dashboards/Panel/Alerts/ViewAlerts";
import { beamtoast } from "../../../common/CustomToast";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
import { devicesPerPageOptions } from "../../../../BytebeamClient";
import moment from "moment";
import styled from "styled-components";

export const StyledDashboardLink = styled(Button)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: ${(props) => props.theme.colors["link-text-color"]} !important;
  margin-right: 0px !important;
  padding: 8px 12px !important;
  white-space: nowrap !important;
  font-size: 12px !important;
  margin-right: 16px !important;

  .icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 6px !important;
  }
  &:hover {
    opacity: 0.8 !important;
  }
`;

interface AlertAggregate {
  name: string;
  table: string;
  column: string;
}

interface AlertListProps {
  readonly alerts: Alert[];
  readonly alertRules: AlertRule[];
  readonly aggregates: AlertAggregate[];
  readonly loading: boolean;
  readonly activePage: number;
  readonly setActivePage: (page: number) => void;
  readonly totalPages: number;
  readonly dashboards: DashboardsInfo[];
  readonly handlePageChange: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => void;
  readonly alertsPerPage: number;
  readonly setAlertsPerPage: (perPage: number) => void;
}

type AlertsToDashboardLinksProps = {
  readonly dashboardIds: string[];
  readonly allDashboards: DashboardsInfo[];
  readonly timeRange?: TimeRange;
  readonly deviceId?: string;
};

function AlertsToDashboardLinks(props: AlertsToDashboardLinksProps) {
  return (
    <div className="dashboard-links">
      {props.dashboardIds.map((id) => {
        const dashboard = props.allDashboards.find(
          (dashboard) => String(dashboard.id) === id
        );
        const dashboardURL = `${window.location.href.split("/alerts")[0]}/dashboards/${id}`;
        const url = new URL(dashboardURL);
        if (props.deviceId) {
          url.searchParams.set("id", props.deviceId);
        }
        if (props.timeRange) {
          const timeRange = JSON.stringify(props.timeRange.serialize());
          url.searchParams.set("timeRange", timeRange);
        }

        if (dashboard) {
          return (
            <StyledDashboardLink
              key={id}
              as="a"
              href={url.toString()}
              target="_blank"
              icon="external"
              secondary
            >
              <Icon name="external" />
              <span>{`${dashboard?.title} (${dashboard?.type})`}</span>
            </StyledDashboardLink>
          );
        } else {
          return <Fragment key="0"></Fragment>;
        }
      })}
    </div>
  );
}

const TableView: React.FC<AlertListProps> = ({
  alerts,
  alertRules,
  aggregates,
  loading,
  activePage,
  setActivePage,
  totalPages,
  dashboards,
  handlePageChange,
  alertsPerPage,
  setAlertsPerPage,
}) => {
  const [inputPageNumber, setInputPageNumber] = useState<string | number>("");
  const serialMetadataKey =
    alerts.length > 0 &&
    alerts[0]["-serial_metadata"] &&
    Object.keys(alerts[0]["-serial_metadata"]).length > 0 &&
    capitalizeFirstLetter(Object.keys(alerts[0]["-serial_metadata"])[0]);

  const handlePaginationInputChange = async (event) => {
    const newValue = event.target.value;
    setInputPageNumber(newValue);
  };

  const handlePaginationInputKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber.toString())) {
        let pageToGo = Number(inputPageNumber);
        if (pageToGo <= 0) {
          pageToGo = 1;
        } else if (pageToGo > totalPages) {
          pageToGo = totalPages;
        }
        handlePageChange(event, {
          activePage: pageToGo,
          totalPages,
        });

        // Clear the input field by setting activePage to empty string
        setInputPageNumber("");
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  const changeAlertsPerPage = async (event, data) => {
    try {
      setAlertsPerPage(data.value);
      setActivePage(1);
      window.localStorage.setItem("alertsListPerPage", data.value);
    } catch (error) {
      beamtoast.error("Failed to change alerts per page");
      console.error(error);
    }
  };

  return (
    <div>
      <Dimmer active={loading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <Table fixed size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={2}>
              {serialMetadataKey ? `#${serialMetadataKey}` : "Device ID"}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Rule Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Start Time
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              End Time
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Duration
            </Table.HeaderCell>
            {aggregates.map((agg) => (
              <Table.HeaderCell key={agg.name}>{agg.name}</Table.HeaderCell>
            ))}
            <Table.HeaderCell textAlign="center" width={3}>
              Dashboard Link
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {alerts.length > 0 ? (
            alerts.map((alert) => {
              const dashboardId = alertRules?.find(
                (rule) => rule?.id === alert?.alert_rule?.id
              )?.deepdive_dashboard_id;

              return (
                <Table.Row key={alert.id}>
                  {alert?.["-serial_metadata"] ? (
                    <Popup
                      inverted
                      hideOnScroll
                      position="top left"
                      content={
                        <>
                          {alert?.["-serial_metadata"] && (
                            <p>Device ID: {alert?.device_id}</p>
                          )}
                          {alert?.metadata &&
                          Object.entries(alert.metadata).length > 0
                            ? Object.entries(alert.metadata).map(
                                ([key, value]) => {
                                  return (
                                    <p key={key}>
                                      {value ? `${key} : ${value}` : <></>}
                                    </p>
                                  );
                                }
                              )
                            : "No metadata"}
                        </>
                      }
                      trigger={
                        <Table.Cell textAlign="center">
                          {
                            Object.values(
                              alert?.["-serial_metadata"]
                            )[0] as ReactNode
                          }
                        </Table.Cell>
                      }
                    />
                  ) : (
                    <Table.Cell textAlign="center">
                      {alert?.device_id}
                    </Table.Cell>
                  )}
                  <Table.Cell textAlign="center">
                    {" "}
                    {alert.alert_rule.name}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {new Date(alert.start_time).toLocaleString()}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {new Date(alert.end_time).toLocaleString()}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment
                      .duration(alert.end_time - alert.start_time)
                      .humanize({ ss: -1 })}
                  </Table.Cell>
                  {aggregates.map((agg) => (
                    <Table.Cell key={agg.name} textAlign="center">
                      {alert.aggregates[agg.name] || "N/A"}
                    </Table.Cell>
                  ))}
                  <Table.Cell textAlign="center">
                    {dashboardId ? (
                      <AlertsToDashboardLinks
                        dashboardIds={[dashboardId]}
                        allDashboards={dashboards}
                        deviceId={String(alert?.device_id)}
                        timeRange={
                          new AbsoluteTimeRange(
                            new AbsoluteTimestamp(new Date(alert.start_time)),
                            new AbsoluteTimestamp(new Date(alert.end_time))
                          )
                        }
                      />
                    ) : (
                      "--"
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={6 + aggregates.length} textAlign="center">
                No Alerts data available
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {/* Only render pagination if there are alerts */}
      {alerts.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "16px",
            marginTop: "32px",
          }}
        >
          <StyledPagination
            boundaryRange={0}
            ellipsisItem={null}
            siblingRange={1}
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <StyledCardSearchPageInput
            icon="search"
            placeholder="Jump to page..."
            name="activePage"
            min={1}
            onChange={handlePaginationInputChange}
            onKeyDown={handlePaginationInputKeyDown}
            type="number"
            value={inputPageNumber}
          />

          <StyledSecondaryDevicePerPageWidget>
            <MenuItem>Alerts per page</MenuItem>
            <MenuItem style={{ padding: "0px" }}>
              <SelectDevicesPerPage
                compact
                selection
                upward
                options={devicesPerPageOptions}
                value={alertsPerPage}
                onChange={changeAlertsPerPage}
              />
            </MenuItem>
          </StyledSecondaryDevicePerPageWidget>
        </div>
      )}
    </div>
  );
};

type AlertsComponentProps = {
  readonly data: AlertsData;
  readonly alertGroups: string[];
  readonly aggregates: AlertAggregate[];
  readonly loading: boolean;
  readonly totalPages: number;
  readonly dashboards: DashboardsInfo[];
  readonly handlePageChange: (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => void;
  readonly activePage: number;
  readonly setActivePage: (page: number) => void;
  readonly activeTab: string;
  readonly setActiveTab: (tab: string) => void;
  readonly groupByAlertGroups: boolean;
  readonly alertsPerPage: number;
  readonly setAlertsPerPage: (perPage: number) => void;
  readonly selectedFilters?: string[];
};

const AlertsComponent: React.FC<AlertsComponentProps> = ({
  data,
  alertGroups,
  aggregates,
  loading,
  totalPages,
  dashboards,
  handlePageChange,
  activePage,
  setActivePage,
  activeTab,
  setActiveTab,
  groupByAlertGroups,
  alertsPerPage,
  setAlertsPerPage,
  selectedFilters = [],
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const containerStyle = {
    overflowY: "scroll",
    marginTop: "10px",
  } as React.CSSProperties;

  const alerts = data?.alerts ?? [];
  const alertRules = data?.alertRules ?? [];
  let allAlertsTotalCount = Object.entries(data.summary).reduce(
    (sum, [, rules]) =>
      sum +
      Object.values(rules).reduce((sum, ruleInfo) => sum + ruleInfo.count, 0),
    0
  );

  const groupAlertsByGroupName = () => {
    // Use a Set to keep track of provided group names
    const groupNamesSet = new Set(alertGroups);

    // Create a new object to store grouped alerts based on matched alert rules
    const groupedAlerts = {};

    // Initialize all group names with an empty array
    groupNamesSet.forEach((name) => {
      groupedAlerts[name] = [];
    });

    // filter alert rules based on the group names from the tab array
    const filteredAlertRules = data.alertRules.filter((rule) =>
      groupNamesSet.has(rule.group)
    );

    // Iterate over alert rules and group alerts by their group name
    filteredAlertRules.forEach((rule) => {
      const groupName = rule.group;

      // Filter alerts that match the current alert rule ID
      const matchingAlerts = data.alerts.filter(
        (alert) => alert.alert_rule?.id === rule?.id
      );

      // Append the new matchingAlerts to the existing array
      groupedAlerts[groupName] =
        groupedAlerts[groupName].concat(matchingAlerts);
    });

    return groupedAlerts;
  };

  const groupAlertsByAlertRuleName = () => {
    const ruleNames = alertRules.map((rule) => rule.name);
    // Use a Set to keep track of provided rule names (case-sensitive)
    const alertRuleNamesSet = new Set(ruleNames);

    // Create a new object to store grouped alerts based on matched alert rule names
    const groupedAlerts = {};

    // Initialize all rule names with an empty array
    ruleNames.forEach((name) => {
      groupedAlerts[name] = [];
    });

    // Filter alert rules based on the provided alert rule names
    const matchedAlertRules =
      data?.alertRules?.filter((rule) => {
        return alertRuleNamesSet.has(rule?.name);
      }) ?? [];

    // Group alerts by the matched alert rule names
    matchedAlertRules.forEach((rule) => {
      const ruleName = rule?.name;
      const matchingAlerts = data?.alerts?.filter(
        (alert) => alert?.alert_rule.id === rule?.id
      );

      // Initialize or append alerts to the ruleName key in groupedAlerts
      if (!groupedAlerts[ruleName]) {
        groupedAlerts[ruleName] = [];
      }

      // Append the new matchingAlerts to the existing array
      groupedAlerts[ruleName] = groupedAlerts[ruleName].concat(matchingAlerts);
    });

    // Level map for sorting based on criticality
    const levelMap = {
      critical: 3,
      warning: 2,
      info: 1,
    };

    // Convert the grouped alerts object into an array and sort based on alert rule criticality
    const sortedGroupedAlerts = Object.entries(groupedAlerts)
      .sort((a, b) => {
        // Find the criticality of the alert rules
        const ruleA = matchedAlertRules.find((rule) => rule.name === a[0]);
        const ruleB = matchedAlertRules.find((rule) => rule.name === b[0]);

        // Handle potential undefined rule cases
        const criticalityA = ruleA ? levelMap[ruleA.criticality] : 0;
        const criticalityB = ruleB ? levelMap[ruleB.criticality] : 0;

        // Compare their criticality using the levelMap
        return criticalityB - criticalityA;
      })
      .reduce((acc, [ruleName, alerts]) => {
        acc[ruleName] = alerts;
        return acc;
      }, {});

    return sortedGroupedAlerts;
  };

  const sortTabbedAlerts = (tabbedAlerts: Record<string, Alert[]>) => {
    const summary: AlertSummary = data.summary;
    if (groupByAlertGroups) {
      // Sort by alert groups: Calculate the sum of counts for each group
      const groupCounts: Record<string, number> = {};

      for (const [group, rules] of Object.entries(summary)) {
        const totalCount = Object.values(rules).reduce(
          (sum, ruleInfo) => sum + ruleInfo.count,
          0
        );
        groupCounts[group] = totalCount;
      }

      // Sort the tabbedAlerts object by group count and then alphabetically if count is zero
      const sortedGroups = Object.fromEntries(
        Object.entries(tabbedAlerts).sort(([groupA], [groupB]) => {
          const countA = groupCounts[groupA] || 0;
          const countB = groupCounts[groupB] || 0;
          if (countA === countB) {
            // If counts are the same (including zero), sort alphabetically
            return groupA.localeCompare(groupB);
          }
          return countB - countA; // descending
        })
      );

      return sortedGroups;
    } else {
      // Sort by alert rules: get count for rules directly from summary
      const ruleCounts: Record<string, number> = {};

      for (const rules of Object.values(summary)) {
        for (const [rule, ruleInfo] of Object.entries(rules)) {
          ruleCounts[rule] = ruleInfo.count;
        }
      }

      // Sort the tabbedAlerts object by rule count and then alphabetically if count is zero
      const sortedRules = Object.fromEntries(
        Object.entries(tabbedAlerts).sort(([ruleA], [ruleB]) => {
          const countA = ruleCounts[ruleA] || 0;
          const countB = ruleCounts[ruleB] || 0;
          if (countA === countB) {
            // If counts are the same (including zero), sort alphabetically
            return ruleA.localeCompare(ruleB);
          }
          return countB - countA; // descending
        })
      );

      return sortedRules;
    }
  };

  let tabbedAlerts: Record<string, Alert[]> = {};

  if (data) {
    tabbedAlerts = groupByAlertGroups
      ? groupAlertsByGroupName()
      : groupAlertsByAlertRuleName();
  }

  const sortedTabbedAlerts = sortTabbedAlerts(tabbedAlerts);

  const tabs = [
    {
      menuItem: (
        <MenuItem
          active={activeTab === "All Alerts"}
          key="All Alerts"
          onClick={(e, { children }) => {
            let activeTab = (children && children[0]) || "All alerts";
            setActiveTab(activeTab);
            setActiveIndex(0);
            setActivePage(1);
          }}
        >
          All Alerts
          <Label
            style={{
              background: "#65c369",
            }}
          >
            {allAlertsTotalCount}
          </Label>
        </MenuItem>
      ),
      render: () => (
        <StyledTabPaneNoBorder key="All Alerts" style={{ padding: "0px" }}>
          <TableContainer style={{ padding: "0px" }}>
            <div className="tableContentContainer" style={{ padding: "0px" }}>
              <TableView
                alerts={alerts}
                alertRules={data?.alertRules}
                aggregates={aggregates}
                loading={loading}
                activePage={activePage}
                setActivePage={setActivePage}
                totalPages={totalPages}
                dashboards={dashboards}
                handlePageChange={handlePageChange}
                alertsPerPage={alertsPerPage}
                setAlertsPerPage={setAlertsPerPage}
              />
            </div>
          </TableContainer>
        </StyledTabPaneNoBorder>
      ),
    },

    // Tabs for individual alert rules/groups
    ...Object.keys(sortedTabbedAlerts ?? {}).map((tabName, key) => {
      const summary: AlertSummary = data.summary;
      let totalCount = 0;
      let criticality = "";

      if (groupByAlertGroups) {
        const alertGroupSummary = summary[tabName];

        if (alertGroupSummary)
          Object.keys(alertGroupSummary)?.forEach(
            (rule) => (totalCount += alertGroupSummary[rule].count)
          );
      } else {
        Object.keys(summary).flatMap((group) =>
          Object.keys(summary[group]).forEach((rule) => {
            if (rule === tabName) {
              totalCount = summary[group][rule].count;
              criticality = summary[group][rule].criticality;
            }
          })
        );
      }

      let background = "";
      if (totalCount !== 0) {
        background = criticality ? colorMap[criticality] : colorMap["info"];
      }

      return {
        menuItem: (
          <MenuItem
            key={tabName}
            active={activeTab === tabName}
            onClick={() => {
              const activeTab = tabName;
              setActiveTab(activeTab);
              setActiveIndex(key + 1);
              setActivePage(1);
            }}
          >
            {tabName}
            <Label
              style={{
                background,
              }}
            >
              {totalCount}
            </Label>
          </MenuItem>
        ),
        render: () => (
          <StyledTabPaneNoBorder key="All Alerts" style={{ padding: "0px" }}>
            <TableContainer style={{ padding: "0px" }}>
              <div className="tableContentContainer" style={{ padding: "0px" }}>
                <TableView
                  alerts={sortedTabbedAlerts[tabName]}
                  alertRules={data?.alertRules}
                  aggregates={aggregates}
                  loading={loading}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  totalPages={totalPages}
                  dashboards={dashboards}
                  handlePageChange={handlePageChange}
                  alertsPerPage={alertsPerPage}
                  setAlertsPerPage={setAlertsPerPage}
                />
              </div>
            </TableContainer>
          </StyledTabPaneNoBorder>
        ),
      };
    }),
  ];

  return (
    <div>
      <div style={containerStyle}>
        <StyledSemanticTabs
          panes={tabs}
          activeIndex={activeIndex}
          background_color={"transparent"}
          style={{ maxWidth: "80vw" }}
        />
      </div>
    </div>
  );
};

export default AlertsComponent;
