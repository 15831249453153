import React from "react";
import styled, { keyframes } from "styled-components";

interface AnimatedEllipsisProps {
  spacing?: number;
  dotSize?: string;
  dotColor?: string;
}

const jump = keyframes`
  0% {
    transform: translateY(4px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(4px);
  }
`;

const Dot = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "spacing" && prop !== "dotSize" && prop !== "dotColor",
})<{
  spacing: number;
  dotSize: string;
  dotColor?: string;
}>`
  display: inline-block;
  animation: ${jump} 1s infinite;
  margin-left: ${({ spacing }) => spacing}px;
  font-size: ${({ dotSize }) => dotSize};
  color: ${(props) =>
    props.dotColor ? props.dotColor : props.theme.colors["foreground-color"]};

  &:nth-child(2) {
    animation-delay: 0.25s;
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
  }
`;

function AnimatedEllipsis(props: AnimatedEllipsisProps) {
  const { spacing = 4, dotSize = "inherit", dotColor } = props;

  return (
    <span>
      <Dot spacing={spacing} dotSize={dotSize} dotColor={dotColor}>
        &#8226;
      </Dot>
      <Dot spacing={spacing} dotSize={dotSize} dotColor={dotColor}>
        &#8226;
      </Dot>
      <Dot spacing={spacing} dotSize={dotSize} dotColor={dotColor}>
        &#8226;
      </Dot>
    </span>
  );
}

export default AnimatedEllipsis;
