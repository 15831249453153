import React from "react";
import { TimeRange } from "./Datetime/TimeRange";
import { DateTimeDropdown } from "./Datetime/DateTimeDropdown";
import {
  Dropdown,
  Icon,
  Button,
  DropdownProps,
  Popup,
  Label,
} from "semantic-ui-react";
import { Device } from "../../../BytebeamClient";
import { DashboardMeta, DashboardType } from "./EditDashboardModal";
import { capitalizeFirstLetter } from "../util";
import { refreshIntervalOptions } from "./util";
import styled from "styled-components";
import { DashboardFilters } from "./DashboardFilters";
import { DashboardsInfo } from "./ViewDashboard";
import { DashboardLinks } from "./DashboardLinks";
import { breakpoints } from "../../common/breakpoints";
import { Prompt, RouteComponentProps, withRouter } from "react-router-dom";
import SlicedTextPopUp from "../DeviceManagement/Devices/SlicedTextPopUp";

export enum ReplayState {
  ReplayStopped,
  ReplayPaused,
  ReplayRunning,
  ReplayFetching,
}

const DisabledLabels = styled(Label)`
  font-size: 14px !important;
  margin: 0px 8px 4px 0px !important;
  padding: 11px 21px 11px 21px !important;
  background-color: ${({ theme }) => theme.colors["refreshtime-bg"]} !important;
  border-radius: 8px !important;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  cursor: text !important;
`;

const DashboardHeaderContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "componentwidth" && prop !== "beingcompared",
})<{
  componentwidth: number;
  beingcompared: boolean;
}>`
  ${({ componentwidth, beingcompared }) =>
    componentwidth <= breakpoints.md &&
    beingcompared &&
    `
      padding-right: 12px !important;

      .dashboard-header-row-1 {
        flex-wrap: wrap !important;

        .header-left {
          width: 100% !important;

          .dashboard-title {
            display: inline-block !important;
            max-width: 60% !important;
          }
        }

        .header-right {
          flex-wrap: wrap !important;
          gap: 12px !important;
          margin-top: 12px !important;
        }   
      }
    `}
`;

const SaveButton = styled(Button).attrs((props) => ({
  id: props.id,
}))`
  border-radius: 2px !important;
  margin: 0px 8px 0px 8px !important;
  cursor: pointer !important;
`;

/*
type ReplayIconsProps = {
  replayState: ReplayState;
  replayTimestamp: number;
  onReplayStart: () => void;
  onReplayStop: () => void;
  onReplayPause: () => void;
  onReplayResume: () => void;
  onReplayForward: () => void;
  onReplayRewind: () => void;
}

function ReplayHeader(props: ReplayIconsProps) {
  const replayState = props.replayState;

  if(replayState === ReplayState.ReplayStopped) {
    return <span className="dashboard-header-icon">
      <Icon onClick={props.onReplayStart} link name="play" />
    </span>
  }

  return <span>
    <span className="dashboard-header-icon">
      <Icon onClick={props.onReplayRewind} link name="backward" />
    </span>

    <span className="dashboard-header-icon">
      {
        replayState === ReplayState.ReplayRunning ? 
          <Icon onClick={props.onReplayPause} link name="pause" />
        :
          <Icon onClick={props.onReplayResume} link name="play" />
      }
    </span>

    <span className="dashboard-header-icon">
      <Icon onClick={props.onReplayStop} link name="stop" />
    </span>

    <span className="dashboard-header-icon">
      <Icon onClick={props.onReplayForward} link name="forward" />
    </span>

    <span className="dashboard-header-timestamp">
      {moment(props.replayTimestamp).format("DD-MM-YY HH:mm:ss")}
    </span>
  </span>
}
*/

type DashboardHeaderState = {
  screenWidth: number;
  showDeviceFilter: boolean;
  navigationLocation: string;
};

interface DashboardHeaderProps extends RouteComponentProps {
  dashboardMeta: DashboardMeta;
  device: Device;
  timeRange: TimeRange;
  refreshing: boolean;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  onAddPanel: () => void;
  handleCompareClick?: () => void;
  comparisonVisible?: boolean;
  filterKeys: string[];
  selectedFilters: { [key: string]: string[] };
  onFilterSelected: (filterName: string, filterValue: string[]) => void;
  isDirty: boolean;
  showWarningModal: boolean;
  updateWarningModal: (arg0: boolean) => void;
  replayEnabled: boolean;
  replayState: ReplayState;
  replayTimestamp: number;
  onDashboardSave: () => void;
  onDashboardEdit: () => void;
  onDashboardHistory: () => void;
  onRefreshIntervalChange: (interval: number) => void;
  currentRefreshInterval: number | undefined;
  onRefresh: () => void;
  onReplayStart: () => void;
  onReplayStop: () => void;
  onReplayPause: () => void;
  onReplayResume: () => void;
  onReplayForward: () => void;
  onReplayRewind: () => void;
  editable: boolean;
  onDeviceSearch: () => void;
  allDashboards: DashboardsInfo[];
  beingcompared?: boolean;
}

export class DashboardHeader extends React.Component<
  DashboardHeaderProps,
  DashboardHeaderState
> {
  state = {
    screenWidth: window.self.innerWidth,
    showDeviceFilter: false,
    navigationLocation: "",
  };

  componentRef = React.createRef<HTMLDivElement>();

  onRefreshIntervalChange(_event: any, data: DropdownProps) {
    this.props.onRefreshIntervalChange(data.value as number);
  }

  // Event handler for beforeunload event
  handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (this.props.isDirty && !this.props.comparisonVisible) {
      // Prevent the default browser behavior
      e.preventDefault();

      // Show the custom confirmation modal
      // this.setState({ showWarningModal: true });

      // Chrome requires returnValue to be set
      e.returnValue = "";
    }
  };

  // Function to handle navigation
  handleNavigation = (location: any) => {
    // this prop (location) contains the path which the user is trying to currently go to
    // Check if there are unsaved changes
    if (this.props.isDirty) {
      // check only if search queries are changed in the same route pathname and allow routing without prompting the warning modal
      if (
        window.location.pathname === location.pathname &&
        location.search &&
        window.location.search !== location.search
      ) {
        // allow routing
        return true;
      }
      // Display a confirmation dialog if whole route pathname is being changed aka user is trying to move out from the current page entirely without saving their changes
      else if (window.location.pathname !== location.pathname) {
        this.setState({ navigationLocation: location.pathname }, () =>
          // this.props.updateWarningModal(true)
          this.letUserNavigate()
        );
      }

      return false;
    }

    // Continue with navigation if there are no unsaved changes
    return true;
  };

  closeWarningModal = () => {
    this.props.updateWarningModal(false);
  };

  letUserNavigate = () => {
    window.location.href = this.state.navigationLocation;
  };

  handleToggleClick = () => {
    this.setState({
      showDeviceFilter: !this.state.showDeviceFilter,
    });
  };

  updateDimensions = () => {
    if (this.componentRef.current && this.props.comparisonVisible) {
      const width = this.componentRef.current.offsetWidth;

      this.setState({ screenWidth: width });
    } else {
      this.setState({ screenWidth: window.self.innerWidth });
    }
  };

  componentDidMount() {
    this.updateDimensions();

    if (this.props.comparisonVisible) {
      this.handleToggleClick();
    }

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const refreshIcon = (
      <Button
        disabled={this.props.refreshing}
        onClick={(e) => {
          this.props.onRefresh();
          e.stopPropagation();
        }}
      >
        <Icon
          name="refresh"
          style={{
            marginRight: "12px",
          }}
          loading={this.props.refreshing}
        />
        Refresh
      </Button>
    );

    return (
      <DashboardHeaderContainer
        componentwidth={this.state.screenWidth}
        beingcompared={this.props.comparisonVisible ?? false}
        className="dashboard-header"
        ref={this.componentRef}
      >
        {/* custom modal for prompting if dashboard has unsaved changes */}
        {/* {this.props.showWarningModal && (
          <Modal
            className="dark"
            open={this.props.showWarningModal}
            size="tiny"
            onClose={() => this.closeWarningModal()}
          >
            <Modal.Header> Save Dashboard ? </Modal.Header>
            <Modal.Content>
              <div style={{ fontSize: "15px", marginBottom: "8px" }}>
                Are you sure you want to leave the dashboard without saving your
                changes ?
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary onClick={() => this.closeWarningModal()}>
                No
              </Button>
              <Button primary onClick={() => this.letUserNavigate()}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        */}

        <Prompt when={this.props.isDirty} message={this.handleNavigation} />

        <div className="dashboard-header-row-1">
          <div className="header-left">
            <span className="dashboard-title">
              {this.props.dashboardMeta?.title ?? ""}
            </span>

            {this.props.isDirty &&
            this.props.editable &&
            !this.props.beingcompared ? (
              <span className="dashboard-header-icon-save attention-grabbing-button">
                <SaveButton
                  id="save-button"
                  primary
                  icon
                  onClick={this.props.onDashboardSave}
                >
                  <Icon link name="save" />
                </SaveButton>
              </span>
            ) : (
              ""
            )}

            {this.state.screenWidth <= breakpoints.sm && (
              <Button
                floated="right"
                icon
                onClick={() => this.handleToggleClick()}
              >
                <Icon link name="filter" />
              </Button>
            )}

            {/* {
              this.props.replayEnabled ?
                <ReplayHeader 
                  replayState={this.props.replayState}
                  replayTimestamp={this.props.replayTimestamp}
                  onReplayStart={this.props.onReplayStart}
                  onReplayStop={this.props.onReplayStop}
                  onReplayPause={this.props.onReplayPause}
                  onReplayResume={this.props.onReplayResume}
                  onReplayForward={this.props.onReplayForward}
                  onReplayRewind={this.props.onReplayRewind}
                />
                : ""
            } */}
          </div>

          {(this.state.screenWidth > breakpoints.sm ||
            this.state.showDeviceFilter) && (
            <div className="header-right" style={{ flexWrap: "wrap" }}>
              {this.props.dashboardMeta?.showTimeRange ? (
                <span className="dashboard-header-dropdown">
                  <DateTimeDropdown
                    showControls={true}
                    timeRange={this.props.timeRange}
                    onTimeRangeChange={this.props.onTimeRangeChange}
                    disabled={
                      this.props.replayState !== ReplayState.ReplayStopped
                    }
                  />
                </span>
              ) : (
                <></>
              )}

              {this.props.dashboardMeta?.showRefreshInterval ? (
                <span className="dashboard-header-dropdown">
                  <Button.Group>
                    {refreshIcon}
                    <Popup
                      inverted
                      position="top right"
                      content="Refresh Interval"
                      style={{ marginBottom: "6px" }}
                      trigger={
                        <Dropdown
                          options={refreshIntervalOptions}
                          button
                          value={
                            this.props.currentRefreshInterval ||
                            (this.props.dashboardMeta?.refreshInterval ?? 30)
                          }
                          onChange={this.onRefreshIntervalChange.bind(this)}
                          disabled={
                            this.props.replayState !== ReplayState.ReplayStopped
                          }
                          style={{
                            border: "none",
                            padding: "12px",
                            width: "84px",
                          }}
                        />
                      }
                    />
                  </Button.Group>
                </span>
              ) : (
                <></>
              )}

              {!this.props.comparisonVisible ? (
                <span className="add-panel-button">
                  <Button
                    style={{
                      height: "38px",
                      marginRight: "10px",
                      whiteSpace: "nowrap",
                    }}
                    icon="history"
                    content={"Dashboard History"}
                    onClick={this.props.onDashboardHistory}
                  />
                </span>
              ) : null}

              {this.state.screenWidth > breakpoints.sm &&
              this.props.handleCompareClick &&
              !this.props.comparisonVisible ? (
                <span className="add-panel-button">
                  <Button
                    style={{
                      height: "38px",
                      marginRight: "10px",
                      whiteSpace: "nowrap",
                    }}
                    icon="columns"
                    content={
                      this.props.comparisonVisible
                        ? "Cancel Compare"
                        : "Compare"
                    }
                    onClick={this.props.handleCompareClick}
                    disabled={this.props.comparisonVisible}
                  />
                </span>
              ) : null}

              {this.props.editable && !this.props.comparisonVisible ? (
                <span className="add-panel-button">
                  <Button
                    style={{
                      height: "38px",
                      marginRight: "10px",
                      whiteSpace: "nowrap",
                    }}
                    icon="add"
                    content="Panel"
                    onClick={this.props.onAddPanel}
                    disabled={
                      this.props.replayState !== ReplayState.ReplayStopped
                    }
                  />
                </span>
              ) : (
                ""
              )}

              {this.props.editable && !this.props.comparisonVisible ? (
                <Button
                  icon
                  onClick={this.props.onDashboardEdit}
                  style={{ height: "38px", width: "38px" }}
                >
                  <Icon link name="setting" />
                </Button>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {(this.state.screenWidth > breakpoints.sm ||
          this.state.showDeviceFilter) &&
        this.props.dashboardMeta?.type === DashboardType.FleetDashboard ? (
          <div className="dashboard-header-row-2">
            {this.props.filterKeys.length > 0 ? (
              <DashboardFilters
                filterKeys={this.props.filterKeys}
                selectedFilters={this.props.selectedFilters}
                disabled={this.props.replayState !== ReplayState.ReplayStopped}
                onFilterSelected={this.props.onFilterSelected}
              />
            ) : (
              <></>
            )}

            <DashboardLinks
              currentDashboardType={this.props.dashboardMeta.type}
              dashboardIds={this.props.dashboardMeta.dashboardLinks || []}
              allDashboards={this.props.allDashboards}
            />
          </div>
        ) : (
          <></>
        )}
        {(this.state.screenWidth > breakpoints.sm ||
          this.state.showDeviceFilter) &&
        this.props.dashboardMeta?.type === DashboardType.DeviceDashboard ? (
          <div className="dashboard-header-row-2">
            <div className="dashboard-header-row-2-left">
              <DisabledLabels>
                ID: {this.props.selectedFilters.id}
              </DisabledLabels>
              {(this.props.dashboardMeta?.showMetadataKeys ?? []).map((key) => {
                return (
                  <DisabledLabels key={key}>
                    {capitalizeFirstLetter(key)}:{" "}
                    <SlicedTextPopUp
                      showBorder
                      text={this.props.device?.metadata[key]}
                      length={20}
                      emptyText
                    />
                  </DisabledLabels>
                );
              })}

              <Button
                style={{ marginLeft: "4px" }}
                primary
                onClick={() => {
                  this.props.onDeviceSearch();
                }}
              >
                <Icon
                  name="search"
                  style={{ marginRight: "0px", color: "white" }}
                />
              </Button>
            </div>

            <DashboardLinks
              currentDashboardType={this.props.dashboardMeta.type}
              dashboardIds={this.props.dashboardMeta.dashboardLinks ?? []}
              allDashboards={this.props.allDashboards}
            />
          </div>
        ) : (
          <></>
        )}
      </DashboardHeaderContainer>
    );
  }
}

export default withRouter(DashboardHeader);
