import React, { useState } from "react";
import ConfirmationModal from "../../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../../common/ConfirmationModalMessage";
import { beamtoast } from "../../../../common/CustomToast";
import EditFirmwareBundleModal from "./EditFirmwareBundleModal";
import {
  deleteFirmwareBundleById,
  downloadComponentFirmware,
  downloadFirmwareBundleById,
  FirmwareBundle,
  FirmwareInBundle,
  FirmwareType,
} from "../../../../../BytebeamClient";
import { Accordion, Button, Grid, Icon, Popup, Table } from "semantic-ui-react";
import {
  ButtonIcon,
  DisplayIf,
  getHumanReadableFileSizeString,
} from "../../../util";
import { useUser } from "../../../../../context/User.context";
import BackupIcon from "../../../../../assets/svg/BackupIcon";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import CreateFirmwareBundleModal from "./CreateFirmwareBundleModal";
import BrowserUpdatedIcon from "../../../../../assets/svg/BrowserUpdatedIcon";
import DisplayDependencies from "./DisplayDependencies";
import TextWithToolTip from "../../../DeviceManagement/Devices/TextWithToolTip";

type FirmwareBundlesProps = {
  readonly firmwareBundles: FirmwareBundle[];
  readonly fillFirmwareBundlesTable: () => void;
  readonly componentsList: string[];
  readonly firmwareVersions: FirmwareType[];
  readonly loadingComponentList: boolean;
};

export function FirmwareBundles(props: FirmwareBundlesProps) {
  const {
    firmwareBundles,
    fillFirmwareBundlesTable,
    componentsList,
    firmwareVersions,
    loadingComponentList,
  } = props;

  const { user } = useUser();
  const permissions = user?.role?.permissions || {};

  const [firmwareBundle, setFirmwareBundle] = useState<FirmwareBundle>(
    {} as FirmwareBundle
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const [createFirmwareModalIsOpen, setCreateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [editFirmwareBundleModalIsOpen, setEditFirmwareBundleModalIsOpen] =
    useState<boolean>(false);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  async function downloadFile(data: Blob, fileName: string) {
    try {
      const element = document.createElement("a");
      const file = new Blob([data], {
        type: "text/plain",
      });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      setTimeout(() => element.click());
    } catch (error) {
      console.log("Error while creating file: ", error);
    }
  }

  async function downloadFirmwareBundle(bundle: FirmwareBundle) {
    try {
      const res = await downloadFirmwareBundleById(bundle.id);
      const blob: Blob = await res?.blob();
      downloadFile(
        blob,
        `firmware-bundle-${bundle.version}-${bundle.file_name}.zip`
      );
    } catch (error) {
      console.log("Error while downloading firmware: ", error);
    }
  }

  async function downloadFirmwareByVersion(firmware: FirmwareInBundle) {
    const version = firmware.version_number,
      file = firmware.file_name,
      component = firmware.component_name;
    try {
      const res = await downloadComponentFirmware(component, version);
      const blob: Blob = await res?.blob();
      downloadFile(blob, `firmware-${version}-${file}`);
    } catch (error) {
      console.log("Error while downloading firmware: ", error);
    }
  }

  const handleDelete = async (bundle_id: string) => {
    try {
      const res = await deleteFirmwareBundleById(bundle_id);
      if (res) {
        beamtoast.success("Firmware Bundle Deleted Successfully");
        fillFirmwareBundlesTable();
      }
    } catch (error) {
      beamtoast.error("Error Deleting Firmware Bundle");
    }
  };

  // Sorting the firmwareBundles based on created_at before mapping
  firmwareBundles.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <Grid>
      <CreateFirmwareBundleModal
        isOpen={createFirmwareModalIsOpen}
        close={() => setCreateFirmwareModalIsOpen(false)}
        loadingComponentList={loadingComponentList}
        componentsList={componentsList}
        firmwareVersions={firmwareVersions.filter(
          (version) => !version.is_deactivated
        )}
        fillFirmwareBundlesTable={fillFirmwareBundlesTable}
        existingBundleVersions={
          new Set(firmwareBundles.map((bundle) => bundle.version))
        }
      />
      <EditFirmwareBundleModal
        isOpen={editFirmwareBundleModalIsOpen}
        close={() => setEditFirmwareBundleModalIsOpen(false)}
        firmwareBundle={firmwareBundle}
        fillFirmwareBundlesTable={fillFirmwareBundlesTable}
      />
      <Grid.Row>
        <Grid.Column width="6" floated="right" style={{ padding: "0px" }}>
          <DisplayIf cond={permissions.editFirmwares}>
            <Button
              id="upload_firmware_button"
              primary
              floated="right"
              onClick={() => {
                setCreateFirmwareModalIsOpen(true);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                whiteSpace: "nowrap",
              }}
            >
              <BackupIcon height="16px" altColor="#fff" />
              Create Firmware Bundle
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ padding: "0px" }}>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center" width={2}>
                  Version
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  File Size
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Raw Size</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={2}>
                  Upload Status
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Created At
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={2}>
                  Created By
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Checksum</Table.HeaderCell>
                <DisplayIf cond={permissions.editFirmwares}>
                  <Table.HeaderCell textAlign="center" width={2}>
                    Options
                  </Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {firmwareBundles?.length !== 0 ? (
                firmwareBundles.map((bundle, index) => (
                  <>
                    <Table.Row
                      key={bundle.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAccordionClick(index);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Table.Cell textAlign="center">
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "8px",
                          }}
                        >
                          <Icon
                            name={
                              index === activeIndex
                                ? "angle down"
                                : "angle right"
                            }
                          />
                          <TextWithToolTip
                            text={bundle.version}
                            textList={[`Bundle ID: ${bundle.id}`]}
                          />
                        </div>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.content_length
                          ? getHumanReadableFileSizeString(
                              bundle.content_length
                            )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.uncompressed_size
                          ? getHumanReadableFileSizeString(
                              bundle.uncompressed_size
                            )
                          : "--"}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.upload_status}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {new Date(bundle.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <TextWithToolTip text={bundle.created_by} />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bundle.checksum ? (
                          <TextWithToolTip text={bundle.checksum} />
                        ) : (
                          "N/A"
                        )}
                      </Table.Cell>
                      <DisplayIf cond={permissions.editFirmwares}>
                        <Table.Cell textAlign="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "18px",
                            }}
                          >
                            <Popup
                              trigger={
                                <ButtonIcon
                                  name="edit"
                                  disabled={
                                    bundle.upload_status !== "completed"
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    setFirmwareBundle(bundle);
                                    setEditFirmwareBundleModalIsOpen(true);
                                  }}
                                  style={{
                                    marginBottom: "0.7rem",
                                    marginRight: "0",
                                    cursor:
                                      bundle.upload_status !== "completed"
                                        ? "default"
                                        : "pointer",
                                  }}
                                />
                              }
                              content={
                                bundle.upload_status !== "completed"
                                  ? "Firmware Bundle is not uploaded completely"
                                  : "Edit Firmware Bundle"
                              }
                              position="top center"
                              inverted
                            />
                            <Popup
                              trigger={
                                <BrowserUpdatedIcon
                                  height="14px"
                                  id={`downloadFirmwareIcon${index}`}
                                  style={{
                                    cursor:
                                      bundle.upload_status !== "completed"
                                        ? "default"
                                        : "pointer",
                                    opacity:
                                      bundle.upload_status !== "completed"
                                        ? 0.35
                                        : 0.7,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (bundle.upload_status === "completed") {
                                      downloadFirmwareBundle(bundle);
                                    }
                                  }}
                                />
                              }
                              content={
                                bundle.upload_status !== "completed"
                                  ? "Firmware Bundle is not uploaded completely"
                                  : "Download Firmware Version"
                              }
                              position="top center"
                              inverted
                            />
                            <ConfirmationModal
                              prefixContent="Delete Firmware Bundle"
                              expectedText={bundle.version}
                              onConfirm={() => handleDelete(bundle.id)}
                              trigger={
                                <ButtonIcon
                                  link
                                  title="Delete Firmware Bundle"
                                  name="trash"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  style={{
                                    marginBottom: "0.7rem",
                                    marginRight: "0",
                                  }}
                                />
                              }
                              message={
                                <ConfirmationModalMessage
                                  name={bundle.version}
                                  type={"Firmware Bundle"}
                                  specialMessage=""
                                />
                              }
                            />
                          </div>
                        </Table.Cell>
                      </DisplayIf>
                    </Table.Row>
                    {activeIndex === index && (
                      <Table.Row key={`${bundle.id}-details`}>
                        <Table.Cell
                          colSpan={permissions.editFirmwares ? "8" : "7"}
                          style={{ paddingTop: "0px" }}
                        >
                          <Accordion
                            style={{
                              padding: "0px 32px 18px 32px",
                            }}
                          >
                            <Accordion.Title
                              active={activeIndex === index}
                              index={index}
                              onClick={() => handleAccordionClick(index)}
                            >
                              Firmware Details
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                              <Table fixed>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell textAlign="center">
                                      Component Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Version Number
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      File Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Content Length
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Checksum
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Options
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {bundle.firmwares.map((firmware, idx) => (
                                    <Table.Row key={`${bundle.id}-${idx}`}>
                                      <Table.Cell textAlign="center">
                                        {firmware.component_name}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        <TextWithToolTip
                                          text={firmware.version_number}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        <TextWithToolTip
                                          text={firmware.file_name}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        {getHumanReadableFileSizeString(
                                          firmware.content_length
                                        )}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        {firmware.checksum ? (
                                          <TextWithToolTip
                                            text={firmware.checksum}
                                          />
                                        ) : (
                                          "N/A"
                                        )}
                                      </Table.Cell>
                                      <Table.Cell textAlign="center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "18px",
                                          }}
                                        >
                                          <DisplayDependencies
                                            dependencies={firmware.dependencies}
                                            componentName={
                                              firmware.component_name
                                            }
                                            firmwareVersion={
                                              firmware.version_number
                                            }
                                          />
                                          <Popup
                                            trigger={
                                              <BrowserUpdatedIcon
                                                height="14px"
                                                id={`downloadFirmwareIcon${index}`}
                                                style={{
                                                  cursor: "pointer",
                                                  opacity: 0.7,
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  downloadFirmwareByVersion(
                                                    firmware
                                                  );
                                                }}
                                              />
                                            }
                                            content={
                                              "Download Firmware Version"
                                            }
                                            position="top center"
                                            inverted
                                          />
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </Accordion.Content>
                          </Accordion>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell
                    textAlign="center"
                    colSpan={permissions.editFirmwares ? "8" : "7"}
                  >
                    <ErrorMessage
                      marginTop="30px"
                      message={"No Firmware Bundles Uploaded!"}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
