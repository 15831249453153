import styled from "styled-components";
import { DeviceFilterOption, Filters } from "../../../../BytebeamClient";
import React from "react";
import { Icon, DropdownProps, Dropdown, Button } from "semantic-ui-react";
import { breakpoints } from "../../../common/breakpoints";

const SearchTitle = styled.div`
  display: inline-block !important;
  font-size: 24px;
  margin-bottom: 16px;
  max-width: 80% !important;
  height: 100%;
  line-height: 32px;
`;

type SearchHeaderProps = {
  title: string;
  filterOptions: DeviceFilterOption[];
  handleToggleClick: any;
  showInactive: boolean;
  onFilterSelected: (filterName: string, filterValue: string[]) => void;
  filters: Filters;
};

type SearchHeaderState = {
  screenWidth: number;
  showDeviceFilter: boolean;
  selectedFilters: Filters;
};

export class SearchHeader extends React.Component<
  SearchHeaderProps,
  SearchHeaderState
> {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      showDeviceFilter: false,
      selectedFilters: {
        ...props.filters,
      },
    };
  }
  filterSelectedTimeout: any = null;

  onFilterChange(data: DropdownProps, filterOption: DeviceFilterOption) {
    const filterName = filterOption.filterName;
    const filterValue = data.value as string[];
    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [filterName]: filterValue,
      },
    });
    clearTimeout(this.filterSelectedTimeout);
    this.filterSelectedTimeout = setTimeout(() => {
      this.props.onFilterSelected(filterName, filterValue);
    }, 1000);
  }

  handleToggleClick() {
    this.setState({
      showDeviceFilter: !this.state.showDeviceFilter,
    });
  }

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div className="dashboard-header">
        <div className="dashboard-header-row-1">
          <div className="header-left" style={{ width: "100%" }}>
            <SearchTitle>Select Device to view {this.props.title}</SearchTitle>
            {this.state.screenWidth <= breakpoints.sm && (
              <Button
                floated="right"
                icon
                onClick={() => this.handleToggleClick()}
              >
                <Icon link name="filter" />
              </Button>
            )}
          </div>
        </div>
        {(this.state.screenWidth > breakpoints.sm ||
          this.state.showDeviceFilter) &&
        this.props.filterOptions.length > 0 ? (
          <div
            className="dashboard-header-row-2"
            style={{ justifyContent: "flex-start" }}
          >
            {this.state.screenWidth > breakpoints.sm && <Icon name="filter" />}
            {this.props.filterOptions.map((filterOption) => {
              // Filtering out values containing only white spaces and Mapping to Dropdown options using FlatMap
              const options = filterOption.filterValues.flatMap((v) => {
                let value = String(v);
                if (value?.trim() !== "")
                  return [
                    {
                      key: value,
                      value: value,
                      text: value,
                    },
                  ];
                else return [];
              });

              return (
                <span
                  className="dashboard-header-dropdown"
                  key={filterOption.filterName}
                >
                  <Dropdown
                    placeholder={filterOption.filterName}
                    options={options}
                    selection
                    multiple
                    search
                    button
                    labeled
                    defaultValue={
                      this.state.selectedFilters[
                        filterOption.filterName
                      ] as string[]
                    }
                    onChange={(_event, data) =>
                      this.onFilterChange(data, filterOption)
                    }
                    disabled={false}
                  />
                </span>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
