import React, { createContext, useContext, useState } from "react";

export type StatusBarDevicesInfoType = {
  totalCount: number;
  filterStatus: string;
};

type StatusBarAutoRefreshPages = "device-management" | "dashboard" | "";
export type StatusBarAutoRefreshDetailsType = {
  lastRefreshedTime: Date | null;
  isRefreshPaused: boolean;
  refreshPage: StatusBarAutoRefreshPages;
  isRefreshStale: boolean;
};

type StatusBarContextType = {
  autoRefresh: StatusBarAutoRefreshDetailsType;
  setAutoRefresh: React.Dispatch<
    React.SetStateAction<StatusBarAutoRefreshDetailsType>
  >;
  devicesInfo: StatusBarDevicesInfoType;
  setDevicesInfo: React.Dispatch<
    React.SetStateAction<StatusBarDevicesInfoType>
  >;
};

const StatusBarContext = createContext<StatusBarContextType | undefined>(
  undefined
);

export const StatusBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [autoRefresh, setAutoRefresh] =
    useState<StatusBarAutoRefreshDetailsType>({
      lastRefreshedTime: null,
      isRefreshPaused: false,
      refreshPage: "",
      isRefreshStale: false,
    });
  const [devicesInfo, setDevicesInfo] = useState<StatusBarDevicesInfoType>({
    totalCount: 0,
    filterStatus: "",
  });

  const value = React.useMemo(
    () => ({
      autoRefresh,
      setAutoRefresh,
      devicesInfo,
      setDevicesInfo,
    }),
    [autoRefresh, setAutoRefresh, devicesInfo, setDevicesInfo]
  );

  return (
    <StatusBarContext.Provider value={value}>
      {children}
    </StatusBarContext.Provider>
  );
};

export const useStatusBarContext = () => {
  const context = useContext(StatusBarContext);
  if (!context) {
    throw new Error(
      "useStatusBarContext must be used within a StatusBarProvider"
    );
  }
  return context;
};
