import React, { useEffect, useRef, useState } from "react";
import { AlertRule } from "../../../../util";
import { DashboardsInfo } from "../../Dashboards/ViewDashboard";
import { fetchPanelData } from "../../../../BytebeamClient";
import { DashboardType } from "../../Dashboards/EditDashboardModal";
import uuid from "uuid";
import { FetchParams } from "../../Dashboards/Panel/util";
import { AlertsData } from "../../Dashboards/Panel/Alerts/PanelDef";
import {
  RelativeTimeRange,
  TimeRange,
} from "../../Dashboards/Datetime/TimeRange";
import LoadingAnimation from "../../../common/Loader";
import { CardContainer } from "../../../common/ActionsUtils";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DateTimeDropdown } from "../../Dashboards/Datetime/DateTimeDropdown";
import { DateTimeDropdownWrapper } from "../../Actions/Logs/LogsManagement";
import styled from "styled-components";
import AlertsComponent from "./AlertsComponent";
import Toggle from "../../../common/Toggle";
import { PaginationProps, Dropdown, DropdownProps } from "semantic-ui-react";

type AlertsDashboardProps = {
  readonly alertRules: AlertRule[];
  readonly alertGroups: string[];
  readonly dashboards: DashboardsInfo[];
};

const AlertsDashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  margin-bottom: 42px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FilterDropdownContainer = styled.div`
  min-width: 250px;
  margin-right: auto;
`;

function AlertsDashboard(props: AlertsDashboardProps) {
  const { alertRules, alertGroups, dashboards } = props;

  const [panelData, setPanelData] = useState<AlertsData | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRange>(
    new RelativeTimeRange(5, "minutes")
  );
  const [filterBys, setFilterBys] = useState({}); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [activeTab, setActiveTab] = useState<string>("All Alerts");
  const [groupByAlertGroups, setGroupByAlertGroups] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [alertsPerPage, setAlertsPerPage] = useState<number>(10);

  // state for selected filter items
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const abortControllerRef = useRef(new AbortController());
  const isMountedRef = useRef<boolean>(true);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const refreshInterval = 5000; // in milliseconds : 5 seconds
  //state to track initial loading phase
  const [initialLoading, setInitialLoading] = useState(true);
  const shouldContinuePollingRef = useRef<boolean>(true);

  const onTimeRangeChange = (timeRange: TimeRange) => {
    setTimeRange(timeRange);
  };

  const memoizedAlertGroups = React.useMemo(
    () => [...alertGroups],
    [alertGroups]
  );
  const memoizedAlertRules = React.useMemo(() => [...alertRules], [alertRules]);
  const dropdownOptions = React.useMemo(() => {
    if (groupByAlertGroups) {
      // For alert groups
      return memoizedAlertGroups.map((group) => ({
        key: group,
        text: group,
        value: group,
      }));
    } else {
      // For alert rules
      return memoizedAlertRules.map((rule) => ({
        key: rule.id,
        text: rule.name,
        value: rule.name,
      }));
    }
  }, [memoizedAlertGroups, memoizedAlertRules, groupByAlertGroups]);

  const handleFilterChange: DropdownProps["onChange"] = (_, data) => {
    setSelectedFilters(data.value as string[]);
    setActiveTab("All Alerts");
    setActivePage(1);
  };
  const handleClearFilters = () => {
    setSelectedFilters([]);
    setActiveTab("All Alerts");
    setActivePage(1);
  };

  const handlePageChange = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) => {
    if (data.activePage) {
      setActivePage(data.activePage as number);

      // pausing polling during manual refresh
      shouldContinuePollingRef.current = false;

      // Cancel any existing timeout
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      // Resume polling after manual refresh
      shouldContinuePollingRef.current = true;
    }
  };

  // mount status tracking
  useEffect(() => {
    isMountedRef.current = true;
    shouldContinuePollingRef.current = true;

    return () => {
      // setting component as unmounted
      isMountedRef.current = false;
      shouldContinuePollingRef.current = false;

      // Cancelling any ongoing pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clear any scheduled timeouts
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, []);

  // Initial data load and re-fetch when dependencies change
  useEffect(() => {
    // Cancel any previous polling cycle
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }

    // Cancel any in-flight request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Start a new polling cycle
    shouldContinuePollingRef.current = true;

    const refreshData = async (
      page: number = activePage,
      isAutoRefresh: boolean = false
    ) => {
      // Only update state if component is still mounted
      if (isMountedRef.current && !isAutoRefresh) {
        setLoading(true);
      }

      try {
        // Check and cancel any ongoing requests first
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        // Creating a new abort controller for this specific request
        abortControllerRef.current = new AbortController();

        //alert array based on selected filters
        let alertArray: string[] = [];

        if (selectedFilters.length > 0) {
          //when filters are selected using only those options
          alertArray = [...selectedFilters];
        } else {
          // Otherwise using all available options
          alertArray = groupByAlertGroups
            ? alertGroups
            : alertRules.map((rule) => rule.name);
        }

        let alertsFilter = alertArray;
        if (selectedFilters.length > 0) {
          if (activeTab !== "All Alerts") alertsFilter = [activeTab];
          else alertsFilter = selectedFilters;
        } else if (activeTab !== "All Alerts") alertsFilter = [activeTab];

        const panelMeta = {
          type: "alerts",
          id: `alerts-dashboard-page-panel-${uuid.v4()}`,
          title: "Alerts Dashboard Panel",
          description: "Alerts Dashboard in alerts Tab",
          aggregates: [],
          tabbedView: true,
          tabByAlertGroups: groupByAlertGroups,
          tabList: activeTab === "All Alerts" ? "all" : activeTab,
          page: page,
          limit: alertsPerPage,
          groupBys: ["id"],
          ...(groupByAlertGroups
            ? { alert_groups: alertsFilter }
            : { alert_names: alertsFilter }),
        };
        const summaryPanelMeta = {
          type: "alerts",
          id: `summary-${panelMeta.id}`,
          title: "Alerts Summary Panel",
          description: "Alerts Summary Panel",
          aggregates: [],
          tabbedView: false,
          tabByAlertGroups: groupByAlertGroups,
          tabList: activeTab === "All Alerts" ? "all" : activeTab,
          page: page,
          limit: alertsPerPage,
          groupBys: ["id"],
          ...(groupByAlertGroups
            ? {
                alert_groups:
                  selectedFilters.length > 0 ? selectedFilters : undefined,
              }
            : {
                alert_names:
                  selectedFilters.length > 0 ? selectedFilters : undefined,
              }),
        };

        const fetchParams: FetchParams = {
          groupBys: [],
          fetchAll: false,
          timeRange: timeRange,
          filterBys: filterBys,
        };

        const panelsData = await fetchPanelData(
          "alerts-dashboard-page",
          panelMeta.id,
          [panelMeta],
          fetchParams,
          abortControllerRef.current.signal,
          DashboardType.FleetDashboard
        );

        const summaryData = await fetchPanelData(
          "alerts-dashboard-page",
          summaryPanelMeta.id,
          [summaryPanelMeta],
          fetchParams,
          abortControllerRef.current.signal,
          DashboardType.FleetDashboard
        );

        // Only update state if component is still mounted
        if (isMountedRef.current) {
          let pData = await panelsData?.[0]?.data,
            sData = await summaryData?.[0]?.data;

          if (pData && sData) {
            pData["summary"] = sData?.summary ?? {};
          }
          setPanelData(pData ?? null);
          setErrorOccurred(false);
          setInitialLoading(false); // Data has been fetched at least once
        }
      } catch (error) {
        // no considering abort errors as they happen when we cancel the request intentionally
        if (
          isMountedRef.current &&
          !(error instanceof Error && error.name === "AbortError")
        ) {
          console.error(error);
          setErrorOccurred(true);
          setInitialLoading(false); //initialLoading to false even on error
        }
      } finally {
        // Only update state if component is still mounted
        if (isMountedRef.current && !isAutoRefresh) {
          setLoading(false);
        }

        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
        // refresh continues if component is still mounted
        if (isMountedRef.current && shouldContinuePollingRef.current) {
          timeoutIdRef.current = setTimeout(() => {
            if (isMountedRef.current && shouldContinuePollingRef.current) {
              refreshData(activePage, true);
            }
          }, refreshInterval);
        }
      }
    };

    refreshData();

    // Cleaning up interval on component unmount or when dependencies change
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [
    filterBys,
    timeRange,
    groupByAlertGroups,
    activeTab,
    selectedFilters,
    activePage,
    alertGroups,
    alertRules,
    alertsPerPage,
  ]);

  useEffect(() => {
    const alertsPerPage = window.localStorage.getItem("alertsListPerPage");
    if (alertsPerPage) {
      setAlertsPerPage(Number(alertsPerPage));
    }
  }, [setAlertsPerPage]);

  const renderContent = () => {
    if (initialLoading || loading) {
      return (
        <LoadingAnimation
          fontSize="16px"
          loaderContainerHeight="60vh"
          loaderSize="42px"
          loadingText={`Loading Alerts...`}
        />
      );
    }

    if (errorOccurred) {
      return (
        <ErrorMessage
          containerHeight={"59vh"}
          iconSize={"large"}
          marginTop={"0px"}
          message={"Failed to fetch Alerts data"}
        />
      );
    }

    if (!panelData) {
      return <ErrorMessage message="No alerts data available" />;
    }

    let filteredData = { ...panelData };
    let filteredAlertGroups = [...alertGroups];

    if (selectedFilters.length > 0) {
      filteredData = {
        ...panelData,
        alerts: panelData.alerts.filter((alert) => {
          if (groupByAlertGroups) {
            const rule = panelData.alertRules?.find(
              (r) => r.id === alert.alert_rule.id
            );
            return rule && selectedFilters.includes(rule.group);
          } else {
            return selectedFilters.includes(alert.alert_rule.name);
          }
        }),
        alertRules: panelData.alertRules?.filter((rule) => {
          if (groupByAlertGroups) {
            return selectedFilters.includes(rule.group);
          } else {
            return selectedFilters.includes(rule.name);
          }
        }),
      };

      // Filter alertGroups based on selectedFilters when grouping by alert groups
      if (groupByAlertGroups) {
        filteredAlertGroups = alertGroups.filter((group) =>
          selectedFilters.includes(group)
        );
      }
    }

    return (
      <AlertsComponent
        data={filteredData}
        alertGroups={filteredAlertGroups}
        aggregates={[]}
        loading={loading}
        totalPages={Math.ceil(filteredData.totalCount / alertsPerPage)}
        dashboards={dashboards}
        handlePageChange={handlePageChange}
        activePage={activePage}
        setActivePage={setActivePage}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        groupByAlertGroups={groupByAlertGroups}
        selectedFilters={selectedFilters}
        alertsPerPage={alertsPerPage}
        setAlertsPerPage={setAlertsPerPage}
      />
    );
  };

  return (
    <CardContainer>
      <AlertsDashboardHeader>
        <FiltersContainer>
          <FilterDropdownContainer>
            <Dropdown
              placeholder={
                groupByAlertGroups
                  ? "Filter by Alert Groups"
                  : "Filter by Alert Rules"
              }
              fluid
              multiple
              search
              selection
              clearable
              options={dropdownOptions}
              value={selectedFilters}
              onChange={(e, data) => {
                if (
                  !data.value ||
                  (Array.isArray(data.value) && data.value.length === 0)
                ) {
                  handleClearFilters();
                } else {
                  handleFilterChange(e, data);
                }
              }}
              disabled={loading}
              onClear={handleClearFilters}
            />
          </FilterDropdownContainer>

          <div>
            <span style={{ marginRight: "8px" }}>Group by Alert Groups</span>
            <Toggle
              id="group-by-alert-groups"
              size="medium"
              checked={groupByAlertGroups}
              onChange={() => {
                setGroupByAlertGroups((prev) => !prev);
                setSelectedFilters([]);
                setActiveTab("All Alerts");
                setActivePage(1);
              }}
              style={{
                top: "4px",
              }}
            />
          </div>
        </FiltersContainer>

        <DateTimeDropdownWrapper>
          <DateTimeDropdown
            showControls={true}
            timeRange={timeRange}
            onTimeRangeChange={onTimeRangeChange}
            disabled={loading}
          />
        </DateTimeDropdownWrapper>
      </AlertsDashboardHeader>

      {renderContent()}
    </CardContainer>
  );
}

export default AlertsDashboard;
