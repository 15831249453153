import React, { memo, useEffect, useMemo, useState } from "react";
import { NewActionWrapper, PhaseData } from "../NewAction/NewAction";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import {
  ActionStatsContainer,
  CardContainer,
  ChartContainer,
  FullLengthFlexContainer,
  MoreInfoPopUp,
  NewActionContainer,
  ActionsChartContainer,
} from "../../../common/ActionsUtils";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import styled from "styled-components";
import ActionsMoreInfoCard from "../ActionsV3/ActionsInfoCard";
import { ActionStatusBarChart } from "./ActionStatusBarChart";
import { ActionsLatencyHistogram } from "./ActionsLatencyHistogram";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Header } from "semantic-ui-react";
import moment from "moment";
import { capitalizeFirstLetter } from "../../util";
import AddPhaseModal from "./AddPhaseModal";
import CarouselArrow from "../ActionsV3/CarouselArrow";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import { beamtoast } from "../../../common/CustomToast";
import ErrorDistributionChart from "../ActionsV3/ErrorDistributionChart";
import {
  ActionStatusType,
  cancelActions,
  retryActions,
} from "../../../../BytebeamClient";
import { useUser } from "../../../../context/User.context";
import ViewPayloadModal from "./ViewPayloadModal";

const PhaseButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LiveActionsButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ActionSummarySubHeading = styled(Header)`
  color: ${(props) => props.theme.colors["table-text"]};
  margin-bottom: 0.2rem;
  font-weight: normal;
`;

type ActionsSummarySectionProps = {
  readonly actionIDParam: string;
  readonly deviceIDParam: string;
  readonly selectedAction: ActionStatusType;
  readonly selectedPhase: string;
  readonly setSelectedPhase: (name: string) => void;
  readonly setMainLoading: (isLoading: boolean) => void;
  readonly openAddPhase: boolean;
  readonly setOpenAddPhase: (open: boolean) => void;
  readonly fetchActionData: (actionId?: number) => Promise<void>;
  readonly refetchDeviceStatusDetails: () => void;
};

const ActionsSummarySection = (props: ActionsSummarySectionProps) => {
  const {
    actionIDParam,
    deviceIDParam,
    selectedAction,
    selectedPhase,
    setSelectedPhase,
    setMainLoading,
    openAddPhase,
    setOpenAddPhase,
    fetchActionData,
    refetchDeviceStatusDetails,
  } = props;
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actions");

  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const [phasesData, setPhasesData] = useState<PhaseData[]>([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [isHistogramVisible, setIsHistogramVisible] = useState<boolean>(false);
  const [phasesDistributionData, setPhasesDistributionData] = useState<any>({});
  const [showPayload, setShowPayload] = useState<boolean>(false);

  const sections = useMemo(
    () => ["Status Distribution", "Latency Histogram"],
    []
  );

  useEffect(() => {
    const statuses = selectedAction?.statuses ?? {};

    if (selectedAction) {
      const numTotal = Object.values(statuses ?? {}).reduce((a, b) => a + b, 0);
      const {
        Queued,
        Initiated,
        Completed,
        Scheduled,
        PendingApproval,
        Failed,
        ...remaining
      } = statuses;

      let totalInProgressDevices = 0;
      Object.keys(remaining).forEach((status) => {
        totalInProgressDevices = totalInProgressDevices + remaining[status];
      });

      // Set Phase level distribution also here
      if (selectedAction?.schedule?.phases?.length > 0) {
        setPhasesData(selectedAction?.schedule?.phases);
        let updatedPhasesDistributionData = {
          all: {
            Queued: Queued ?? 0,
            Initiated: Initiated ?? 0,
            InProgress: totalInProgressDevices ?? 0,
            Completed: Completed ?? 0,
            Failed: Failed ?? 0,
            Scheduled: Scheduled ?? 0,
            PendingApproval: PendingApproval ?? 0,
            Total: numTotal,
          },
        };
        for (let i = 0; i < selectedAction?.schedule?.phases?.length; i++) {
          if (selectedAction?.statuses_phased?.[i + ""]) {
            let phaseStatusData = selectedAction.statuses_phased[i + ""];
            let phaseData = selectedAction?.schedule?.phases[i];
            let {
              Queued,
              Initiated,
              Completed,
              Scheduled,
              PendingApproval,
              Failed,
              ...remaining
            } = phaseStatusData;
            const numTotal: any = Object.values(phaseStatusData).reduce(
              (a: any, b: any) => a + b
            );
            let inProgressDevices = 0;
            Object.keys(remaining).forEach((status) => {
              inProgressDevices = inProgressDevices + remaining[status];
            });
            let key = phaseData.name.toString();
            updatedPhasesDistributionData[key] = {
              Queued: Queued ?? 0,
              Initiated: Initiated ?? 0,
              InProgress: inProgressDevices ?? 0,
              Completed: Completed ?? 0,
              Scheduled: Scheduled ?? 0,
              PendingApproval: PendingApproval ?? 0,
              Failed: Failed ?? 0,
              Total: numTotal,
            };
          }
        }
        setPhasesDistributionData(updatedPhasesDistributionData);
      } else {
        // Setting Only All in phase distribution
        setPhasesData([]);
        let updatedPhasesDistributionData = {
          all: {
            Queued: Queued ?? 0,
            Initiated: Initiated ?? 0,
            InProgress: totalInProgressDevices ?? 0,
            Completed: Completed ?? 0,
            Scheduled: Scheduled ?? 0,
            PendingApproval: PendingApproval ?? 0,
            Failed: Failed ?? 0,
            Total: numTotal,
          },
        };
        setPhasesDistributionData(updatedPhasesDistributionData);
      }
    }
  }, [JSON.stringify(selectedAction)]); // eslint-disable-line react-hooks/exhaustive-deps

  const setActivePhase = (name: string) => {
    setSelectedPhase(name);
  };

  const getPhaseScheduleTimestamp = () => {
    let selectedActionPhase =
      selectedPhase === "all" ? "Phase I" : selectedPhase;
    let phaseIndex = selectedAction?.schedule?.phases.findIndex(
      (phase) => phase.name === selectedActionPhase
    );

    if (phaseIndex >= 0) {
      let returnTimeStamp =
        selectedAction?.schedule?.phases[phaseIndex].trigger_on?.timestamp;
      return returnTimeStamp;
    }
  };

  const generateActionSubHeader = (selectedAction, selectedPhase) => {
    const actionInfo = createActionInfo(selectedAction);
    const timestampInfo = createTimestampInfo(selectedAction, selectedPhase);

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `${actionInfo}&nbsp;&nbsp;${timestampInfo}`,
        }}
      />
    );
  };

  const createActionInfo = (selectedAction) => {
    return `#${selectedAction?.action_id} ${capitalizeFirstLetter(selectedAction?.type)} triggered by ${selectedAction?.user_name}`;
  };

  const formatTimestamp = (timestamp) => {
    return capitalizeFirstLetter(moment(timestamp).fromNow());
  };

  const createTimestampInfo = (selectedAction, selectedPhase) => {
    if (selectedPhase === "all") {
      return ` [ ${formatTimestamp(selectedAction?.created_at)} ]`;
    } else {
      const scheduleTimestamp = getPhaseScheduleTimestamp();
      if (scheduleTimestamp) {
        return ` [ ${formatTimestamp(scheduleTimestamp)} ]`;
      } else {
        return "";
      }
    }
  };

  const showHistogram = () => {
    setIsHistogramVisible(true);
    setActiveSectionIndex((prevIndex) => (prevIndex + 1) % sections?.length);
  };

  const hideHistogram = () => {
    setIsHistogramVisible(false);
    setActiveSectionIndex(
      (prevIndex) => (prevIndex - 1 + sections?.length) % sections?.length
    );
  };

  type handleCancelOrRetryActionsParams =
    | {
        actionId: string;
        phaseIDX?: number;
        type: "all";
        operation: "cancel" | "retry";
      }
    | {
        actionId: string;
        phaseIDX: number;
        type: "phase_idx";
        operation: "cancel" | "retry";
      };

  /**
   * Handles the cancellation or retry of an action.
   *
   * @param {handleCancelOrRetryActionsParams} params - The parameters for handling the cancellation or retry.
   * @returns {Promise<void>} - A promise that resolves when the cancellation or retry is completed.
   */
  const handleCancelOrRetryAction = async ({
    actionId,
    phaseIDX,
    type,
    operation,
  }: handleCancelOrRetryActionsParams): Promise<void> => {
    try {
      let res;
      if (operation === "cancel") {
        res = await cancelActions(actionId, {
          ...(type === "all" && { all: true }),
          ...(type === "phase_idx" && { phase_idx: phaseIDX }),
        });
      } else if (operation === "retry") {
        res = await retryActions(actionId, {
          ...(type === "all" && { all: true }),
          ...(type === "phase_idx" && { phase_idx: phaseIDX }),
        });
      }

      // If the action is cancelled/retried successfully and the action ID is present, then fetch the action data.
      if (res && res !== undefined && actionIDParam) {
        await fetchActionData(Number(actionId));

        // Refetch the device status details, to update the status of the devices.
        refetchDeviceStatusDetails();
      }

      // Determine the operation for the toast message
      const operationText = operation === "cancel" ? "cancelled" : "retried";

      beamtoast.success(
        `Action ${actionId} for ${selectedPhase} has been ${operationText}`
      );
    } catch (error) {
      console.log(error);
      // Determine the operation for the error toast message
      const operationText = operation === "cancel" ? "cancel" : "retry";
      beamtoast.error(
        `Failed to ${operationText} action ${actionId} for ${selectedPhase}`
      );
    }
  };

  // Check if the phase is failed.
  const isPhaseFailed = (phaseName: string) => {
    const phaseTotalDevices: number = phasesDistributionData[phaseName]?.Total;

    if (phasesDistributionData[phaseName]) {
      const { Failed } = phasesDistributionData[phaseName];
      if ((Failed || 0) === phaseTotalDevices) {
        return true;
      }
    }
    return false;
  };

  // Check if the phase is completed or failed.
  const isPhaseCompletedOrFailed = (phaseName: string) => {
    const phaseTotalDevices: number = phasesDistributionData[phaseName]?.Total;

    // If the phase is completed or failed
    // Then the sum of completed and failed devices should be equal to the total devices in the phase.
    if (phasesDistributionData[phaseName]) {
      const { Completed, Failed } = phasesDistributionData[phaseName];
      if ((Completed || 0) + (Failed || 0) === phaseTotalDevices) {
        return true;
      }
    }
    return false;
  };

  return (
    <NewActionContainer>
      <AddPhaseModal
        isOpen={openAddPhase}
        onClose={() => {
          setOpenAddPhase(false);
        }}
        action={selectedAction}
        fetchActionData={fetchActionData}
      />
      <ViewPayloadModal
        isOpen={showPayload}
        onClose={() => setShowPayload(false)}
        actionId={selectedAction.action_id}
      />
      <NewActionWrapper>
        {!deviceIDParam && actionIDParam && (
          <ActionButtonV3
            margin_bottom="30px"
            margin_left="0px"
            label="Back"
            icon="left chevron"
            onClick={() => {
              history.push(`${matchedPath?.url}/live-actions`);
              setMainLoading(true);
            }}
          />
        )}
        {!!selectedAction.action_id && (
          <CardContainer>
            <StyledHeader
              as="h2"
              style={{
                marginTop: "0px",
                marginBottom: "20px",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span>
                {selectedAction?.action_id
                  ? generateActionSubHeader(selectedAction, selectedPhase)
                  : null}
              </span>
              <PhaseButtonsWrapper style={{ fontSize: "1rem" }}>
                {!actionIDParam && (
                  <ActionButtonV3
                    onClick={() => {
                      history.push(
                        `${matchedPath?.url}?action_id=${selectedAction?.action_id}`
                      );
                    }}
                    label={"View Details"}
                    icon={"eye"}
                    selected={false}
                    border_radius={"5px"}
                    margin_left={"10px"}
                    padding={"0.5rem 1rem"}
                  />
                )}
                <ActionButtonV3
                  onClick={() => {
                    setShowPayload(true);
                  }}
                  label={"View Payload"}
                  icon={"file code outline"}
                  border_radius={"5px"}
                  margin_left={"10px"}
                  padding={"0.5rem 1rem"}
                />
                <MoreInfoPopUp
                  content={
                    <ActionsMoreInfoCard
                      action={selectedAction}
                      selectedPhase={
                        selectedPhase === "all" ? "Phase I" : selectedPhase
                      }
                      scheduledAt={getPhaseScheduleTimestamp()}
                    />
                  }
                  position="bottom right"
                  inverted
                  trigger={
                    <ActionButtonV3
                      onClick={() => {}}
                      label={"More Info"}
                      icon={"info circle"}
                      selected={false}
                      border_radius={"5px"}
                      margin_left={"10px"}
                      padding={"0.5rem 1rem"}
                    />
                  }
                />
              </PhaseButtonsWrapper>
            </StyledHeader>
            <LiveActionsButtonWrapper>
              {phasesData?.length > 0 && (
                <>
                  <PhaseButtonsWrapper>
                    <ActionButtonV3
                      onClick={() => {
                        setActivePhase("all");
                      }}
                      label={"All Phases"}
                      selected={selectedPhase === "all"}
                      margin_left={"10px"}
                      padding={"0.5rem 1rem"}
                    />
                    {phasesData.map((phase) => (
                      <ActionButtonV3
                        key={phase.name}
                        onClick={() => {
                          setActivePhase(phase.name);
                        }}
                        label={phase.name}
                        icon={phase.icon}
                        selected={selectedPhase === phase.name}
                        margin_left={"10px"}
                        padding={"0.5rem 1rem"}
                      />
                    ))}
                    <ActionButtonV3
                      disabled={["cancel_action", "launch_shell"].includes(
                        selectedAction?.type
                      )}
                      onClick={() => {
                        if (
                          !["cancel_action", "launch_shell"].includes(
                            selectedAction?.type
                          )
                        )
                          setOpenAddPhase(true);
                      }}
                      icon={"add"}
                      label={"Add Phase"}
                      margin_left={"20px"}
                      padding={"0.5rem 1rem"}
                      style={{
                        backgroundColor: "#f5f5f5",
                        color: "#201f1f",
                      }}
                    />
                  </PhaseButtonsWrapper>

                  {selectedPhase !== "all" &&
                    permissions.allowedActions.includes(
                      selectedAction?.type
                    ) && (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <ConfirmationModal
                          prefixContent="Cancel Action"
                          expectedText={"Yes"}
                          onConfirm={() => {
                            // Cancel the action for the selected phase, based on the phase index.
                            const phaseIDX = phasesData.findIndex(
                              (phase) => phase.name === selectedPhase
                            );
                            handleCancelOrRetryAction({
                              actionId: selectedAction.action_id.toString(),
                              phaseIDX: phaseIDX,
                              type: "phase_idx",
                              operation: "cancel",
                            });
                          }}
                          trigger={
                            <ActionButtonV3
                              onClick={() => {}}
                              icon={"cancel"}
                              label={"Cancel Phase"}
                              margin_left={"20px"}
                              padding={"0.5rem 1rem"}
                              style={{
                                backgroundColor: "#f5f5f5",
                                color: "#201f1f",
                                ...(isPhaseCompletedOrFailed(selectedPhase)
                                  ? { opacity: ".2", cursor: "not-allowed" }
                                  : {}),
                              }}
                              disabled={
                                isPhaseCompletedOrFailed(selectedPhase) ||
                                selectedAction?.type === "cancel_action" ||
                                selectedAction?.type === "launch_shell"
                              }
                            />
                          }
                          message={
                            <ConfirmationModalMessageNonDelete
                              name={`Cancel Action for ${selectedPhase}`}
                              expectedText={"Yes"}
                              type={""}
                              specialMessage={`Action-ID: ${selectedAction.action_id}, Type: ${selectedAction.type}`}
                            />
                          }
                        />

                        <ConfirmationModal
                          prefixContent="Retry Action"
                          expectedText={"Yes"}
                          onConfirm={() => {
                            const phaseIDX = phasesData.findIndex(
                              (phase) => phase.name === selectedPhase
                            );
                            handleCancelOrRetryAction({
                              actionId: selectedAction.action_id.toString(),
                              phaseIDX: phaseIDX,
                              type: "phase_idx",
                              operation: "retry",
                            });
                          }}
                          trigger={
                            <ActionButtonV3
                              onClick={() => {}}
                              icon={"redo"}
                              label={"Retry Phase"}
                              margin_left={"20px"}
                              padding={"0.5rem 1rem"}
                              style={{
                                backgroundColor: "#f5f5f5",
                                color: "#201f1f",
                                ...(!isPhaseFailed(selectedPhase)
                                  ? { opacity: ".2", cursor: "not-allowed" }
                                  : {}),
                              }}
                              disabled={
                                !isPhaseFailed(selectedPhase) ||
                                selectedAction?.type === "cancel_action" ||
                                selectedAction?.type === "launch_shell"
                              }
                            />
                          }
                          message={
                            <ConfirmationModalMessageNonDelete
                              name={`Retry Action for ${selectedPhase}`}
                              expectedText={"Yes"}
                              type={""}
                              specialMessage={`Action-ID: ${selectedAction.action_id}, Type: ${selectedAction.type}`}
                            />
                          }
                        />
                      </div>
                    )}
                </>
              )}
            </LiveActionsButtonWrapper>

            <FullLengthFlexContainer>
              <CarouselArrow
                onClick={() => hideHistogram()}
                arrowDir="left"
                style={{
                  opacity: isHistogramVisible ? "1" : "0",
                  transition: "100ms ease-in",
                }}
              />
              <CarouselArrow
                onClick={() => showHistogram()}
                arrowDir="right"
                style={{
                  opacity: isHistogramVisible ? "0" : "1",
                  transition: "100ms ease-in",
                }}
              />
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "12px",
                }}
              >
                <ActionStatsContainer id="action-radial-charts-container">
                  {sections.map((section, index) => {
                    const isActive = index === activeSectionIndex;

                    const renderSection = () => {
                      switch (section) {
                        case "Status Distribution":
                          return (
                            <>
                              <ActionsChartContainer
                                style={{ display: isActive ? "flex" : "none" }}
                              >
                                <StyledHeader
                                  as="h3"
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  Status Distribution
                                </StyledHeader>
                                <ActionStatusBarChart
                                  phasedData={phasesDistributionData}
                                  selectedPhase={selectedPhase}
                                  height={180}
                                  width={400}
                                  textInfo
                                />
                              </ActionsChartContainer>
                              <ActionsChartContainer
                                style={{
                                  display: isActive ? "flex" : "none",
                                  minWidth: "400px",
                                  maxWidth: "500px",
                                }}
                              >
                                <StyledHeader
                                  as="h3"
                                  style={{ marginTop: "0px" }}
                                >
                                  Failure Cause Distribution
                                </StyledHeader>
                                <ChartContainer>
                                  <ErrorDistributionChart
                                    height={225}
                                    width={225}
                                    action={selectedAction}
                                  />
                                </ChartContainer>
                              </ActionsChartContainer>
                            </>
                          );

                        case "Latency Histogram":
                          return (
                            <ActionsChartContainer
                              style={{ display: isActive ? "flex" : "none" }}
                            >
                              <StyledHeader
                                as="h3"
                                style={{ marginTop: "0px" }}
                              >
                                Latency Histogram (All Phases)
                              </StyledHeader>
                              <ChartContainer>
                                <ActionsLatencyHistogram
                                  selectedAction={selectedAction}
                                />
                              </ChartContainer>
                            </ActionsChartContainer>
                          );

                        default:
                          return null;
                      }
                    };

                    return (
                      <React.Fragment key={section}>
                        {renderSection()}
                      </React.Fragment>
                    );
                  })}
                </ActionStatsContainer>
              </div>
            </FullLengthFlexContainer>
          </CardContainer>
        )}
      </NewActionWrapper>
    </NewActionContainer>
  );
};

export default memo(ActionsSummarySection);
