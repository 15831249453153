import { Popup } from "semantic-ui-react";
import styled from "styled-components";

export const MoreInfoPopUp = styled(Popup)`
  border: ${({ theme }) =>
    theme.colors["live-actions-more-info-popup-border"]} !important;

  &::before {
    border-top: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
    border-left: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
  }
`;

export const CardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "marginBottom",
})<{ marginBottom?: string }>`
  border-radius: 6px;
  width: 100%;
  padding: 24px 36px;
  background: ${({ theme }) =>
    theme.colors["action-card-container-background"]};
  border: ${({ theme }) => theme.colors["container-border"]};
  box-shadow: ${({ theme }) => theme.colors["container-box_shadow"]};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;

export const NewActionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
`;

export const ActionStatsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  transition: all 300ms ease-in-out;
`;

export const ActionsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  padding: 1.5rem 2rem 1.5rem 2rem;
  width: 100%;
  height: 300px;
  border: 1px solid ${(props) => props.theme.colors["add-button-border-color"]};
  border-radius: 5px;
  transition: all 300ms ease-in-out;
`;

export const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: space-evenly; // This will add space between the child elements
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FullLengthFlexContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  transition: transform 0.5s ease-in-out;
`;
