import React, { useEffect, useState } from "react";
import { ActionType } from "../../../../util";
import { Button, Icon, Popup, SemanticICONS, Table } from "semantic-ui-react";
import Layout from "../../common/Layout";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import ActionTypesModal, { ActionTypeModalProps } from "./ActionTypesModal";
import * as uuid from "uuid";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { DisplayIf } from "../../util";
import {
  createActionType,
  deleteActionType,
  editActionType,
  fetchAllActionTypes,
} from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import { formatTimeoutDuration } from "../tenantsSettings/DefaultGlobalTimeout";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer } from "../../../common/ActionsUtils";

export default function ActionTypes() {
  const [actionTypes, setActionTypes] = useState<ActionType[]>([]);
  const [actionTypeModalProps, setActionTypeModalProps] =
    useState<ActionTypeModalProps>({
      key: uuid.v4(),
      isOpen: false,
      title: "",
      isBuiltInAction: false,
      onSubmit: (role) => {},
      onCancel: () => {},
      actionTypesSet: new Set(),
    });
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  const { user, getCurrentUser } = useUser();
  const permissions = user.role.permissions;
  const allowedActions = permissions.allowedActions;

  const onUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchAllActionTypes();
      const filteredRes = res.filter(
        (actionType) =>
          allowedActions === "all" || allowedActions.includes(actionType.type)
      );

      setActionTypes(filteredRes);

      // Update user context to get the latest permissions for the user after updating the action types
      await getCurrentUser();

      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  const closeActionTypeModal = () => {
    setActionTypeModalProps({
      ...actionTypeModalProps,
      isOpen: false,
    });
  };

  const initCreateActionTypeModal = () => {
    setActionTypeModalProps({
      key: uuid.v4(),
      isOpen: true,
      title: "Create Action",
      isBuiltInAction: false,
      onSubmit: async (at) => {
        try {
          await createActionType({
            type: at.type,
            icon: at.icon,
            payload_type: at.payload_type,
            json_schema: at.json_schema,
            json_ui_schema: at.json_ui_schema,
            timeout: at.timeout,
          });
          Mixpanel.track("Create Action", {
            action: at.type,
          });
          beamtoast.success(`Action type '${at.type}' created successfully`);
          onUpdate();
          closeActionTypeModal();
        } catch (error: any) {
          Mixpanel.track("Failure", {
            type: "Create Action",
            error: JSON.stringify(error),
          });
          if (error["name"] === "bad_request") {
            beamtoast.error(error["message"]);
          } else {
            beamtoast.error(`Failed to create action type '${at.type}'`);
          }
          console.log(error);
        }
      },
      onCancel: closeActionTypeModal,
      actionTypesSet: new Set(
        actionTypes?.map((actionType) => actionType.type)
      ),
    });
  };

  const initEditActionTypeModal = (
    actionType: ActionType,
    isBuiltInAction: boolean
  ) => {
    setActionTypeModalProps({
      key: uuid.v4(),
      isOpen: true,
      title: "Edit Action Type",
      isBuiltInAction,
      actionType,
      onSubmit: async (at) => {
        closeActionTypeModal();
        try {
          await editActionType(actionType.type, {
            type: at.type,
            icon: at.icon,
            payload_type: at.payload_type,
            json_schema: at.json_schema,
            json_ui_schema: at.json_ui_schema,
            timeout: at.timeout ?? null,
          });
          beamtoast.success(`Action type '${at.type}' updated successfully`);
          onUpdate();
        } catch (e) {
          beamtoast.error(`Failed to update action type '${at.type}'`);
          console.log(e);
        }
      },
      onCancel: closeActionTypeModal,
      actionTypesSet: new Set(
        actionTypes?.map((actionType) => actionType.type)
      ),
    });
  };

  const dependenciesCheck = [
    Array.isArray(allowedActions)
      ? JSON.stringify(allowedActions.sort())
      : allowedActions,
  ];

  useEffect(() => {
    document.title = "Action Types | Bytebeam";

    onUpdate();
  }, dependenciesCheck); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading actions"
      />
    );
  }

  const BuiltInActions = (
    actionType: ActionType
  ): { isBuiltIn: boolean; icon: SemanticICONS; payloadType: string } => {
    switch (actionType.type) {
      case "update_firmware":
        return { isBuiltIn: true, icon: "download", payloadType: "file" };
      case "update_config":
        return { isBuiltIn: true, icon: "configure", payloadType: "json" };
      case "update_geofence":
        return { isBuiltIn: true, icon: "map outline", payloadType: "json" };
      case "send_file":
        return { isBuiltIn: true, icon: "send", payloadType: "file" };
      case "launch_shell":
        return { isBuiltIn: true, icon: "terminal", payloadType: "none" };
      case "send_script":
        return { isBuiltIn: true, icon: "file", payloadType: "file" };
      case "cancel_action":
        return { isBuiltIn: true, icon: "cancel", payloadType: "none" };
      case "retry_action":
        return { isBuiltIn: true, icon: "redo", payloadType: "none" };
      default:
        return {
          isBuiltIn: false,
          icon: actionType.icon,
          payloadType: actionType.payload_type,
        };
    }
  };

  return (
    <CardContainer>
      <ActionTypesModal {...actionTypeModalProps} />
      <Layout
        buttons={
          <DisplayIf cond={permissions.editActionTypes}>
            <Button
              primary
              floated="right"
              icon
              labelPosition="left"
              onClick={initCreateActionTypeModal}
            >
              <Icon name="plus" />
              Create Action Type
            </Button>
          </DisplayIf>
        }
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Payload Type</Table.HeaderCell>
              <Table.HeaderCell>Timeout</Table.HeaderCell>
              <Table.HeaderCell>Icon</Table.HeaderCell>
              <DisplayIf cond={permissions.editActionTypes}>
                <Table.HeaderCell textAlign="center">Options</Table.HeaderCell>
              </DisplayIf>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {actionTypes.length !== 0 ? (
              actionTypes
                .sort((at) => (BuiltInActions(at).isBuiltIn ? -1 : 1))
                .map((at) => (
                  <Table.Row key={at.type}>
                    <Table.Cell>{at.type}</Table.Cell>
                    <Table.Cell>{BuiltInActions(at).payloadType}</Table.Cell>
                    <Table.Cell>
                      {at.timeout ? formatTimeoutDuration(at.timeout) : "--"}
                    </Table.Cell>
                    <Table.Cell>
                      <Icon name={BuiltInActions(at).icon} />
                      {BuiltInActions(at).icon}
                    </Table.Cell>
                    <DisplayIf cond={permissions.editActionTypes}>
                      <Table.Cell>
                        {BuiltInActions(at).isBuiltIn ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "16px",
                            }}
                          >
                            {" "}
                            <Icon
                              name="edit"
                              onClick={() =>
                                initEditActionTypeModal(
                                  at,
                                  BuiltInActions(at).isBuiltIn
                                )
                              }
                              link
                            />
                            <Popup
                              inverted
                              position="top left"
                              content="Default Action, its built in the platform."
                              style={{ marginBottom: "6px" }}
                              trigger={
                                <span>
                                  <Icon name="trash" disabled={true} />
                                </span>
                              }
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "16px",
                            }}
                          >
                            <Icon
                              name="edit"
                              onClick={() =>
                                initEditActionTypeModal(
                                  at,
                                  BuiltInActions(at).isBuiltIn
                                )
                              }
                              link
                            />
                            <ConfirmationModal
                              prefixContent="Delete Action-Type"
                              expectedText={at.type}
                              onConfirm={async () => {
                                try {
                                  await deleteActionType(at.type);
                                  beamtoast.success(
                                    `Action type '${at.type}' deleted successfully`
                                  );
                                  onUpdate();
                                } catch (e) {
                                  beamtoast.error(
                                    `Failed to delete action type '${at.type}'`
                                  );
                                  console.log(e);
                                }
                              }}
                              trigger={<Icon link name="trash"></Icon>}
                              message={
                                <ConfirmationModalMessage
                                  name={at.type}
                                  type={"Action"}
                                  specialMessage="This only works if no action is assigned to this Action-Type."
                                />
                              }
                            />
                          </div>
                        )}
                      </Table.Cell>
                    </DisplayIf>
                  </Table.Row>
                ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={permissions.editActionTypes ? "5" : "4"}>
                  <ErrorMessage
                    marginTop="30px"
                    message={
                      user?.role?.name === "admin"
                        ? "No Action Types Found!"
                        : "Please contact the Project Admin to request access to action types."
                    }
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Layout>
    </CardContainer>
  );
}
