import React, { useRef } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FirmwareVersions from "./FirmwareVersions/FirmwareVersions";
import EditFirmware from "./FirmwareVersions/EditFirmware";
import DeviceConfigurations from "./DeviceConfigurations/DeviceConfigurations";
import EditDeviceConfigurations from "./DeviceConfigurations/EditDeviceConfigurations";
import ViewDeviceConfigurations from "./DeviceConfigurations/ViewDeviceConfigurations";
import Devices from "./Devices/Devices";
import { DisplayIf } from "../util";
import { Permission } from "../../../util";
import { useUser } from "../../../context/User.context";
import ChangeLogModal from "../../common/Changelog/ChangelogModal";
import { useStatusBarContext } from "../../../context/StatusBar.context";

type DeviceManagementProps = {
  readonly currentTenant: string;
};

export const MenuTabLink = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  padding: 20px 30px;
  border-radius: 10px;
  background: ${(props) =>
    props.active
      ? props.theme.colors["secondary_tab-background-active"]
      : props.theme.colors["secondary_tab-background"]};
  color: ${(props) =>
    props.active
      ? props.theme.colors["secondary_tab-text-active"]
      : props.theme.colors["secondary_tab-text"]};
  text-transform: capitalize;
  box-shadow: ${(props) => props.theme.colors["container-box_shadow"]};
  border: ${(props) => props.theme.colors["container-border"]};
  cursor: pointer;
  p {
    margin-top: 12px;
    font-weight: 700;
  }
`;

export const DeviceCountBubble = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #000;
  font-size: 12px !important;
  font-weight: 700 !important;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: ${(props) => props.theme.colors["container-border"]} !important;
  box-shadow: ${(props) => props.theme.colors["container-box_shadow"]};
  z-index: 100;
`;

function DeviceManagement(props: DeviceManagementProps) {
  const { currentTenant } = props;
  const { setAutoRefresh, setDevicesInfo } = useStatusBarContext();

  const { user } = useUser();
  const DevicesComponentRef = useRef(null);

  const permissions: Permission = user?.role?.permissions;
  const theme = user?.settings?.theme ?? "dark";

  return (
    <Router basename={`/projects/${currentTenant}/`}>
      {user.name && <ChangeLogModal currentTenant={currentTenant} />}
      <Switch>
        <Route exact path="/device-management">
          <DisplayIf cond={permissions.showDeviceManagementTab}>
            <Devices
              ref={DevicesComponentRef}
              user={user}
              setAutoRefresh={setAutoRefresh}
              setDevicesInfo={setDevicesInfo}
            />
          </DisplayIf>
        </Route>

        <Route exact path="/device-management/devices">
          <DisplayIf cond={permissions.showDeviceManagementTab}>
            <Devices
              ref={DevicesComponentRef}
              user={user}
              setAutoRefresh={setAutoRefresh}
              setDevicesInfo={setDevicesInfo}
            />
          </DisplayIf>
        </Route>

        <Route exact path={`/device-management/firmwares`}>
          <DisplayIf cond={permissions.viewFirmwares}>
            <FirmwareVersions user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`/device-management/firmwares/add`}>
          <DisplayIf cond={permissions.editFirmwares}>
            <EditFirmware
              type="Add"
              id=""
              title="Upload New Firmware"
              actionButtonText="Create"
            />
          </DisplayIf>
        </Route>

        <Route
          exact
          path={`/device-management/firmwares/:id/edit`}
          render={(props) => (
            <DisplayIf cond={permissions.editFirmwares}>
              <EditFirmware
                title="Edit Firmware"
                actionButtonText="Save"
                type="Edit"
                id={props.match.params.id}
              />
            </DisplayIf>
          )}
        ></Route>

        <Route exact path={`/device-management/configurations`}>
          <DisplayIf cond={permissions.viewDeviceConfigs}>
            <DeviceConfigurations user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`/device-management/configurations/add`}>
          <DisplayIf cond={permissions.viewDeviceConfigs}>
            <EditDeviceConfigurations
              title="Create Device Config"
              type="Add"
              name=""
              actionButtonText="Create"
              theme={theme}
            />
          </DisplayIf>
        </Route>

        <Route
          exact
          path={`/device-management/configurations/:name`}
          render={(props) => (
            <DisplayIf cond={permissions.viewDeviceConfigs}>
              <ViewDeviceConfigurations
                name={props.match.params.name}
                theme={theme}
              />
            </DisplayIf>
          )}
        />

        <Route
          exact
          path={`/device-management/configurations/:name/edit`}
          render={(props) => (
            <DisplayIf cond={permissions.viewDeviceConfigs}>
              <EditDeviceConfigurations
                title="Edit Device Config"
                actionButtonText="Save"
                type="Edit"
                name={props.match.params.name}
                theme={theme}
              />
            </DisplayIf>
          )}
        />

        <Redirect from="/device-management" to="/device-management/devices" />
      </Switch>
    </Router>
  );
}

export default DeviceManagement;
