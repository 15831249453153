import React, { useState, useMemo } from "react";
import { Button, Dropdown, Icon, Popup, Table } from "semantic-ui-react";
import CreateOrEditUserModal from "./CreateOrEditUserModal";
import { Role, IUser, IUserResult } from "../../../../util";
import useAsyncEffect from "../../common/useAsyncEffect";
import Layout from "../../common/Layout";
import { ButtonIcon, DisplayIf } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  fetchAllUsers,
  deleteUser,
  fetchAllRoles,
  createUser,
  editUser,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import ConfirmationModal from "../../common/ConfirmationModal";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { TableHeaderCellWithSorting } from "../../../common/TableHeaderCellWithSorting";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer } from "../../../common/ActionsUtils";

interface CreateUserButtonProps {
  readonly onUpdate: () => void;
  readonly roles: Role[];
  readonly users: IUser[];
}

function CreateUserButton(props: CreateUserButtonProps) {
  async function handleSubmit(user) {
    try {
      await createUser(user);
      Mixpanel.track("Created User", {
        userName: user.name,
        userEmail: user.email,
      });
      beamtoast.success("User created successfully");
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "user creation",
        error: JSON.stringify(e),
      });
      beamtoast.error("Failed to create user");
      console.log(e);
    }
  }

  return (
    <CreateOrEditUserModal
      title="Create User"
      onSubmit={async (user) => await handleSubmit(user)}
      user={{
        name: "",
        email: "",
        roles: [1],
      }}
      roles={props.roles}
      users={props.users}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Create User
        </Button>
      }
    />
  );
}

interface EditUserButtonProps {
  readonly userId: string;
  readonly user: IUser;
  readonly onUpdate: () => void;
  readonly roles: Role[];
  readonly users: IUser[];
}

function EditUserButton(props: EditUserButtonProps) {
  async function handleSubmit(user) {
    try {
      await editUser(props.userId, user);
      Mixpanel.track("Updated User", {
        userName: user.name,
        userEmail: user.email,
      });
      beamtoast.success("Updated user successfully");
      props.onUpdate();
    } catch (e) {
      Mixpanel.track("Failure", {
        type: "user editing",
        error: JSON.stringify(e),
      });
      beamtoast.error("Failed to update the user");
      console.log(e);
    }
  }

  return (
    <CreateOrEditUserModal
      title="Edit User"
      onSubmit={async (user) => await handleSubmit(user)}
      user={props.user}
      roles={props.roles}
      users={props.users}
      trigger={<ButtonIcon link name="pencil" />}
    />
  );
}

export default function Users({ user }) {
  const [users, setUsers] = useState<IUserResult>({ result: {} });
  const [filteredUsers, setFilteredUsers] = useState<IUserResult>({
    result: {},
  });
  const [rolesOptions, setRolesOptions] = useState([
    { key: "0", value: "allRoles", text: "All Roles" },
  ]);
  const [activeRole, setActiveRole] = useState<string>("allRoles");
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending" | undefined;
  }>({ key: "name", direction: "ascending" });

  const permissions = user.role.permissions;
  const currentUserId = user?.id;

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchAllUsers();
      setUsers(res);
      setFilteredUsers(res);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccured(true);
    }
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      beamtoast.success("User deleted successfully");
      handleUpdate();
    } catch (e) {
      beamtoast.error("Failed to delete user");
      console.log(e);
    }
  };

  const getUserRoles = () => {
    const allRoles: number[] = [];
    for (const user of Object.values(users.result)) {
      allRoles.push(...user.roles);
    }
    return [...new Set(allRoles)];
  };

  useAsyncEffect(async () => {
    const userRoles = getUserRoles();
    const filteredRoles = roles.filter((role) => userRoles.includes(role.id));
    const nonUserRoles = roles.filter((role) => !userRoles.includes(role.id));
    setRolesOptions(() => [
      { key: "0", value: "allRoles", text: "All Roles" },
      ...filteredRoles.map((role) => ({
        key: role.id.toString(),
        value: role.id.toString(),
        text: role.name,
      })),
      ...nonUserRoles.map((role) => ({
        key: role.id.toString(),
        value: role.id.toString(),
        text: role.name,
        disabled: true,
        style: { cursor: "not-allowed" },
        title: "Not assigned to any user",
      })),
    ]);
  }, [users, roles]); // eslint-disable-line react-hooks/exhaustive-deps

  useAsyncEffect(async () => {
    document.title = "Users | Bytebeam";
    try {
      const res = await fetchAllRoles();
      setRoles(res);
    } catch (e) {
      console.log(e);
      setErrorOccured(true);
    }
  }, []);

  useAsyncEffect(handleUpdate, []);

  const onSelect = (_, data) => {
    setActiveRole(data.value);
    if (data.value === "allRoles") {
      setFilteredUsers(users);
    } else {
      const filteredUsersData = Object.entries(users.result).filter(
        ([_, user]) => user.roles.includes(parseInt(data.value))
      );
      setFilteredUsers({ result: Object.fromEntries(filteredUsersData) });
    }
  };

  const roleNameMap = useMemo(() => {
    const map = {};
    for (const role of roles) {
      map[role.id] = role.name;
    }
    return map;
  }, [roles]);

  const lookUpRole = (id: number) => {
    if (roleNameMap[id]) {
      return roleNameMap[id];
    } else {
      return "Unknown Role";
    }
  };

  const onSort = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = useMemo(() => {
    const sortableUsers = Object.entries(filteredUsers.result);
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        let valueA;
        let valueB;
        if (sortConfig.key === "id") {
          valueA = a[0];
          valueB = b[0];
        } else if (sortConfig.key === "roles") {
          valueA = lookUpRole(a[1][sortConfig.key][0]).toString().toLowerCase();
          valueB = lookUpRole(b[1][sortConfig.key][0]).toString().toLowerCase();
        } else {
          valueA = a[1][sortConfig.key];
          valueB = b[1][sortConfig.key];
        }

        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [filteredUsers, sortConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccured) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading users"
      />
    );
  }

  return (
    <CardContainer>
      <Layout
        buttons={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span
              aria-label="Filter roles"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <Icon
                name="filter"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "1rem",
                  fontSize: "1.25rem",
                }}
              />
              <Dropdown
                search
                selection
                style={{ maxWidth: "124px", padding: "0rem 1.75rem 0rem 1rem" }}
                options={rolesOptions}
                value={activeRole}
                onChange={onSelect}
              />
            </span>
            <DisplayIf cond={permissions.editUsers}>
              <CreateUserButton
                onUpdate={handleUpdate}
                roles={roles}
                users={Object.values(users.result)}
              />
            </DisplayIf>
          </div>
        }
      >
        <Table celled>
          <Table.Header>
            <Table.Row>
              <TableHeaderCellWithSorting
                label="Id"
                columnKey="id"
                onSort={onSort}
                sortConfig={sortConfig}
              />
              <TableHeaderCellWithSorting
                label="Name"
                columnKey="name"
                onSort={onSort}
                sortConfig={sortConfig}
              />
              <TableHeaderCellWithSorting
                label="Email"
                columnKey="email"
                onSort={onSort}
                sortConfig={sortConfig}
              />
              <DisplayIf cond={permissions.viewRoles}>
                <TableHeaderCellWithSorting
                  label="Role"
                  columnKey="roles"
                  onSort={onSort}
                  sortConfig={sortConfig}
                />
              </DisplayIf>
              <DisplayIf cond={permissions.editUsers}>
                <Table.HeaderCell textAlign="center">Options</Table.HeaderCell>
              </DisplayIf>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedUsers.length !== 0 ? (
              sortedUsers.map(([userId, user]) => (
                <Table.Row key={userId}>
                  <Table.Cell>{userId}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <DisplayIf cond={permissions.viewRoles}>
                    <Table.Cell>{lookUpRole(user.roles[0])}</Table.Cell>
                  </DisplayIf>
                  <DisplayIf cond={permissions.editUsers}>
                    <Table.Cell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <EditUserButton
                          userId={userId}
                          user={user}
                          onUpdate={handleUpdate}
                          roles={roles}
                          users={Object.values(users.result)}
                        />
                        {userId === currentUserId ? (
                          <Popup
                            content="You cannot delete your own account."
                            trigger={<ButtonIcon link name="trash" disabled />}
                          />
                        ) : (
                          <ConfirmationModal
                            prefixContent="Delete User"
                            expectedText={user.name}
                            onConfirm={() => handleDelete(userId)}
                            trigger={<ButtonIcon link name="trash" />}
                            message={
                              <ConfirmationModalMessage
                                name={user.name}
                                type={"User"}
                                specialMessage=""
                              />
                            }
                          />
                        )}
                      </div>
                    </Table.Cell>
                  </DisplayIf>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell
                  colSpan={`${
                    permissions.viewRoles && permissions.editUsers
                      ? "5"
                      : permissions.viewRoles || permissions.editUsers
                        ? "4"
                        : "3"
                  }`}
                >
                  <ErrorMessage marginTop="30px" message={"No Users found!"} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Layout>
    </CardContainer>
  );
}
