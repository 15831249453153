import * as React from "react";
import { SVGProps } from "react";

const StateTimelineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 91 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_269_61)">
      <path
        d="M91 11.9842C91 15.0984 88.4531 17.6239 85.3125 17.6239H74.1152V18.3288C74.1152 21.443 71.5683 23.9685 68.4277 23.9685H59.8965C56.7559 23.9685 54.209 21.443 54.209 18.3288V17.6239H5.6875C2.54693 17.6239 0 15.0984 0 11.9842C0 8.87009 2.54693 6.34459 5.6875 6.34459H54.209V5.63964C54.209 2.5255 56.7559 0 59.8965 0H68.4277C71.5683 0 74.1152 2.5255 74.1152 5.63964V6.34459H85.3125C88.4531 6.34459 91 8.87009 91 11.9842ZM85.3125 34.3612H55.4531V33.6563C55.4531 30.5422 52.9062 28.0167 49.7656 28.0167H41.2344C38.0938 28.0167 35.5469 30.5422 35.5469 33.6563V34.3612H5.6875C2.54693 34.3612 0 36.8867 0 40.0009C0 43.115 2.54693 45.6405 5.6875 45.6405H35.5469V46.3455C35.5469 49.4596 38.0938 51.9851 41.2344 51.9851H49.7656C52.9062 51.9851 55.4531 49.4596 55.4531 46.3455V45.6405H85.3125C88.4531 45.6405 91 43.115 91 40.0009C91 36.8867 88.4531 34.3612 85.3125 34.3612ZM85.3125 62.3779H36.791V61.6729C36.791 58.5588 34.2441 56.0333 31.1035 56.0333H22.5723C19.4317 56.0333 16.8848 58.5588 16.8848 61.6729V62.3779H5.6875C2.54693 62.3779 0 64.9034 0 68.0175C0 71.1317 2.54693 73.6572 5.6875 73.6572H16.8848V74.3621C16.8848 77.4763 19.4317 80.0018 22.5723 80.0018H31.1035C34.2441 80.0018 36.791 77.4763 36.791 74.3621V73.6572H85.3125C88.4531 73.6572 91 71.1317 91 68.0175C91 64.9034 88.4531 62.3779 85.3125 62.3779Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_269_61">
        <rect width="91" height="80" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default StateTimelineIcon;
