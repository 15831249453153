import React, { useState, useEffect } from "react";

import {
  Route,
  Switch,
  withRouter,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import Streams from "./streams/Streams";

import Roles from "./roles/Roles";
import Users from "./users/Users";
import Metadatakeys from "./metadatakeys/Metadatakeys";
import { DisplayIf } from "../util";
import EndUserAuth from "./enduserauth/EndUserAuth";
import ApiKeys from "./developerAPI/ApiKeys";
import SessionTypes from "./sessions/SessionTypes";
import TenantSettingsPage from "./tenantsSettings/TenantSettingsPage";
import DBC from "./DBC/DBC";
import { useUser } from "../../../context/User.context";
import DeviceComponents from "./DeviceComponents/DeviceComponents";
import { ActionsMenuWrapper } from "../Actions/ActionsV3/ActionsV3";
import ActionsMenuTab from "../Actions/ActionsV3/ActionsMenuTab";
import { enableDBCParserByDefault } from "../../../util";

const resolver = {
  "/settings/streams": "Streams",
  "/settings/roles": "Roles",
  "/settings/users": "Users",
  "/settings/metadata": "Metadata",
  "/settings/api_keys": "API Keys",
  "/settings/auth": "Auth",
  "/settings/session_types": "Session Types",
  "/settings/tenants_settings": "General",
  "/settings/dbc": "DBC Parsers",
  "/settings/device_components": "Device Components",
};

function SettingsManagement({ currentTenant }) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { user } = useUser();
  const showTabs = user?.["tenant-settings"]?.show_tabs;
  // let indexSettings = path.indexOf("/settings");
  // path = path.slice(indexSettings);

  const [activeItem, setActiveItem] = useState(
    resolver[window.location.pathname] ?? "General"
  );

  const permissions = user?.role?.permissions ?? {};

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  useEffect(() => {
    if (location.pathname === `/projects/${currentTenant}/settings/`) {
      history.push(`/projects/${currentTenant}/settings`);
    }

    if (location.pathname === `/projects/${currentTenant}/settings`) {
      let permissions = user.role.permissions;
      if (permissions.editTenantSettings) {
        setActiveItem(resolver[`${path}/general`]);
        history.push(`${location.pathname}/general`);
      } else if (permissions.viewUsers) {
        setActiveItem(resolver[`${path}/users`]);
        history.push(`${location.pathname}/users`);
      } else if (permissions.viewStreams) {
        setActiveItem(resolver[`${path}/streams`]);
        history.push(`${location.pathname}/streams`);
      } else if (permissions.viewRoles) {
        setActiveItem(resolver[`${path}/roles`]);
        history.push(`${location.pathname}/roles`);
      } else if (permissions.viewMetadataKeys) {
        setActiveItem(resolver[`${path}/metadata`]);
        history.push(`${location.pathname}/metadata`);
      } else if (user.role.name === "admin") {
        setActiveItem(resolver[`${path}/api_keys`]);
        history.push(`${location.pathname}/api_keys`);
      } else {
        setActiveItem("General");
        history.push(`${location.pathname}/general`);
      }
    }

    switch (location.pathname) {
      case `${path}/streams`:
        setActiveItem("Streams");
        break;

      case `${path}/users`:
        setActiveItem("Users");
        break;

      case `${path}/roles`:
        setActiveItem("Roles");
        break;

      case `${path}/metadata`:
        setActiveItem("Metadata");
        break;

      case `${path}/api_keys`:
        setActiveItem("API Keys");
        break;

      case `${path}/session_types`:
        setActiveItem("Session Types");
        break;

      case `${path}/general`:
        setActiveItem("General");
        break;

      case `${path}/dbc`:
        setActiveItem("DBC Parsers");
        break;

      case `${path}/device_components`:
        setActiveItem("Device Components");
        break;

      default:
        break;
    }
  }, [location.pathname, activeItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className="desktop-view"
        style={{ display: "flex", alignItems: "center", gap: 10 }}
      >
        <ActionsMenuWrapper className="desktop-view">
          <DisplayIf cond={permissions.editTenantSettings ?? true}>
            <ActionsMenuTab
              firstElement
              name={"General"}
              icon={"key"}
              to={`${path}/general`}
              displayIf={permissions.editTenantSettings}
              active={activeItem === "General"}
              onClick={() => {
                handleItemClick("General");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={permissions.viewUsers ?? true}>
            <ActionsMenuTab
              firstDivider
              name={"Users"}
              icon={"users"}
              to={`${path}/users`}
              displayIf={permissions.viewUsers}
              active={activeItem === "Users"}
              onClick={() => {
                handleItemClick("Users");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={permissions.viewStreams ?? true}>
            <ActionsMenuTab
              name={"Streams"}
              icon={"table"}
              to={`${path}/streams`}
              displayIf={permissions.viewStreams}
              active={activeItem === "Streams"}
              onClick={() => {
                handleItemClick("Streams");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={permissions.viewRoles ?? true}>
            <ActionsMenuTab
              name={"Roles"}
              icon={"settings"}
              to={`${path}/roles`}
              displayIf={permissions.viewRoles}
              active={activeItem === "Roles"}
              onClick={() => {
                handleItemClick("Roles");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={permissions.viewMetadataKeys ?? true}>
            <ActionsMenuTab
              name={"Metadata"}
              icon={"hdd"}
              to={`${path}/metadata`}
              displayIf={permissions.viewMetadataKeys}
              active={activeItem === "Metadata"}
              onClick={() => {
                handleItemClick("Metadata");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={user.role.name === "admin"}>
            <ActionsMenuTab
              name={"API Keys"}
              icon={"key"}
              to={`${path}/api_keys`}
              displayIf={user.role.name === "admin"}
              active={activeItem === "API Keys"}
              onClick={() => {
                handleItemClick("API Keys");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={user.role.name === "admin"}>
            <ActionsMenuTab
              name={"Session Types"}
              icon={"history"}
              to={`${path}/session_types`}
              displayIf={user.role.name === "admin"}
              active={activeItem === "Session Types"}
              onClick={() => {
                handleItemClick("Session Types");
              }}
            />
          </DisplayIf>

          <DisplayIf
            cond={
              showTabs?.dbc_parsers?.show === true || enableDBCParserByDefault()
            }
          >
            <ActionsMenuTab
              name={"DBC Parsers"}
              icon={"microchip"}
              to={`${path}/dbc`}
              displayIf={
                showTabs?.dbc_parsers?.show === true ||
                enableDBCParserByDefault()
              }
              active={activeItem === "DBC Parsers"}
              onClick={() => {
                handleItemClick("DBC Parsers");
              }}
            />
          </DisplayIf>

          <DisplayIf cond={showTabs?.device_components?.show === true}>
            <ActionsMenuTab
              lastElement
              name={"Device Components"}
              icon={"object group outline"}
              to={`${path}/device_components`}
              displayIf={showTabs?.device_components?.show === true}
              active={activeItem === "Device Components"}
              onClick={() => {
                handleItemClick("Device Components");
              }}
            />
          </DisplayIf>

          {/* Auth is hidden till mobile SDKs are ready */}
          {/* { menuTab("Auth", "/settings/auth", true)} */}
        </ActionsMenuWrapper>
      </div>

      <Switch>
        <Route exact path={`${path}/streams`}>
          <DisplayIf cond={permissions.viewStreams}>
            <Streams />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/roles`}>
          <DisplayIf cond={permissions.viewRoles}>
            <Roles user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/users`}>
          <DisplayIf cond={permissions.viewUsers}>
            <Users user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/metadata`}>
          <DisplayIf cond={permissions.viewMetadataKeys}>
            <Metadatakeys user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/api_keys`}>
          <DisplayIf cond={user.role.name === "admin"}>
            <ApiKeys user={user} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/session_types`}>
          <DisplayIf cond={user.role.name === "admin"}>
            <SessionTypes />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/general`}>
          <DisplayIf cond={permissions.editTenantSettings}>
            <TenantSettingsPage currentTenant={currentTenant} />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/dbc`}>
          <DisplayIf
            cond={
              user.role.name === "admin" &&
              (showTabs?.dbc_parsers?.show !== false ||
                enableDBCParserByDefault())
            }
          >
            <DBC />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/device_components`}>
          <DisplayIf
            cond={
              user.role.name === "admin" &&
              showTabs?.device_components?.show !== false
            }
          >
            <DeviceComponents />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/auth`}>
          <DisplayIf cond={true}>
            <EndUserAuth user={user} />
          </DisplayIf>
        </Route>
      </Switch>
    </>
  );
}

export default withRouter(SettingsManagement);
