import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import styled from "styled-components";
import LiveActionsIcon from "../../../common/LiveActionsIcon";
import ActionsMenuTab from "./ActionsMenuTab";
import { DisplayIf } from "../../util";
import LiveActions from "../LiveAction/LiveActions";
import InventoryManagement from "../Inventory/InventoryManagement";
import NewAction from "../NewAction/NewAction";
import LogsManagement from "../Logs/LogsManagement";
import ActionButtonV3 from "./ActionButton";
import { ActionsNavbarActiveItemType } from "../../../../util";
import { useUser } from "../../../../context/User.context";
import ActionTypes from "../../Settings/actiontypes/ActionTypes";

export const ActionsMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
`;

function ActionsV3() {
  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [activeItem, setActiveItem] =
    useState<ActionsNavbarActiveItemType>("Action Details");
  const [doParamsExist, setDoParamsExist] = useState<boolean>(false);

  const searchParams = new URLSearchParams(location.search);

  const [actionIDParam, setActionIDParam] = useState<any>(
    searchParams.get("action_id")
  );

  const handleItemClick = (name) => {
    setActiveItem(name);
  };

  useEffect(() => {
    document.title = "Actions | Bytebeam";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let activeItem: ActionsNavbarActiveItemType;
    switch (location.pathname) {
      case `${path}/actions`:
      case `${path}/actions/`:
      case `${path}/live-actions`:
      case `${path}/`:
        activeItem = "Action Details";
        break;

      case `${path}/inventory`:
      case `${path}/inventory/firmware-files`:
      case `${path}/inventory/json-configurations`:
      case `${path}/inventory/geofence-configurations`:
        activeItem = "Inventory";
        break;

      case `${path}/new-action`:
        activeItem = "New Action";
        break;

      case `${path}/action-settings`:
        activeItem = "Action Settings";
        break;

      default:
        activeItem = "Action Details";
        break;
    }
    setActiveItem(activeItem);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {doParamsExist ? (
        <ActionButtonV3
          margin_left="0px"
          label="Action Summary"
          icon="left chevron"
          onClick={() => {
            history.push(`${path}/live-actions?action_id=${actionIDParam}`);
          }}
        />
      ) : (
        <ActionsMenuWrapper className="desktop-view">
          <ActionsMenuTab
            firstElement
            name={"Action Details"}
            icon={LiveActionsIcon}
            to={`${path}/live-actions`}
            displayIf={true}
            active={activeItem === "Action Details"}
            onClick={() => {
              handleItemClick("Action Details");
            }}
          />

          <ActionsMenuTab
            firstDivider
            name={"Inventory"}
            icon={"boxes"}
            to={`${path}/inventory`}
            displayIf={true}
            active={activeItem === "Inventory"}
            onClick={() => {
              handleItemClick("Inventory");
            }}
          />

          <ActionsMenuTab
            name={"New Action"}
            icon={"plus square outline"}
            to={`${path}/new-action`}
            displayIf={true}
            active={activeItem === "New Action"}
            onClick={() => {
              handleItemClick("New Action");
            }}
          />

          <ActionsMenuTab
            lastElement
            name={"Action Settings"}
            icon={"sitemap"}
            to={`${path}/action-settings`}
            displayIf={true}
            active={activeItem === "Action Settings"}
            onClick={() => {
              handleItemClick("Action Settings");
            }}
          />
        </ActionsMenuWrapper>
      )}

      <Switch>
        <Route exact path={`${path}/`}>
          <DisplayIf cond={true}>
            <LiveActions
              setDoParamsExist={(value) => setDoParamsExist(value)}
              setActionIDParam={(actionID) => setActionIDParam(actionID)}
              setActiveItem={(value: ActionsNavbarActiveItemType) =>
                setActiveItem(value)
              }
            />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/live-actions`}>
          <DisplayIf cond={true}>
            <LiveActions
              setDoParamsExist={(value) => setDoParamsExist(value)}
              setActionIDParam={(actionID) => setActionIDParam(actionID)}
              setActiveItem={(value: ActionsNavbarActiveItemType) =>
                setActiveItem(value)
              }
            />
          </DisplayIf>
        </Route>

        <Route path={`${path}/inventory`}>
          <DisplayIf cond={true}>
            <InventoryManagement />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/new-action`}>
          <DisplayIf cond={true}>
            <NewAction />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/action-settings`}>
          <DisplayIf cond={permissions.viewActionTypes}>
            <ActionTypes />
          </DisplayIf>
        </Route>

        <Route path={`${path}/logs`}>
          <DisplayIf cond={true}>
            <LogsManagement />
          </DisplayIf>
        </Route>
      </Switch>
    </>
  );
}

export default ActionsV3;
