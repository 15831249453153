import React, { Component } from "react";
import { Dropdown, Button, Modal, Icon } from "semantic-ui-react";
import {
  fetchAllFirmwareBundles,
  fetchAllFirmwares,
} from "../../../../../BytebeamClient";
import { DropdownOptionType } from "./UpdateConfigModal";
import { beamtoast } from "../../../../common/CustomToast";
import AdvanceSettingsSection from "../../../Actions/NewAction/AdvanceSettingsSection";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { UserContext } from "../../../../../context/User.context";

type UpdateFirmwareModalProps = {
  isOpen: boolean;
  close: (...args: any[]) => void;
  triggerAction: (
    actionType: string,
    params: { id?: string; version?: string; "bundle-id"?: string },
    requestApproval?: boolean
  ) => any;
  allSelected: boolean;
  selectedDevicesCount: number;
  endTimestamp: Date | number;
  releaseNotes: string;
  updateActionTimeout: (timeout: Date | number) => void;
  updateAutoRetry: (retryUntil: number | Date) => void;
  updateReleaseNotes: (notes: string) => void;
};

type UpdateFirmwareModalState = {
  options: DropdownOptionType[];
  selectedOption: string;
  status: number;
  loadingDropdown: boolean;
};

enum UpdateFirmwareVersionStatus {
  SelectFirmwareVersion,
  TriggerUpdateDeviceFirmware,
}

export class UpdateFirmwareModal extends Component<
  UpdateFirmwareModalProps,
  UpdateFirmwareModalState
> {
  state = {
    options: [],
    selectedOption: "",
    status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
    loadingDropdown: true,
  };

  fillDropdownOptions = async (componentEnabled: boolean) => {
    try {
      let options;
      if (componentEnabled) {
        let res = await fetchAllFirmwareBundles();
        options = res.results.flatMap((o) =>
          o.upload_status === "completed"
            ? [
                {
                  key: o.id,
                  value: o.id,
                  text: o.version,
                },
              ]
            : []
        );
      } else {
        let res = await fetchAllFirmwares();
        res = res.filter((o) => !o.is_deactivated);
        options = res.map((o) => {
          return {
            key: o.version_number,
            value: o.version_number,
            text: o.version_number,
          };
        });
      }
      this.setState({ options });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loadingDropdown: false });
    }
  };

  onSelect = async (e, data) => {
    this.setState({
      selectedOption: data.value,
    });
  };

  // Reset state and close modal
  onCloseModal = () => {
    this.setState({
      options: [],
      selectedOption: "",
      status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
      loadingDropdown: true,
    });
    this.props.close();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <UserContext.Consumer>
        {(userContext) => {
          const componentEnabled =
            userContext.user?.["tenant-settings"]?.show_tabs?.device_components
              ?.show ?? false;

          return (
            <Modal
              open={isOpen}
              size="tiny"
              className="dark"
              onMount={() => this.fillDropdownOptions(componentEnabled)}
              onClose={this.onCloseModal}
            >
              <Modal.Header>Update Device Firmware</Modal.Header>
              <Modal.Content>
                {this.state.status ===
                  UpdateFirmwareVersionStatus.SelectFirmwareVersion && (
                  <Modal.Description>
                    <p>{`Select firmware ${componentEnabled ? "bundle " : ""}version:`}</p>
                    <Dropdown
                      placeholder={
                        componentEnabled
                          ? "Select Firmware Bundle Version"
                          : "Select Version"
                      }
                      fluid
                      selection
                      search
                      options={this.state.options}
                      onChange={this.onSelect}
                      value={this.state.selectedOption}
                      loading={this.state.loadingDropdown !== false}
                      disabled={this.state.loadingDropdown}
                    />
                    <ThinDivider style={{ margin: "18px 0px" }} />
                    <AdvanceSettingsSection
                      endTimestamp={this.props.endTimestamp}
                      releaseNotes={this.props.releaseNotes}
                      action={"update_firmware"}
                      setEndTimestamp={this.props.updateActionTimeout}
                      setReleaseNotes={this.props.updateReleaseNotes}
                      setRetryUntil={this.props.updateAutoRetry}
                      textEditorDimensions={{ height: "250px", width: "450px" }}
                    />
                  </Modal.Description>
                )}
                {this.state.status ===
                  UpdateFirmwareVersionStatus.TriggerUpdateDeviceFirmware && (
                  <Modal.Description>
                    <div style={{ fontSize: "16px", marginBottom: "16px" }}>
                      Are you ready to deploy{" "}
                      <b>
                        {componentEnabled
                          ? "bundle id: "
                          : "firmware version: "}{" "}
                        {this.state.selectedOption}
                      </b>{" "}
                      to{" "}
                      <b>
                        {this.props.allSelected
                          ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                            `All devices`
                          : `${
                              this.props.selectedDevicesCount === 1
                                ? "1 device"
                                : `${this.props.selectedDevicesCount} devices`
                            }`}
                      </b>{" "}
                      ?
                    </div>
                    This action will update the firmware on all selected
                    devices. Please click on <b>Update</b> button if you would
                    like to proceed with the deployment, else click on{" "}
                    <b>Discard</b> button.
                  </Modal.Description>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  secondary
                  onClick={() => {
                    this.onCloseModal();
                    this.setState({
                      status: UpdateFirmwareVersionStatus.SelectFirmwareVersion,
                    });
                  }}
                  // style={{
                  //   float: "left",
                  // }}
                >
                  <Icon name="remove" /> Discard
                </Button>
                {/* {this.state.status ===
                  UpdateFirmwareVersionStatus.TriggerUpdateDeviceFirmware && (
                  <Button
                    secondary
                    onClick={() => {
                      this.props.triggerAction(
                        "update_firmware",
                        {
                          ...(componentEnabled
                            ? { "bundle-id": this.state.selectedOption }
                            : { version: this.state.selectedOption }),
                        },
                        true
                      );
                      this.onCloseModal();
                    }}
                  >
                    <Icon name="envelope outline" /> Request
                  </Button>
                )} */}
                <Button
                  primary
                  onClick={() => {
                    if (
                      this.state.status ===
                      UpdateFirmwareVersionStatus.SelectFirmwareVersion
                    ) {
                      if (this.state.selectedOption === "") {
                        beamtoast.error(
                          `Please select a firmware ${
                            componentEnabled ? "bundle " : ""
                          }version`
                        );
                      } else {
                        this.setState({
                          status:
                            UpdateFirmwareVersionStatus.TriggerUpdateDeviceFirmware,
                        });
                      }
                    } else {
                      this.props.triggerAction("update_firmware", {
                        ...(componentEnabled
                          ? { "bundle-id": this.state.selectedOption }
                          : { version: this.state.selectedOption }),
                      });
                      this.onCloseModal();
                    }
                  }}
                >
                  {this.state.status ===
                  UpdateFirmwareVersionStatus.SelectFirmwareVersion ? (
                    <>
                      Next <Icon name="angle right" />
                    </>
                  ) : (
                    <>
                      <Icon name="checkmark" /> Trigger
                    </>
                  )}
                </Button>
              </Modal.Actions>
            </Modal>
          );
        }}
      </UserContext.Consumer>
    );
  }
}
