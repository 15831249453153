import { LogsData, LogsMetaData, logLevelOptions } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import Convert from "ansi-to-html";
import dompurify from "dompurify";
import {
  Button,
  PaginationProps,
  Table,
  TableHeaderCell,
  Input,
  Dropdown,
  Icon,
  MenuItem,
  Dimmer,
} from "semantic-ui-react";

import {
  EditMetaRoot,
  EditMetaRow,
  getDashboardStateQueryParam,
} from "../util";
import {
  fetchPanelData,
  fetchAllLogTags,
  rowsPerPageOptions,
} from "../../../../../BytebeamClient";
import Plot from "react-plotly.js";
import { AbsoluteTimeRange } from "../../Datetime/TimeRange";
import { AbsoluteTimestamp } from "../../Datetime/Timestamp";
import { UserContext } from "../../../../../context/User.context";
import ThemeSchema from "../../../../../theme/schema";
import { __RouterContext as RouterContext } from "react-router";
import styled from "styled-components";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../../common/commonStyledComps";
import { SelectDevicesPerPage } from "../../../DeviceManagement/Devices/Devices";
import { validateWholeNumber } from "../../../../../util";
import LoadingAnimation from "../../../../common/Loader";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import { beamtoast } from "../../../../common/CustomToast";
import ForcedDirDropdownStyled from "../../../../common/ForceDirDropdownStyled";
import { capitalizeFirstLetter } from "../../../util";
import ansiRegex from "ansi-regex";

const LogsPanelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  svg.main-svg {
    background: transparent !important;
  }
`;

const LogsContainer = styled.div`
  width: 100%;
  height: 80%;
  padding: 0px 10px 0px 10px;
  margin-top: 10px;
  overflow: scroll;
`;

const logStyles = {
  Error: {
    color: "#ff695e",
    fontWeight: "bolder",
    fontSize: "16px",
  },
  Warn: {
    color: "#f2c037",
    fontSize: "15px",
  },
  Info: {
    color: "#5E94FF",
    fontSize: "14px",
  },
  default: {
    fontSize: "12px",
  },
};

type ViewLogsState = {
  data?: LogsData;
  logLevel?: string;
  tags?: string[];
  allTags?: string[];
  tagsLoading?: boolean;
  searchString?: string;
  refreshing: boolean;
  inputPageNumber: number;
  logsPerPage: number;
  totalPages: number;
  loading: boolean;
  page: number;
};

const convert = new Convert();

export class ViewLogs extends PanelViewComponent<
  LogsMetaData,
  LogsData,
  ViewLogsState
> {
  constructor(props) {
    super(props);
    this.state = {
      inputPageNumber: 0,
      totalPages: 10,
      page: 1,
      logsPerPage: 20,
      refreshing: false,
      loading: true,
      tagsLoading: true,
    };
  }

  onRelayout(event) {
    if ("xaxis.range[0]" in event && this.props.onTimeRangeChange) {
      const startTime = event["xaxis.range[0]"];
      const endTime = event["xaxis.range[1]"];

      this.props.onTimeRangeChange(
        new AbsoluteTimeRange(
          new AbsoluteTimestamp(new Date(startTime)),
          new AbsoluteTimestamp(new Date(endTime))
        )
      );
    }
  }

  async fetchData(
    logsPerPage: number,
    logLevel: string | undefined,
    tags: string[] | undefined,
    searchString: string | undefined,
    pageNumber: number
  ) {
    this.setState({
      refreshing: true,
      loading: true,
    });

    try {
      const panelMeta: LogsMetaData = {
        ...this.props.panelMeta,
        rowsPerPage: logsPerPage,
        page: pageNumber,
        tags: tags || this.props.panelMeta.tags,
        logLevel: logLevel || this.props.panelMeta.logLevel,
        searchString: searchString || this.props.panelMeta.searchString,
      };
      const response = await fetchPanelData(
        this.props.dashboardId,
        panelMeta.id,
        [panelMeta],
        this.props.fetchParams,
        null,
        null
      );

      if (response && response.length > 0 && response[0].data) {
        this.setState({
          data: response[0].data,
          totalPages: Math.ceil(response[0].data.totalRows / logsPerPage),
        });
      } else {
        this.setState({
          data: { data: [], totalRows: 0, tags: [], histogram: [] },
          totalPages: 0,
        });
      }
    } finally {
      this.setState({ refreshing: false, loading: false });
    }
  }

  async setPanelsStateInURL(history) {
    const { panelMeta } = this.props;
    const { logLevel, tags, searchString } = this.state;

    const urlParams = new URLSearchParams(window.location.search);
    // Get the panels state from URL for the current dashboard and compared dashboard in case of compare mode is enabled
    // as [panels state for current dashboard, panels state for compared dashboard ]
    const panelsStateFromURL = urlParams
      .getAll("state")
      .map((s) => JSON.parse(s));
    urlParams.delete("state");

    const currentDashboardPanelsState = panelsStateFromURL[0] || {};
    currentDashboardPanelsState[panelMeta.id] = {
      logLevel,
      tags,
      searchString,
    };
    panelsStateFromURL[0] = currentDashboardPanelsState;

    let state = panelsStateFromURL.reduce((acc, curr) => {
      if (acc === "") {
        return JSON.stringify(curr);
      }
      return `${acc},${JSON.stringify(curr)}`;
    }, "");

    urlParams.set("state", state);

    const updatedUrl = `?${urlParams.toString()}`;
    history.replace(updatedUrl);
  }

  async fetchSearchData(history) {
    await this.setPanelsStateInURL(history);
    await this.fetchData(
      this.state.logsPerPage,
      this.state.logLevel,
      this.state.tags,
      this.state.searchString,
      1
    );
  }

  async handlePaginationChange(
    _event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    if (data.activePage) {
      this.setState({
        page: data.activePage as number,
      });
      this.fetchData(
        this.state.logsPerPage,
        this.state.logLevel,
        this.state.tags,
        this.state.searchString,
        data.activePage as number
      );
    }
  }

  handlePaginationInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    this.setState({
      inputPageNumber: newValue,
    });
  };

  handlePaginationInputKeyDown = (event) => {
    const { inputPageNumber, totalPages } = this.state;

    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      if (validateWholeNumber(inputPageNumber.toString())) {
        let newPageNumber = 1; // Default to the first page if the input is invalid or out of bounds

        if (inputPageNumber > 0) {
          newPageNumber = Math.min(inputPageNumber, totalPages);
        }

        this.handlePaginationChange(event, {
          activePage: newPageNumber,
          totalPages: totalPages,
        });

        this.setState({
          inputPageNumber: 0,
        });
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  changeLogsPerPage(e, data) {
    try {
      this.setState({
        logsPerPage: parseInt(data.value),
        page: 1,
      });

      this.fetchData(
        parseInt(data.value),
        this.state.logLevel,
        this.state.tags,
        this.state.searchString,
        data.activePage as number
      );
    } catch (e) {
      beamtoast.error("Failed to change number of devices per page");
      console.error("Error in changeDeviceStatus: ", e);
    }
  }

  renderSearch() {
    const tagOptions = (this.state.allTags || []).map((tag) => {
      return {
        key: tag,
        value: tag,
        text: tag,
      };
    });

    return (
      <RouterContext.Consumer>
        {(context) => {
          const { history } = context;

          return (
            <form>
              <EditMetaRoot style={{ gap: 10 }}>
                <EditMetaRow>
                  <Input
                    style={{ width: "100%" }}
                    value={
                      this.state.searchString ||
                      this.props.panelMeta.searchString
                    }
                    placeholder="Message regex"
                    onChange={(e) => {
                      this.setState({ searchString: e.target.value });
                    }}
                  />
                </EditMetaRow>
                <EditMetaRow
                  style={{ display: "flex", alignItems: "center", gap: 10 }}
                >
                  <ForcedDirDropdownStyled
                    dropdownComponent={Dropdown}
                    pointing="bottom"
                    style={{ maxWidth: "200px" }}
                    selection
                    fluid
                    clearable
                    options={logLevelOptions}
                    placeholder="Log Level"
                    value={this.state.logLevel || this.props.panelMeta.logLevel}
                    onChange={(_e, d) => {
                      this.setState({ logLevel: d.value as string });
                    }}
                  />
                  <ForcedDirDropdownStyled
                    dropdownComponent={Dropdown}
                    pointing="bottom"
                    style={{ maxWidth: "300px" }}
                    loading={this.state.tagsLoading}
                    search
                    multiple
                    selection
                    fluid
                    options={tagOptions}
                    placeholder="Tags"
                    value={this.state.tags || this.props.panelMeta.tags}
                    onChange={(_e, d) => {
                      this.setState({ tags: d.value as string[] });
                    }}
                    renderLabel={(label) => ({
                      content:
                        typeof label.text === "string" && label.text.length > 20
                          ? `${label.text.substring(0, 25)}...`
                          : label.text,
                    })}
                  />

                  <Button
                    secondary
                    disabled={this.state.refreshing}
                    onClick={() => this.fetchSearchData(history)}
                  >
                    <Icon
                      name={this.state.refreshing ? "refresh" : "search"}
                      style={{ marginRight: "0px", color: "white" }}
                      loading={this.state.refreshing}
                    />
                  </Button>
                </EditMetaRow>
              </EditMetaRoot>
            </form>
          );
        }}
      </RouterContext.Consumer>
    );
  }

  histogramDataToPlot(histogramData) {
    const x = histogramData.map((d) => new Date(d.start));
    const y = histogramData.map((d) => d.height);
    const hoverText = histogramData.map(
      (d) =>
        `${d.height} messages : ${
          new Date(d.start).toTimeString().split(" ")[0]
        } - ${new Date(d.end).toTimeString().split(" ")[0]}`
    );

    const dataBar = {
      x: x,
      y: y,
      type: "bar",
      marker: {
        color: "#90B2E5",
      },
      text: hoverText,
    } as Plotly.Data;

    return dataBar;
  }

  validateHtmlTags(content: string): {
    correctedContent: string;
    openingTags: string[];
    closingTags: string[];
  } {
    const tagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const stack: string[] = [];
    const closingTags: string[] = [];
    const openingTags: string[] = [];
    let resultContent = content;

    let match: RegExpExecArray | null;
    while ((match = tagRegex.exec(content)) !== null) {
      const tag = match[0];
      const tagName = match[1];
      const isClosingTag = tag.startsWith("</");

      if (isClosingTag) {
        if (stack.length > 0 && stack[stack.length - 1] === tagName) {
          stack.pop();
        } else {
          closingTags.push(tag);
          resultContent = resultContent.replace(tag, "");
        }
      } else {
        stack.push(tagName);
      }
    }

    while (stack.length > 0) {
      const tagName = stack.pop()!;
      openingTags.push(`</${tagName}>`);
      resultContent += `</${tagName}>`;
    }

    return {
      correctedContent: resultContent,
      openingTags,
      closingTags,
    };
  }

  cleanHtmlTags(html: string): string {
    const regex =
      /<span style="background-color: #ffa401; color: black;">([\s\S]*?)<\/span>/g;
    let match: RegExpExecArray | null;
    let correctedHtml = html;

    while ((match = regex.exec(html)) !== null) {
      const spanContent = match[1];
      const spanStartIndex = match.index;
      const spanEndIndex = regex.lastIndex;
      const contentBeforeSpan = html.slice(0, spanStartIndex);
      const contentAfterSpan = html.slice(spanEndIndex);

      const { correctedContent, openingTags, closingTags } =
        this.validateHtmlTags(spanContent);

      let newContentBeforeSpan = contentBeforeSpan;
      let newContentAfterSpan = contentAfterSpan;

      if (openingTags.length > 0) {
        const openingTagsString = openingTags.join("");
        newContentAfterSpan = openingTagsString + contentAfterSpan;
      }

      if (closingTags.length > 0) {
        const closingTagsString = closingTags.join("");
        newContentBeforeSpan += closingTagsString;
      }

      correctedHtml =
        newContentBeforeSpan +
        `<span style="background-color: #ffa401; color: black;">` +
        correctedContent +
        `</span>` +
        newContentAfterSpan;
    }

    return correctedHtml;
  }
  textToColoredHTML(text: string): string {
    // text = "\x1b[32mINFO: This is an informational message\x1b[0m"
    //        "\x1b[31mERROR: This is an error message\x1b[0m"
    //        "\x1b[33mWARN: This is a warning message\x1b[0m"
    //        "\x1b[34mDEBUG: This is a debug message\x1b[0m"

    const { searchString } = this.state;
    let html = text;

    const ansiPattern = ansiRegex();

    function getAnsiCodePositions(html: string) {
      const positions: { index: number; length: number }[] = [];
      let match: RegExpExecArray | null;
      let ansi = new RegExp(ansiPattern.source, "g");
      while ((match = ansi.exec(html)) !== null) {
        positions.push({ index: match.index, length: match[0].length });
      }

      return positions;
    }

    function adjustIndexForAnsiCodes(
      index: number,
      ansiPositions: { index: number; length: number }[]
    ): number {
      let adjustedIndex = index;
      for (const pos of ansiPositions) {
        if (pos.index <= adjustedIndex) {
          adjustedIndex += pos.length;
        } else {
          break;
        }
      }

      for (const pos of ansiPositions) {
        if (pos.index + pos.length === adjustedIndex) {
          adjustedIndex = pos.index;
        }
      }
      return adjustedIndex;
    }

    function adjustLengthForAnsiCodes(
      index: number,
      length: number,
      ansiPositions: { index: number; length: number }[]
    ): number {
      let adjustedLength = length;
      for (const pos of ansiPositions) {
        if (pos.index >= index && pos.index < index + adjustedLength) {
          adjustedLength += pos.length;
        } else if (pos.index >= index + adjustedLength) {
          break;
        }
      }

      for (const pos of ansiPositions) {
        if (pos.index === index + adjustedLength) {
          adjustedLength += pos.length;
        }
      }
      return adjustedLength;
    }

    const ansiPositions = getAnsiCodePositions(html);

    function highlightSearchString(html: string, searchString: string): string {
      const cleanHtml = html.replace(ansiPattern, ""); // Remove ANSI escape codes from a string
      const regex = new RegExp(`(${searchString})`, "gi");

      const matches: { index: number; length: number }[] = [];
      let match: RegExpExecArray | null;
      while ((match = regex.exec(cleanHtml)) !== null) {
        matches.push({ index: match.index, length: match[0].length });
      }

      for (let i = matches.length - 1; i >= 0; i--) {
        const { index, length } = matches[i];
        let trueIndex = adjustIndexForAnsiCodes(index, ansiPositions);
        let trueLength = adjustLengthForAnsiCodes(
          trueIndex,
          length,
          ansiPositions
        );

        const originalText = html.substring(trueIndex, trueIndex + trueLength);
        const highlightedText = `<span style="background-color: #ffa401; color: black;">${originalText}</span>`;
        html =
          html.substring(0, trueIndex) +
          highlightedText +
          html.substring(trueIndex + trueLength);
      }

      return html;
    }

    if (searchString) {
      try {
        html = highlightSearchString(html, searchString);
      } catch (e) {
        console.error("Invalid regular expression: ", e);
      }
    }

    let sanitizedText = dompurify.sanitize(html);
    let sanitizedHTML = convert.toHtml(sanitizedText);

    let finalHTML = this.cleanHtmlTags(sanitizedHTML);
    return finalHTML;
  }

  componentDidMount() {
    const data = this.state.data || this.props.data;

    const panelStateFromURL =
      getDashboardStateQueryParam()?.[0]?.[this.props.panelMeta.id];
    const logLevel = panelStateFromURL?.logLevel ?? "";
    const searchString = panelStateFromURL?.searchString ?? "";
    const tags = panelStateFromURL?.tags ?? [];

    this.setState({
      logLevel,
      searchString,
      tags,
      totalPages: Math.ceil(data.totalRows / this.state.logsPerPage),
    });

    const getAllLogTags = async () => {
      this.setState({ tagsLoading: true });

      try {
        const allTags = await fetchAllLogTags(
          this.props?.panelMeta?.table ?? "logs"
        );

        this.setState({
          allTags: allTags.data.tags ?? [],
        });
      } catch (error) {
        console.error("logTags error: ", error);
        this.setState({ allTags: [] });
      } finally {
        this.setState({ loading: false, tagsLoading: false });
      }
    };

    getAllLogTags();
  }

  // Update the total pages when the data changes.
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.data?.totalRows !== this.props.data.totalRows ||
      prevState?.data?.totalRows !== this.state?.data?.totalRows
    ) {
      const data = this.state?.data || this.props?.data;
      this.setState({
        totalPages: Math.ceil(data?.totalRows / this.state.logsPerPage),
        loading: false,
      });
    }
  }

  render() {
    let startTime = this.props.timeRange.getStartTime().toDate();
    let endTime = this.props.timeRange.getEndTime().toDate();

    const data = this.state.data || this.props.data;

    const histogramData = data.histogram;
    const plotHistogramData = this.histogramDataToPlot(histogramData);

    const plotStyle = {
      background: "transparent",
      width: "100%",
      height: "20%",
      minHeight: "100px",
      maxHeight: "200px",
      marginTop: "30px",
    };

    const layout = {
      autosize: true,
      responsive: true,
      legend: { orientation: "h", y: -0.4 }, // Added y position so that ticks don't overlap with Margin
      margin: {
        l: 25,
        r: 25,
        b: 25,
        t: 25,
      },
      xaxis: {
        automargin: true,
        showgrid: false,
        ticklen: 4,
        tickfont: {
          color: "#C0C0C0",
        },
        range: [startTime, endTime],
      },
      yaxis: {
        automargin: true,
        rangemode: "tozero",
        showline: true,
        ticklen: 4,
        tickfont: {
          color: "#C0C0C0",
        },
        showgrid: false,
      },
    } as Partial<Plotly.Layout>;

    return (
      <LogsPanelContainer>
        <UserContext.Consumer>
          {(userContext) => (
            <Plot
              data={[plotHistogramData]}
              style={plotStyle}
              layout={{
                ...layout,
                xaxis: {
                  ...layout.xaxis,
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
                yaxis: {
                  ...layout.yaxis,
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
              }}
              useResizeHandler={true}
              config={{ displayModeBar: false }}
              onRelayout={this.onRelayout.bind(this)}
            />
          )}
        </UserContext.Consumer>
        <LogsContainer>
          {this.props.panelMeta.showSearchBar ? this.renderSearch() : <></>}

          <Dimmer.Dimmable dimmed={this.state.loading}>
            <Dimmer active={this.state.loading}>
              <LoadingAnimation
                loadingText="Loading Panel..."
                loaderSize="42px"
              />
            </Dimmer>
            <Table
              compact
              selectable
              size="small"
              basic
              unstackable
              className={`stickyTable`}
            >
              <Table.Header>
                <Table.Row>
                  {
                    // Check if "-serial_metadata" exists and show the serial key else show Device ID.
                    data?.data[0]?.["-serial_metadata"] ? (
                      <TableHeaderCell width={1}>
                        {`#${capitalizeFirstLetter(Object?.keys(data.data[0]?.["-serial_metadata"]).toString())}`}
                      </TableHeaderCell>
                    ) : (
                      <TableHeaderCell width={1}>Device ID</TableHeaderCell>
                    )
                  }
                  <TableHeaderCell width={3}>Timestamp</TableHeaderCell>
                  <TableHeaderCell width={2}>Tag</TableHeaderCell>
                  <TableHeaderCell width={1}>Level</TableHeaderCell>
                  <TableHeaderCell width={10}>Message</TableHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data?.data.length > 0 ? (
                  data.data.map((p, i) => {
                    const logLevel = p["level"] || "trace";
                    const rowStyle =
                      logStyles[logLevel] || logStyles["default"];
                    const millis = p.timestamp % 1000;

                    return (
                      <Table.Row key={`${p.id}-${p.timestamp}-${i}`}>
                        {
                          // Check if serial metadata exists and show the serial key values else show device-Id.
                          p?.["-serial_metadata"] ? (
                            <Table.Cell>
                              {
                                p?.["-serial_metadata"][
                                  Object.keys(p?.["-serial_metadata"])[0]
                                ]
                              }
                            </Table.Cell>
                          ) : (
                            <Table.Cell>{p.id}</Table.Cell>
                          )
                        }
                        <Table.Cell>
                          {new Date(p.timestamp).toLocaleString("en-GB")}.
                          {millis}
                        </Table.Cell>
                        <Table.Cell>{p["tag"]}</Table.Cell>
                        <Table.Cell style={rowStyle}>{p["level"]}</Table.Cell>
                        <Table.Cell style={rowStyle}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.textToColoredHTML(
                                (p["message"] || "").replace(/^..-.. .*?: /, "")
                              ),
                            }}
                          ></span>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row rowSpan={5}>
                    <Table.Cell colSpan={5} style={{ textAlign: "center" }}>
                      <ErrorMessage
                        marginTop={"30px"}
                        message={" No logs found!"}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            {!this.state.loading && this.state.totalPages !== 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "16px",
                  marginBottom: "20px",
                }}
              >
                <StyledPagination
                  boundaryRange={0}
                  activePage={this.state.page}
                  ellipsisItem={null}
                  siblingRange={2}
                  totalPages={this.state.totalPages}
                  onPageChange={this.handlePaginationChange.bind(this)}
                />

                <StyledCardSearchPageInput
                  icon="search"
                  placeholder="Jump to page..."
                  name="activePage"
                  min={1}
                  onChange={this.handlePaginationInputChange}
                  onKeyDown={this.handlePaginationInputKeyDown}
                  type="number"
                  value={
                    this.state.inputPageNumber ? this.state.inputPageNumber : ""
                  }
                />

                <StyledSecondaryDevicePerPageWidget>
                  <MenuItem>Logs per page</MenuItem>
                  <MenuItem style={{ padding: "0px" }}>
                    <SelectDevicesPerPage
                      pointing="bottom"
                      compact
                      selection
                      options={rowsPerPageOptions}
                      value={this.state.logsPerPage}
                      onChange={this.changeLogsPerPage.bind(this)}
                    />
                  </MenuItem>
                </StyledSecondaryDevicePerPageWidget>
              </div>
            )}
          </Dimmer.Dimmable>
        </LogsContainer>
      </LogsPanelContainer>
    );
  }
}
