import React, { Component } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import {
  searchDevices,
  searchDevicesFilters,
} from "../../../../../BytebeamClient";
import { MessageContainer } from "./BulkMetadataUpdateModal";
import csvjson from "csvjson";
import { saveAs } from "file-saver";
import LoadingAnimation from "../../../../common/Loader";
import styled from "styled-components";
import { AtLeastOnePropRequired } from "../../../../../util";
import { queryToObject } from "../../../common/QuerySelector";

function replaceNullWithEmptyString(object) {
  for (let key in object) {
    if (object[key] === null) {
      object[key] = "";
    }
  }
}

type DownloadMetadataModalProps = {
  close: (...args: any[]) => any;
  readonly devicesSearchFilters: searchDevicesFilters;
};

type DownloadMetadataModalState = {
  buttonDisabled: boolean;
};

export const CodeQueryText = styled.code`
  color: ${({ theme }) => theme.colors["code-query-text-color"]} !important;
  .codeQueryText {
    background: ${({ theme }) =>
      theme.colors["code-query-text-background-color"]} !important;
  }
`;

class FilterQueryText extends React.Component<{
  devicesSearchFilters: searchDevicesFilters;
}> {
  render() {
    const { devicesSearchFilters } = this.props;

    const metadataFiltersString = devicesSearchFilters.metaDatafilters
      ? Object.entries(devicesSearchFilters.metaDatafilters)
          .map(([key, values]) => {
            if (Array.isArray(values)) {
              return `${key}: ${values.join(", ")}`;
            } else {
              return `${key} contains ${String(values)}`;
            }
          })
          .join(" AND \n")
      : "";

    return (
      <CodeQueryText id={"filter_query"} className="codeQueryText">
        <div className="codeQueryText" style={{ width: "fit-content" }}>
          {devicesSearchFilters.stateFilters &&
            `Device-Shadow Filters: ${queryToObject(
              devicesSearchFilters.stateFilters
            )
              .map(
                (filter) =>
                  `${filter.column} ${filter.operator} ${filter.value}`
              )
              .join(", ")}`}
        </div>
        <div
          style={{ marginTop: "5px", width: "fit-content" }}
          className="codeQueryText"
        >
          {metadataFiltersString &&
            `Metadata Filters - ${metadataFiltersString}`}
        </div>
      </CodeQueryText>
    );
  }
}

export class DownloadFilteredMetadataModal extends Component<
  DownloadMetadataModalProps,
  DownloadMetadataModalState
> {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
    };
  }

  async getDevicesMetadata(): Promise<string> {
    try {
      const status = "active";
      const deviceFilters = this.props
        .devicesSearchFilters as AtLeastOnePropRequired<searchDevicesFilters>;

      const devicesPerPage = 100000;
      const page = 1;

      // totalDevices is possible maximum number of devices (totalDevices = page * devicesPerPage)
      const { devices } = await searchDevices(
        deviceFilters,
        page,
        devicesPerPage,
        status,
        null
      );

      let metadata: any[] = [];

      for (const device of devices) {
        const object = {};
        object["id"] = device["id"];
        replaceNullWithEmptyString(device["metadata"]);
        object["Metadata"] = device["metadata"];
        metadata.push(object);
      }

      let csvData: string = csvjson.toCSV(metadata, { delimiter: "," });

      // To give a proper header to the csv file
      csvData = csvData.replace("[].", "");
      csvData = csvData.replaceAll("[].Metadata.", "");
      return csvData;
    } catch (error) {
      console.error("Error While Fetching all devices data: ", error);
      return "";
    }
  }

  async downloadSelectedDevicesMetadata() {
    try {
      const csvData: BlobPart = await this.getDevicesMetadata();
      this.setState({
        buttonDisabled: false,
      });

      const file = new File([csvData], "metadata.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error in downloading metadata: ", error);
    }
  }

  async onClickDownloadMetadata() {
    this.setState({
      buttonDisabled: true,
    });
    await this.downloadSelectedDevicesMetadata();
    this.props.close();
  }

  render() {
    return (
      <>
        <Modal.Content>
          <Modal.Description>
            <MessageContainer>
              {this.state.buttonDisabled ? (
                <LoadingAnimation
                  loadingText="Downloading all devices metadata..."
                  loaderBorderSize="3px"
                  loaderSize="30px"
                  fontSize="14px"
                />
              ) : (
                <p>
                  Download metadata for filtered devices, based on search query:
                  <br></br>
                  <FilterQueryText
                    devicesSearchFilters={this.props.devicesSearchFilters}
                  />
                </p>
              )}
            </MessageContainer>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              this.props.close();
            }}
          >
            <Icon name="angle left" /> Back
          </Button>
          {/* disable the button unless parsing and fetching is done */}
          <Button
            primary
            disabled={this.state.buttonDisabled}
            onClick={this.onClickDownloadMetadata.bind(this)}
          >
            <Icon name="checkmark" /> Download Metadata
          </Button>
        </Modal.Actions>
      </>
    );
  }
}
